import React , { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
// import mui
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import GETAPI from "utils/GETAPI";
//local files
// import EducationIcon from "assets/education.png";
// import EmploymentIcon from "assets/employment.png";
// import VolunteerIcon from "assets/volunteer.png";
// import DonateIcon from "assets/donate.png";
// import CoachingIcon from "assets/coaching.png";
import FooterSpacer from "components/FooterSpacer";
// import person from "../../assets/person.png";

const ViewMore = () => {
  const { t } = useTranslation(); // for translation
  // let types = [
  //   "education",
  //   "volunteer",
  //   "donate",
  //   "employment",
  //   "counselling & coaching",
  //   "missing person",
  // ];

  const [serviceList, setServiceList] = useState([]); // Use state to store serviceList

  const ServiceData = () => {
    GETAPI.GetServicesList((response) => {
      console.log('kkkkggg');
      if (!response.status) {
        setServiceList([]); // Reset serviceList if there's an error
      } else if (response.status) {
        // Create the serviceList array here, inside the callback
        const updatedServiceList = response.data.data
          .filter(element => element.status === true)
          .map(element => ({
            name: element.name,
            url: element.url,
            icon: element.photo,
          }));
        setServiceList(updatedServiceList); // Update serviceList with the filtered data
      } else {
        setServiceList([]); // Reset serviceList if there's an error
      }
    });
  };

  useEffect(() => {
    ServiceData();
  }, []);

// Now, serviceList state should contain the filtered and processed data

//console.log(serviceList);
  return (
    <div>
      <Typography
        variant="h4"
        color="#E97E6F"
        component="div"
        sx={{ fontWeight: "bold", m: "6% 3% 1% 5%" }}
      >
        {t("other.moreInfo")}
      </Typography>
      <Divider />
      <Container max_width="lg" sx={{ mt: "7%" }}>
        <Grid container spacing={2}>
          {serviceList.map((services, index) => {
            const { name, url, icon } = services;
            return (
              <Grid item xs={6} key={index} spacing={3}>
                <Link href={url} underline="none" target="_blank">
                  <Card
                    className={`${name}-card card`}
                    sx={{ p: 2, justifyContent: "center" }}
                  >
                    <CardMedia
                      className="services-icon"
                      sx={{ objectFit: "contain" }}
                      component="img"
                      image={icon}
                      height={80}
                      alt={icon}
                    />
                    <Typography
                      className="services-name"
                      align="center"
                      sx={{
                        mt: 1,
                        fontWeight: "bold",
                        color: "#D22108",
                        textTransform: "capitalize",
                      }}
                    >
                      {t(name)}
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        </Grid>
        <FooterSpacer />
      </Container>
    </div>
  );
};

export default ViewMore;
