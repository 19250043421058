import axios from "axios";
import { BaseURL } from "./BaseURL";

const DeleteAPI = {
  DeleteRequest: async (data, callback) => {
    axios({
      method: "DELETE",
      url: `${BaseURL}/request/deleteonerequest/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  DeleteDangerReport: async (data, callback) => {
    axios({
      method: "DELETE",
      url: `${BaseURL}/helpoffered/removemineimage/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  DeleteDeceasedReport: async (data, callback) => {
    axios({
      method: "DELETE",
      url: `${BaseURL}/deceased/deleteDeceased/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
};

export default DeleteAPI;
