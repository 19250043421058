import {
  FROM_DATE,
  TO_DATE,
  GENDER_FILTER,
  AGERANGE_FILTER,
  HAIRCOLOR_FILTER,
  HAIRLENGTH_FILTER,
  SKINCOLOR_FILTER,
} from "../type";

const initialState = {
  genderFilter: [],
  ageRangeFilter: [],
  hairLengthFilter: [],
  hairColorFilter: [],
  skinColorFilter: [],
  fromDate: "",
  toDate: "",
};

const DeceasedFilter = (state = initialState, action) => {
  switch (action.type) {
    case FROM_DATE:
      return {
        ...state,
        fromDate: action.payload,
      };
    case TO_DATE:
      return {
        ...state,
        toDate: action.payload,
      };
    case GENDER_FILTER:
      return {
        ...state,
        genderFilter: action.payload,
      };
    case AGERANGE_FILTER:
      return {
        ...state,
        ageRangeFilter: action.payload,
      };
    case HAIRCOLOR_FILTER:
      return {
        ...state,
        hairColorFilter: action.payload,
      };
    case HAIRLENGTH_FILTER:
      return {
        ...state,
        hairLengthFilter: action.payload,
      };
    case SKINCOLOR_FILTER:
      return {
        ...state,
        skinColorFilter: action.payload,
      };
    default:
      return state;
  }
};

export default DeceasedFilter;
