import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import mui
import Button from "@mui/material/Button";
import { googleLogout } from "@react-oauth/google";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { PROFILE } from "navigation/routeConfig";
import { clientID } from "utils/GoogleClientID";
import { GoogleLogout } from "react-google-login";
import { Grid, Stack, TextField } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { MuiTelInput } from "mui-tel-input";
import GETAPI from "utils/GETAPI";
import PostAPI from "utils/POSTAPI";

export default function SettingsIndex() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [type, setType] = useState();
  const [phone, setPhone] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [telegram, setTelegram] = useState("");
  const [fb, setFb] = useState("");
  const [line, setLine] = useState("");
  const [viber, setViber] = useState("");
  const [weChat, setWeChat] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [filename, setFilename] = useState("");
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    profile();
  }, []);

  const profile = () => {
    GETAPI.GetProfile((response) => {
      if (!response.status) {
        setImagePreview("");
        setLine("");
        setTelegram("");
        setFb("");
        setViber("");
        setWhatsApp("");
        setPhone("");
        setWeChat("");
      } else if (response.status) {
        setImagePreview(response.data.picture || "");
        setLine(response.data.lineInfo || "");
        setTelegram(response.data.telegramInfo || "");
        setFb(response.data.facebookInfo || "");
        setViber(response.data.viberInfo || "");
        setWhatsApp(response.data.whatsappInfo || "");
        setPhone(response.data.phoneInfo || "");
        setWeChat(response.data.weChatInfo || "");
      } else {
        setImagePreview("");
        setLine("");
        setTelegram("");
        setFb("");
        setViber("");
        setWhatsApp("");
        setPhone("");
        setWeChat("");
      }
    });
  };

  const editprofile = () => {
    const data = new FormData();
    data.append("_id", localStorage.getItem("_id"));
    data.append("photo", imgSrc);
    data.append("telegramInfo", telegram);
    data.append("whatsappInfo", whatsApp);
    data.append("phoneInfo", phone);
    data.append("facebookInfo", fb);
    data.append("lineInfo", line);
    data.append("viberInfo", viber);
    data.append("weChatInfo", weChat);
    PostAPI.EditProfile(data, (response) => {
      if (!response.status) {
        alert("Something went wrong");
        window.location.reload();
      } else if (response.status) {
        alert("Profile updated succesfully");
        window.location.reload();
      } else {
        alert("Something went wrong");
        window.location.reload();
      }
    });
  };

  const handleChange = (newValue) => {
    setPhone(newValue);
  };

  const handleImage = async (e) => {
    e.preventDefault();
    let image = URL.createObjectURL(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setImagePreview(image);
    setImgSrc(e.target.files[0]);
  };

  useEffect(() => {
    const type = localStorage.getItem("loginType");
    setType(type);
  }, []);

  // use for displaying language option
  const lang = {
    en: t("languages.english"),
    de: t("languages.german"),
    el: t("languages.greek"),
    ru: t("languages.russian"),
    uk: t("languages.ukrainian"),
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  return (
    <div style={{ paddingBottom: "80px" }}>
      <Box
        sx={{
          background: "#D22108",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          mb: 2,
          pb: 2,
        }}
      >
        <Container maxWidth="lg" sx={{ zIndex: "2", width: "100%" }}>
          <div className="mt-3">
            <Button
              variant="text"
              size="small"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosIcon sx={{ color: "#FFFFFF" }} />
              <Typography
                className="close-request"
                color="#FFFFFF"
                variant="button"
                fontSize={15}
                fontWeight="900"
              >
                {t("back")}
              </Typography>
            </Button>

            <Typography
              variant="h5"
              color="#FFFFFF"
              component="div"
              sx={{ fontWeight: "bold", m: "1rem 0 0 5px" }}
            >
              {t("setting.settings")}
            </Typography>
          </div>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Box b={10} sx={{ padding: "0 5px" }}>
          <Typography variant="button" gutterBottom sx={{ color: "#D22108" }}>
            {t("setting.preferences")}
          </Typography>
        </Box>

        <List
          sx={{ width: "100%" }}
          component="nav"
          aria-label="settings language"
        >
          <Divider />
          <ListItem
            className="language-select"
            button
            onClick={() => navigate("language")}
          >
            <ListItemText primary={t("setting.language")} />
            <Typography variant="body2" sx={{ color: "#00000099" }}>
              {lang[localStorage.getItem("i18nextLng")] || "English"}
            </Typography>
            <KeyboardArrowRightIcon sx={{ color: "#00000099" }} />
          </ListItem>
          <Divider />
        </List>
        <Container maxWidth="lg">
          <Typography
            color="#D22108"
            fontWeight="bold"
            component="h5"
            variant="subtitle1"
            align="center"
            gutterBottom
            marginTop={2}
            marginBottom={1}
            fontSize={35}
          >
            {t("maps.edit_profile")}
          </Typography>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginTop={2}
            marginBottom={1}
          >
            {t("Profile Picture(Take a photo less then 1 MB)")}
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {imagePreview ? (
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt={filename}
                    src={imagePreview}
                    style={{
                      borderRadius: "90px",
                      height: "150px",
                      width: "150px",
                      marginBottom: "20px",
                    }}
                  />
                </div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                  >
                    {t("maps.reupload")}
                    <input
                      hidden
                      accept="image/jpeg, image/png, image/webp"
                      type="file"
                      onChange={handleImage}
                    />
                  </Button>
                </Stack>
              </div>
            ) : (
              <div style={{ textAlign: "center", padding: "10px 0" }}>
                <Button
                  id="upload-button"
                  variant="contained"
                  component="label"
                  startIcon={<PhotoCamera />}
                >
                  {t("maps.select")}
                  <input
                    name="upload"
                    id="upload"
                    hidden
                    accept="image/jpeg, image/png, image/webp"
                    type="file"
                    onChange={handleImage}
                  />
                </Button>
              </div>
            )}
          </div>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Telegram
            </Typography>
            <TextField
              fullWidth
              size="small"
              label="Telegram"
              value={telegram}
              onChange={(e) => setTelegram(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              WhatsApp
            </Typography>
            <MuiTelInput
              label="WhatsApp"
              fullWidth
              value={whatsApp}
              onChange={setWhatsApp}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Phone
            </Typography>
            <MuiTelInput
              label="Phone"
              fullWidth
              value={phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Facebook
            </Typography>
            <TextField
              value={fb}
              onChange={(e) => setFb(e.target.value)}
              fullWidth
              size="small"
              // name={t("maps.description")}
              label="Facebook Link"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              LINE
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={line}
              onChange={(e) => setLine(e.target.value)}
              // name={t("maps.description")}
              label="LINE"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Viber
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={viber}
              onChange={(e) => setViber(e.target.value)}
              // name={t("maps.description")}
              label="Viber"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              WeChat
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={weChat}
              onChange={(e) => setWeChat(e.target.value)}
              // name={t("maps.description")}
              label="WeChat"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#D22108",
              "&:hover": {
                backgroundColor: "#D22108",
                borderColor: "#D22108",
              },
            }}
            onClick={() => editprofile()}
          >
            {t("maps.save")}
          </Button>
        </Container>
        <div style={{ textAlign: "center", paddingTop: "40px" }}>
          {/* <GoogleLogout
              clientId={clientID}
              buttonText="Log out"
              onLogoutSuccess={logOut}
            /> */}
          <Button
            variant="contained"
            onClick={() => {
              googleLogout();
              localStorage.clear();
              navigate("/login", { replace: true });
            }}
          >
            {t("setting.logout")}
          </Button>
        </div>
      </Container>
    </div>
  );
}
