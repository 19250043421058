import React, { useState, useCallback } from "react";

export const useDangerReportDialogStore = () => {
  const [dangerReportDialog, setDangerReportDialog] = useState(false);
  const openDangerReportDialog = useCallback(
    () => setDangerReportDialog(true),
    []
  );
  const closeDangerReportDialog = useCallback(
    () => setDangerReportDialog(false),
    []
  );
  return {
    dangerReportDialog,
    openDangerReportDialog,
    closeDangerReportDialog,
  };
};
