import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HELPLogo from "../../assets/logo.jpg";
import "firebase/compat/auth";
import { FEED, USER } from "navigation/routeConfig";
import DialogContent from "@mui/material/DialogContent";
import FacebookLogin from "react-facebook-login";
import "./Login.css";
import { ReactComponent as FBLogo } from "../../assets/FBLogo.svg";
import { ReactComponent as GoogleLogo } from "../../assets/googleLogo.svg";
import PostAPI from "utils/POSTAPI";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";

export default function Profile() {
  let navigate = useNavigate();
  const Googlelogin = (res) => {
    let formdata = {
      accessToken: res.credential,
    };
    console.log("google formdata",formdata);
    PostAPI.GoogleLogin(formdata, (response) => {
      if (!response.status) {
        alert("Login failed");
      } else if (response.status) {
        localStorage.setItem("_id", response.data._id);
        localStorage.setItem("userName", response.data.userName);
        localStorage.setItem("loginType", "Google");
        navigate(`../${USER}/${response.data._id}/${FEED}`, { replace: true });
      } else {
        alert("Login failed");
      }
    });
  };
  const onFailure = (err) => {
    console.log("failed:", err);
  };

  const FbLogin = (res) => {
    let formdata = {
      accessToken: res.accessToken,
    };
    console.log('formdata : ',formdata);
    PostAPI.FBLogin(formdata, (response) => {
      console.log('response : ',response);
      if (!response.status) {
        alert("Login failed");
      } else if (response.status) {
        console.log("facebook formdata",response.data);
        localStorage.setItem("_id", response.data._id);
        localStorage.setItem("userName", response.data.userName);
        localStorage.setItem("loginType", "Facebook");
        navigate(`../${USER}/${response.data._id}/${FEED}`, { replace: true });
      } else {
        alert("Login failed");
      }
    });
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => Googlelogin(tokenResponse),
    onError: (err) => onFailure(err),
  });

  return (
    <DialogContent>
      <HeaderContainer>
        <img style={{ height: "40%", width: "20%" }} src={HELPLogo} />
        <HeaderText>Welcome Back!</HeaderText>
        <SmallText>Sign-in to HELP!</SmallText>
        <Seperator />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GoogleLogin onSuccess={Googlelogin} onError={onFailure} />
          {/* <button onClick={login} className="googleLogo">
            <GoogleLogo
              style={{
                height: "30px",
                width: "30px",
              }}
            />
            Sign in with Google
          </button> */}
        </div>
        <Seperator />
        <FacebookLogin
          // appId="585724400048427"
          appId="1840176166439258"
          autoLoad={false}
          fields="name,email,picture"
          scope="email"
          callback={FbLogin}
          disableMobileRedirect={true}
          icon={
            <FBLogo
              style={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          textButton="Sign in with FB"
          cssClass="fbLogo"
        />
        <Seperator />
        <span className="termsText">
          By continuing, you are indicating that you accept our{" "}
          <a
            className="linkText"
            href="https://tonytangebirah.wixsite.com/help/terms-of-use"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            className="linkText"
            href="https://tonytangebirah.wixsite.com/help/privacy-policy"
          >
            Privacy Policy.
          </a>
        </span>
      </HeaderContainer>
    </DialogContent>
  );
}

const HeaderContainer = styled.div`
  width: 100%;
  padding-top: 45px;
  padding-bottom: 25px;
  text-align: center;
  font-family: "Roboto";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled.h2`
  padding-top: 40px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.24;
  z-index: 10;
  margin: 0;
`;

const SmallText = styled.h5`
  font-weight: 500;
  font-size: 11px;
  z-index: 10;
  margin: 0;
  margin-top: 8px;
`;

const Footer = styled.div`
  text-align: center;
  padding: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgba(117, 117, 117, 1);
  margin-top: 30px;
`;

const Seperator = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
