import axios from "axios";
import { BaseURL } from "./BaseURL";

const GETAPI = {
  GetAllRequest: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/request/getallrequest`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetProfile: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/user/getprofile/${localStorage.getItem("_id")}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetProfileById: async (id, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/user/getprofile/${id}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetRequests: async (data, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/request/getrequest/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetNotifications: async (data, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getofferlist/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetMineLocations: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getallmineimages`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetDeceasedLocations: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getdeceasedallimages`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetConnectionsList: async (data, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getacceptedofferlist/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetReportList: async (data, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getMineImageById/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetDeceasedList: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/deceased/getDeceasedlist`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetDeceasedListById: async (data, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/deceased/getdeceasedbyid/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetCategoryList: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getcategorylist`,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err);
      });
  },

  GetServicesList: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getserviceslist`,
    })
      .then((response) => {
        return callback(response);
      })
      .catch((err) => {
        return callback(err);
      });
  },
};

export default GETAPI;
