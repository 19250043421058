import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CardActionArea } from "@mui/material";
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import TelegramIcon from '@mui/icons-material/Telegram';
import { ReactComponent as WhatsAppIcon } from "assets/whatsapp.svg";
import { ReactComponent as TelegramIcon } from "assets/telegram.svg";
import { ReactComponent as ViberIcon } from "assets/viber.svg";
import { ReactComponent as WechatIcon } from "assets/wechat.svg";
import { ReactComponent as FacebookIcon } from "assets/facebook-messenger.svg";
import { ReactComponent as LineIcon } from "assets/line.svg";
import { ReactComponent as MessageIcon } from "assets/phone-message.svg";

// import local files
import accommodationIcon from "assets/accommodation.png";
import bandageIcon from "assets/bandage.png";
import foodIcon from "assets/food.png";
import transportIcon from "assets/transport.png";
import { GetUserCoordinates, getProximity } from "utils/locationService";
import GETAPI from "utils/GETAPI";

// expects requests as array, pass as props to component
export const RequestCard = ({ requests }) => {
  let navigate = useNavigate(); // for url navigation
  const { t } = useTranslation("request"); // for translation
  const [iconTitle, setIconTitle] = useState([]);
  console.log('requests : ',requests);

  const categoryData = () => {
    GETAPI.GetCategoryList((response) => {
      if (!response.status) {
        setIconTitle([]); // Reset categoryList if there's an error
      } else if (response.status) {
        console.log('hhssh',response.data.data);
        const categoryList = response.data.data.map((item) => ({
          title: item.name,
          url: item.photo,
        }));
        setIconTitle(categoryList);
      }
    });
  };
  useEffect(() => {
    categoryData();
  }, []);
  // console.log('ds',iconTitle);

  // PROXIMITY START ======================================================================

  function getProximityString(lat, long, request) {
    if (!lat || !long) {
      return "";
    }
    if (
      request.helpType == "transport" &&
      [
        request.transportLocation.from.longitude,
        request.transportLocation.from.latitude,
        request.transportLocation.to.longitude,
        request.transportLocation.to.latitude,
      ].includes(null)
    ) {
      return "";
    }
    if (
      request.helpType !== "transport" &&
      [
        request.currentLocation.longitude,
        request.currentLocation.latitude,
      ].includes(null)
    ) {
      return "";
    }

    const latlng = getProximity(lat, long, request);

    if (request.helpType == "transport") {
      return (
        latlng[0] +
        t("card.requesting_travel") +
        " " +
        latlng[1] +
        t("card.away")
      );
    }
    return latlng[0] + t("card.away");
  }

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  GetUserCoordinates(setLat, setLong);

  // PROXIMITY STUFF END ======================================================================
  // More stuff at Proximity field of render

  // use to toggle contact display
  const contactType = {
    Whatsapp: WhatsAppIcon,
    Telegram: TelegramIcon,
    Viber: ViberIcon,
    Wechat: WechatIcon,
    Facebook: FacebookIcon,
    Line: LineIcon,
    Phone: MessageIcon,
  };

  // use to toggle request icon base on request type
  const iconType = {
    medical: bandageIcon,
    accommodation: accommodationIcon,
    transport: transportIcon,
    food: foodIcon,
  };

  // const status = () => {
  //   let state = requests.offeredBy.find((id) => {
  //     id == localStorage.getItem("_id") ? false : true;
  //   });
  //   return state;
  // };
  
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {requests.map((request) => {
        return (
          <Grid item xs={12} sm={6} key={request._id}>
            <Card className="card request">
              <CardActionArea
                onClick={() => {
                  navigate(`${request._id}`, {
                    state: {
                      data: request,
                    },
                  });
                }}
                request="hello"
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={4} md={2} style={{ textAlign: "center" }}>
                      <CardMedia
                        component="img"
                        image = {iconTitle.find(item => item.title.toLowerCase() === request.helpType.toLowerCase())?.url || ""}
                        alt="icon"
                        className={`${request.helpType} card-media`}
                      />
                    </Grid>
                    <Grid item xs={8} md={10}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent
                          sx={{
                            flex: "1 0 auto",
                            p: "0% 0.75rem 0% 0%",
                            "&:last-child": { pb: 0 },
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="card-title"
                          >
                            {request.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="card-text"
                          >
                            {request.description}
                          </Typography>
                          <Typography className="proximity">
                            {getProximityString(lat, long, request)}
                          </Typography>

                          {request.contact.length > 2 ? (
                            <div
                              style={{
                                display: "inline-block",
                                margin: "5px 2px",
                                float: "right",
                              }}
                            >
                              <Typography
                                style={{ color: "#6F6F6F", fontSize: "0.9em" }}
                              >
                                +{request.contact.length - 2}
                              </Typography>
                            </div>
                          ) : (
                            <></>
                          )}

                          {request.contact.slice(0, 2).map((mode) => {
                            const ContactTypeIcon = contactType[mode];
                            return ContactTypeIcon ? (
                              <ContactTypeIcon
                                style={{
                                  fill: "#E97E6F",
                                  width: 18,
                                  margin: "5 2",
                                  float: "right",
                                }}
                                key={mode}
                              />
                            ) : (
                              ""
                            );
                          })}
                        </CardContent>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

// RequestCard.propTypes = {
//   requests: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.number,
//       userd: PropTypes.number,
//       username: PropTypes.string,
//       title: PropTypes.string,
//       expiry: PropTypes.string,
//       description: PropTypes.string,
//       request_type: PropTypes.string,
//       quantity: PropTypes.shape({
//         Adults: PropTypes.number,
//         Children: PropTypes.number,
//         Infants: PropTypes.number,
//       }),
//       status: PropTypes.string,
//       timestamp: PropTypes.string,
//       created_at: PropTypes.string,
//       updated_at: PropTypes.string,
//       preferred_mode_of_contact: PropTypes.arrayOf(PropTypes.string),
//       location: PropTypes.shape({
//         lat: PropTypes.number,
//         lng: PropTypes.number,
//       }),
//       tags: PropTypes.arrayOf(PropTypes.string),
//     })
//   ),
// };
