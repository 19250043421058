import React, { useState, useCallback } from "react";

export const useDangerPostWarningDialogStore = () => {
  const [dangerPostWarningDialog, setDangerPostWarningDialog] = useState(false);
  const openDangerPostWarningDialog = useCallback(
    () => setDangerPostWarningDialog(true),
    []
  );
  const closeDangerPostWarningDialog = useCallback(
    () => setDangerPostWarningDialog(false),
    []
  );
  return {
    dangerPostWarningDialog,
    openDangerPostWarningDialog,
    closeDangerPostWarningDialog,
  };
};
