import {
  CURRENT_POSITION,
  DEFAULT_POSITION,
  SHOW_CLICKABLE_MARKER,
  SHOW_CLICKABLE_MARKER_DECEASED,
  LOCATION_COORDINATES,
  LOCATION_NAME,
  LOCATION_TO_COORDINATES,
  LOCATION_TO_NAME,
} from "../type";

const initialState = {
  position: {
    lat: 0,
    lng: 0,
  },
  defaultPosition: {
    lat: 0,
    lng: 0,
  },
  showClickableMarker: false,
  showClickableMarkerDeceased: false,
  locationCoordinates: {
    lat: 0,
    lng: 0,
  },
  locationName: "",
  locationToName: "",
  locationToCoordinates: {
    lat: 0,
    lng: 0,
  },
};

const currentLocationCoordinates = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_POSITION:
      return {
        ...state,
        position: action.payload,
      };
    case DEFAULT_POSITION:
      return {
        ...state,
        defaultPosition: action.payload,
      };
    case SHOW_CLICKABLE_MARKER:
      return {
        ...state,
        showClickableMarker: action.payload,
      };
    case SHOW_CLICKABLE_MARKER_DECEASED:
      return {
        ...state,
        showClickableMarkerDeceased: action.payload,
      };
    case LOCATION_COORDINATES:
      return {
        ...state,
        locationCoordinates: action.payload,
      };
    case LOCATION_NAME:
      return {
        ...state,
        locationName: action.payload,
      };
    case LOCATION_TO_NAME:
      return {
        ...state,
        locationToName: action.payload,
      };
    case LOCATION_TO_COORDINATES:
      return {
        ...state,
        locationToCoordinates: action.payload,
      };
    default:
      return state;
  }
};

export default currentLocationCoordinates;
