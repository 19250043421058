import {
  REQUEST_TYPE_STATUS,
  REQUEST_TYPE,
  REQUEST_DESCRIPTION,
  TYPE,
  EDIT_DECEASED_DIALOG,
  EDIT_DECEASED_DATA,
  FROM_DATE,
  TO_DATE,
  GENDER_FILTER,
  AGERANGE_FILTER,
  HAIRCOLOR_FILTER,
  HAIRLENGTH_FILTER,
  SKINCOLOR_FILTER,
  DIALOG_DANGER,
  DIALOG_DECEASED,
  PROFILE_DATA,
  SEARCH_DATA,
  FEED_DATA,
} from "../type";

const initialState = {
  requestType: "pending",
  toggleType: "request",
  requestDescription: "",
  type: "sent",
  editDeceasedDialog: false,
  editDeceasedData: {},
  genderFilter: [],
  ageRangeFilter: [],
  hairLengthFilter: [],
  hairColorFilter: [],
  skinColorFilter: [],
  fromDate: "",
  toDate: "",
  dialogDanger: false,
  dialogDeceased: false,
  profileData: {},
  searchData: [],
  feedData: [],
};

const requestTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TYPE_STATUS:
      return {
        ...state,
        requestType: action.payload,
      };
    case REQUEST_TYPE:
      return {
        ...state,
        toggleType: action.payload,
      };
    case REQUEST_DESCRIPTION:
      return {
        ...state,
        requestDescription: action.payload,
      };
    case TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case EDIT_DECEASED_DIALOG:
      return {
        ...state,
        editDeceasedDialog: action.payload,
      };
    case EDIT_DECEASED_DATA:
      return {
        ...state,
        editDeceasedData: action.payload,
      };
    case FROM_DATE:
      return {
        ...state,
        fromDate: action.payload,
      };
    case TO_DATE:
      return {
        ...state,
        toDate: action.payload,
      };
    case GENDER_FILTER:
      return {
        ...state,
        genderFilter: action.payload,
      };
    case AGERANGE_FILTER:
      return {
        ...state,
        ageRangeFilter: action.payload,
      };
    case HAIRCOLOR_FILTER:
      return {
        ...state,
        hairColorFilter: action.payload,
      };
    case HAIRLENGTH_FILTER:
      return {
        ...state,
        hairLengthFilter: action.payload,
      };
    case SKINCOLOR_FILTER:
      return {
        ...state,
        skinColorFilter: action.payload,
      };
    case DIALOG_DANGER:
      return {
        ...state,
        dialogDanger: action.payload,
      };
    case DIALOG_DECEASED:
      return {
        ...state,
        dialogDeceased: action.payload,
      };
    case PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
      };
    case SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };
    case FEED_DATA:
      return {
        ...state,
        feedData: action.payload,
      };
    default:
      return state;
  }
};

export default requestTypeReducer;
