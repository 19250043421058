export const REQUEST_TYPE_STATUS = "REQUEST_TYPE_STATUS";
export const REQUEST_TYPE = "REQUEST_TYPE";
export const REQUEST_DESCRIPTION = "REQUEST_DESCRIPTION";
export const EDIT_DECEASED_DIALOG = "EDIT_DECEASED_DIALOG";
export const TYPE = "TYPE";
export const CURRENT_POSITION = "CURRENT_POSITION";
export const DEFAULT_POSITION = "DEFAULT_POSITION";
export const SHOW_CLICKABLE_MARKER = "SHOW_CLICKABLE_MARKER";
export const SHOW_CLICKABLE_MARKER_DECEASED = "SHOW_CLICKABLE_MARKER_DECEASED";
export const LOCATION_NAME = "LOCATION_NAME";
export const LOCATION_TO_NAME = "LOCATION_TO_NAME";
export const LOCATION_COORDINATES = "LOCATION_COORDINATES";
export const LOCATION_TO_COORDINATES = "LOCATION_TO_COORDINATES";
export const EDIT_DECEASED_DATA = "EDIT_DECEASED_DATA";
export const GENDER_FILTER = "GENDER_FILTER";
export const AGERANGE_FILTER = "AGERANGE_FILTER";
export const HAIRCOLOR_FILTER = "HAIRCOLOR_FILTER";
export const HAIRLENGTH_FILTER = "HAIRLENGTH_FILTER";
export const SKINCOLOR_FILTER = "SKINCOLOR_FILTER";
export const FROM_DATE = "FROM_DATE";
export const TO_DATE = "TO_DATE";
export const DIALOG_DANGER = "DIALOG_DANGER";
export const DIALOG_DECEASED = "DIALOG_DECEASED";
export const PROFILE_DATA = "PROFILE_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
export const FEED_DATA = "FEED_DATA";
