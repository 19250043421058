import React from "react";
import { Chip } from "@mui/material";

const ChipButton = (props) => {
  const { name, onClick, disabled } = props;
  return (
    <Chip
      label={name}
      variant="outlined"
      onClick={() => onClick()}
      disabled={disabled}
      sx={{
        borderRadius: "20px",
        marginLeft: "15px",
        marginTop: "8px",
        borderColor: "red",
        "&:hover": {
          backgroundColor: "#D22108",
          borderColor: "#D22108",
        },
        "&:focus": {
          backgroundColor: "#D22108",
          borderColor: "#D22108",
          color: "white",
        },
      }}
    />
  );
};

//make this component available to the app
export default ChipButton;
