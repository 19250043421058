// define routing path
export const ROOT = "/";
export const FEED = "feed";
export const USER = "users";
export const PROFILE = "profile";
export const DECEASED = "deceased";
export const MAP = "map";
export const ACCEPT_REQUEST = "accept_request";
export const NOTIFICATION = "notification";
export const OTHER = "other";
export const SETTINGS = "settings";
export const CHATBOT = "chatbot";
export const LOGIN = "login";
export const PLACEDETAILS = "place_details";
export const DECEASEDDETAILS = "deceased_details";
export const CATEGORYDATA = "getcategorylist";
export const SERVICEDATA = "getserviceslist";