import React from "react";
import { useParams } from "react-router-dom";
import { Trans } from "react-i18next";

// import mui
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import moment from "moment";

export const ContactCard = ({ connections }) => {
  return (
    <List sx={{ width: "100%" }} aria-label="notifications">
      {connections.map((connection) => {
        return (
          <>
            <ListItem
              key={connection._id}
              alignItems="flex-start"
              className="contact-card"
            >
              <ListItemAvatar>
                <img
                  alt={connection.helperProfile.userName}
                  src={connection.helperProfile.picture}
                  referrerPolicy="no-referrer"
                  style={{ width: 50, height: 50, borderRadius: "40px" }}
                />
              </ListItemAvatar>
              <ListItemText
                secondary={
                  <React.Fragment>
                    Congratulations! You're now connected with @
                    {connection.helperProfile.userName}. You may contact him/her
                    via:{" "}
                    {connection.helperProfile.telegramInfo &&
                      connection.helperProfile.telegramInfo !== "" &&
                      "Telegram: " +
                        connection.helperProfile.telegramInfo +
                        ","}{" "}
                    {connection.helperProfile.whatsappInfo &&
                      connection.helperProfile.whatsappInfo !== "" &&
                      "whatsApp: " +
                        connection.helperProfile.whatsappInfo +
                        ","}{" "}
                    {connection.helperProfile.lineInfo &&
                      connection.helperProfile.lineInfo !== "" &&
                      "Line: " + connection.helperProfile.lineInfo + ","}{" "}
                    {connection.helperProfile.viberInfo &&
                      connection.helperProfile.viberInfo !== "" &&
                      "Viber: " + connection.helperProfile.viberInfo + ","}{" "}
                    {connection.helperProfile.weChatInfo &&
                      connection.helperProfile.weChatInfo !== "" &&
                      "WeChat: " +
                        connection.helperProfile.weChatInfo +
                        ","}{" "}
                    {connection.helperProfile.phoneInfo &&
                      connection.helperProfile.phoneInfo !== "" &&
                      "Phone No.: " +
                        connection.helperProfile.phoneInfo +
                        ","}{" "}
                    {connection.helperProfile.facebookInfo &&
                      connection.helperProfile.facebookInfo !== "" &&
                      "Facebook: " + connection.helperProfile.facebookInfo}{" "}
                    <Typography className="proximity">
                      {moment(connection.updatedAt).fromNow()}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider />
          </>
        );
      })}
    </List>
  );
};

function loadContactsAsString(contacts_hash) {
  let out = [];
  for (let key in contacts_hash) {
    out.push(` ${key}: ${contacts_hash[key]}`);
  }
  return out.join(", ");
}
