import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

// import mui
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PostAPI from "utils/POSTAPI";
import moment from "moment";

export const NotificationCard = ({
  notifications,
  users,
  handleUpdateNotification,
}) => {
  let { userid } = useParams();
  const { t } = useTranslation();
  const location = useLocation(); // get current url path
  const [open, setOpen] = useState(false);
  const [popUpStatus, setPopUpSatus] = useState("accepted");
  const [warningDialog, setWarningDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    window.location.reload();
    setOpen(false);
    handleUpdateNotification();
    setWarningDialog(false);
  };

  const handleNotificationAction = (helperId, uniqueIdOfRequest, type) => {
    // accept notification
    let formdata = {
      userId: userid,
      helperId: helperId,
      uniqueIdOfRequest: uniqueIdOfRequest,
    };
    if (type == "accept") {
      PostAPI.AcceptOffer(formdata, (response) => {
        if (!response.status) {
          window.location.reload();
          alert("Something went wrong");
        } else if (response.status) {
          setPopUpSatus(t("noti.accepted"));
          handleClickOpen();
        } else {
          window.location.reload();
          alert("Something went wrong");
        }
      });
    } else {
      PostAPI.RejectOffer(formdata, (response) => {
        if (!response.status) {
          window.location.reload();
          alert("Something went wrong");
        } else if (response.status) {
          setPopUpSatus(t("noti.rejected"));
          handleClickOpen();
        } else {
          window.location.reload();
          alert("Something went wrong");
        }
      });
    }
    // (async () => {
    //   try {
    //     const res = await handleNotification(userID, notificationId, type);
    //     // console.log(res.data);
    //     setPopUpSatus(
    //       type === "accept" ? t("noti.accepted") : t("noti.rejected")
    //     );
    //     handleClickOpen();
    //   } catch (error) {
    //     console.log(error);
    //   }
    // })();
  };

  function displayNoti() {
    if (location.pathname.includes("/all")) {
      return notifications;
    } else {
      return notifications.slice(0, 3);
    }
  }

  return (
    <>
      <List sx={{ width: "100%" }} aria-label="notifications">
        {displayNoti().map((notification) => {
          return (
            <>
              <ListItem alignItems="flex-start" className="notification-card">
                <ListItemAvatar>
                  {/* <Avatar
                    alt={notification.helpedBy.picture}
                    src={notification.helpedBy.picture}
                    sx={{ width: 50, height: 50, mr: "15px" }}
                  /> */}
                  <img
                    alt={notification.helpedBy.picture}
                    src={notification.helpedBy.picture}
                    referrerPolicy="no-referrer"
                    style={{ width: 50, height: 50, borderRadius: "40px" }}
                  />
                </ListItemAvatar>
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <span style={{ color: "red", fontSize: 15 }}>
                        @{notification.helpedBy.userName}
                      </span>{" "}
                      would like to help you for your request{" "}
                      <span style={{ color: "red", fontSize: 15 }}>
                        {notification.request.title}
                      </span>
                      <Typography className="proximity">
                        {moment(notification.updatedAt).fromNow()}
                      </Typography>
                      <div style={{ paddingTop: "15px", textAlign: "end" }}>
                        <Button
                          className="accept-button"
                          variant="contained"
                          sx={{ borderRadius: 8, p: "3px 20px" }}
                          onClick={() => setWarningDialog(true)}
                        >
                          {t("noti.accept")}
                        </Button>
                        <Button
                          className="reject-button"
                          variant="outlined"
                          sx={{ borderRadius: 8, p: "3px 20px", ml: "15px" }}
                          onClick={() =>
                            handleNotificationAction(
                              notification.helperId,
                              notification.request._id,
                              "reject"
                            )
                          }
                        >
                          {t("noti.decline")}
                        </Button>
                      </div>
                      <Dialog
                        open={warningDialog}
                        onClose={() => setWarningDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                          maxWidth: "xs",
                          "& .MuiDialog-paper": {
                            borderRadius: 3,
                            width: "70%",
                            maxHeight: 435,
                          },
                        }}
                      >
                        <DialogTitle
                          id="alert-dialog-title"
                          textAlign={"center"}
                          variant="h5"
                          color="#D22108"
                          component="div"
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          {t("details.warning")}
                        </DialogTitle>
                        <DialogContent>
                          {/* <Typography sx={{ marginBottom: "10px" }}>
                            {t("details.beware")}
                          </Typography> */}
                          <Typography sx={{ marginBottom: "10px" }}>
                            {t("details.be_responsible")}
                          </Typography>
                          <Typography sx={{ marginBottom: "10px" }}>
                            <Trans i18nKey="details.tips" t={t}>
                              Click
                              <a
                                className="linkText"
                                href="https://tonytangebirah.wixsite.com/help/fraud-and-safety-policy"
                              >
                                here
                              </a>{" "}
                              for some tips to help you.
                            </Trans>
                          </Typography>
                          <Typography sx={{ marginBottom: "10px" }}>
                            {t("details.continue")}
                          </Typography>
                        </DialogContent>
                        <DialogActions
                          sx={{ "& .MuiDialogActions-root": { flex: "none" } }}
                        >
                          <Button
                            variant="contained"
                            sx={{ fontSize: 18 }}
                            onClick={() =>
                              handleNotificationAction(
                                notification.helperId,
                                notification.request._id,
                                "accept"
                              )
                            }
                          >
                            {t("details.continueBtn")}
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ fontSize: 18 }}
                            onClick={() => setWarningDialog(false)}
                          >
                            {t("details.cancelBtn")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>

      {/* POPUP */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          maxWidth: "xs",
          "& .MuiDialog-paper": {
            borderRadius: 3,
            width: "70%",
            maxHeight: 435,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          variant="h5"
          color="#D22108"
          component="div"
          sx={{ fontWeight: "bold", pt: "10%", textTransform: "uppercase" }}
        >
          <Trans i18nKey="noti.help" popUpStatus={popUpStatus}>
            HELP {{ popUpStatus }}!
          </Trans>
        </DialogTitle>
        {popUpStatus === t("noti.accepted") && (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              textAlign={"center"}
            >
              <Trans i18nKey="noti.archived_msg" popUpStatus={popUpStatus}>
                {/* To view all your accepted HELP Profile &gt; Help Requested &gt;
                Completed
                to view your completed requests. */}
              </Trans>
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              display: "flex",
              m: "auto",
              borderRadius: 3,
              p: "3% 30% 3% 30%",
              mb: "10%",
            }}
            onClick={handleClose}
          >
            {t("noti.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* END OF POPUP */}
    </>
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_id: PropTypes.number,
      from_id: PropTypes.number,
      message: PropTypes.string,
      connection_id: PropTypes.number,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      password: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      contacts: PropTypes.shape({
        Phone: PropTypes.number,
        Telegram: PropTypes.string,
      }),
    })
  ),
};
