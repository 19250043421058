import React from "react";

import Container from "@mui/material/Container";

const FooterSpacer = () => {
    return (
        <Container sx={{height:"100px"}}/>
    )
}

export default FooterSpacer