//import liraries
import React, { useRef, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  CardActionArea,
  Container,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  LOCATION_COORDINATES,
  LOCATION_NAME,
  EDIT_DECEASED_DIALOG,
} from "Redux/type.js";
import LocationBar from "pages/Map/components/LocationBar/LocationBar.js";
import PostAPI from "utils/POSTAPI.js";

const EditDeceased = (props) => {
  const dispatch = useDispatch();
  const [filename, setFilename] = useState("");
  const canvas = useRef();
  const [imgSrc, setImgSrc] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation(); // for translation
  const [open, setOpen] = useState(false);
  const [requestId, setRequestId] = useState();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [gender, setGender] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [dob, setDob] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [hairLength, setHairLength] = useState("");
  const [skinColor, setSkinColor] = useState("");
  const [dateBodyFound, setDateBodyFound] = useState("");
  const [description, setDescription] = useState("");
  const [objectId, setObjectId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { locationName, locationCoordinates } = useSelector(
    (state) => state.currentLocationCoordinates
  );
  const { editDeceasedData } = useSelector((state) => state.requestTypeReducer);
  const [male, setmale] = useState(false);
  const [female, setfemale] = useState(false);
  const [Infant, setInfant] = useState(false);
  const [twelve, settwelve] = useState(false);
  const [twelve_seventeen, settwelve_seventeen] = useState(false);
  const [eighteen_twentyfour, seteighteen_twentyfour] = useState(false);
  const [twentyfive_thirtyfour, settwentyfive_thirtyfour] = useState(false);
  const [thirtyfive_fortyfour, setthirtyfive_fortyfour] = useState(false);
  const [fortyfive_fiftyfour, setfortyfive_fiftyfour] = useState(false);
  const [fiftyfive_sixtyfour, setfiftyfive_sixtyfour] = useState(false);
  const [sixtyfive_seventyfour, setsixtyfive_seventyfour] = useState(false);
  const [seventyfive, setseventyfive] = useState(false);
  const [White, setWhite] = useState(false);
  const [Grey, setGrey] = useState(false);
  const [Blonde, setBlonde] = useState(false);
  const [Brunette, setBrunette] = useState(false);
  const [Red, setRed] = useState(false);
  const [Black, setBlack] = useState(false);
  const [Others, setOthers] = useState(false);
  const [Bald, setBald] = useState(false);
  const [BuzzCut, setBuzzCut] = useState(false);
  const [Ear, setEar] = useState(false);
  const [Neck, setNeck] = useState(false);
  const [Collar, setCollar] = useState(false);
  const [Shoulder, setShoulder] = useState(false);
  const [ArmPit, setArmPit] = useState(false);
  const [MidBack, setMidBack] = useState(false);
  const [Waist, setWaist] = useState(false);
  const [Pale, setPale] = useState(false);
  const [LightBrown, setLightBrown] = useState(false);
  const [Tanned, setTanned] = useState(false);
  const [DarkBrown, setDarkBrown] = useState(false);
  const [VeryDarkBrown, setVeryDarkBrown] = useState(false);

  const handleImage = async ({ target }) => {
    let image = URL.createObjectURL(target.files[0]);
    // console.log(image);
    // setDoneDetect(false);
    // setImgSrc(null);
    // console.log(target.files)
    // const base64 = await convertBase64(target.files[0]);
    setFilename(target.files[0].name);
    setImagePreview(image);
    setImgSrc(target.files[0]);
  };

  useEffect(() => {
    editDeceasedData.ageRange["Infant"] && setInfant(true);
    editDeceasedData.ageRange["12"] && settwelve(true);
    editDeceasedData.ageRange["12-17"] && settwelve_seventeen(true);
    editDeceasedData.ageRange["18-24"] && seteighteen_twentyfour(true);
    editDeceasedData.ageRange["25-34"] && settwentyfive_thirtyfour(true);
    editDeceasedData.ageRange["35-44"] && setthirtyfive_fortyfour(true);
    editDeceasedData.ageRange["45-54"] && setfortyfive_fiftyfour(true);
    editDeceasedData.ageRange["55-64"] && setfiftyfive_sixtyfour(true);
    editDeceasedData.ageRange["65-74"] && setsixtyfive_seventyfour(true);
    editDeceasedData.ageRange["75"] && setseventyfive(true);
    editDeceasedData.hairColor.White && setWhite(true);
    editDeceasedData.hairColor.Grey && setGrey(true);
    editDeceasedData.hairColor.Blonde && setBlonde(true);
    editDeceasedData.hairColor.Brunette && setBrunette(true);
    editDeceasedData.hairColor.Red && setRed(true);
    editDeceasedData.hairColor.Black && setBlack(true);
    editDeceasedData.hairColor.Others && setOthers(true);
    editDeceasedData.hairLength.Bald && setBald(true);
    editDeceasedData.hairLength.BuzzCut && setBuzzCut(true);
    editDeceasedData.hairLength.Ear && setEar(true);
    editDeceasedData.hairLength.Neck && setNeck(true);
    editDeceasedData.hairLength.Collar && setCollar(true);
    editDeceasedData.hairLength.Shoulder && setShoulder(true);
    editDeceasedData.hairLength.ArmPit && setArmPit(true);
    editDeceasedData.hairLength.MidBack && setMidBack(true);
    editDeceasedData.hairLength.Waist && setWaist(true);
    editDeceasedData.skinColor.Pale && setPale(true);
    editDeceasedData.skinColor.LightBrown && setLightBrown(true);
    editDeceasedData.skinColor.Tanned && setTanned(true);
    editDeceasedData.skinColor.DarkBrown && setDarkBrown(true);
    editDeceasedData.skinColor.VeryDarkBrown && setVeryDarkBrown(true);
    setFirstName(editDeceasedData.firstName || "");
    setLastName(editDeceasedData.lastName || "");
    setMiddleName(editDeceasedData.middleName || "");
    setNickName(editDeceasedData.nickName || "");
    setGender(
      (editDeceasedData.gender.male && t("maps.male")) ||
        (editDeceasedData.gender.female && t("maps.female")) ||
        ""
    );
    setAgeRange(
      (editDeceasedData.ageRange["Infant"] && t("maps.Infant")) ||
        (editDeceasedData.ageRange["12"] && t("maps.Under_12_years_old")) ||
        (editDeceasedData.ageRange["12-17"] && t("maps.12-17_years_old")) ||
        (editDeceasedData.ageRange["18-24"] && t("maps.18-24_years_old")) ||
        (editDeceasedData.ageRange["25-34"] && t("maps.25-34_years_old")) ||
        (editDeceasedData.ageRange["35-44"] && t("maps.35-44_years_old")) ||
        (editDeceasedData.ageRange["45-54"] && t("maps.45-54_years_old")) ||
        (editDeceasedData.ageRange["55-64"] && t("maps.55-64_years_old")) ||
        (editDeceasedData.ageRange["65-74"] && t("maps.65-74_years_old")) ||
        (editDeceasedData.ageRange["75"] && t("maps.75_years_or_older")) ||
        ""
    );
    if (editDeceasedData.dateOfBirth !== null) {
      setDob(moment(editDeceasedData.dateOfBirth).format("YYYY-MM-DD"));
    } else {
      setDob(null);
    }
    setEthnicity(editDeceasedData.ethnicity || "");
    setHairColor(
      (editDeceasedData.hairColor.White && t("maps.White")) ||
        (editDeceasedData.hairColor.Grey && t("maps.Grey")) ||
        (editDeceasedData.hairColor.Blonde && t("maps.Blonde")) ||
        (editDeceasedData.hairColor.Brunette && t("maps.Brunette")) ||
        (editDeceasedData.hairColor.Red && t("maps.Red")) ||
        (editDeceasedData.hairColor.Black && t("maps.Black")) ||
        (editDeceasedData.hairColor.Others && t("maps.Others")) ||
        ""
    );
    setHairLength(
      (editDeceasedData.hairLength.Bald && t("maps.Bald")) ||
        (editDeceasedData.hairLength.BuzzCut && t("maps.Buzz_Cut")) ||
        (editDeceasedData.hairLength.Ear && t("maps.Ear")) ||
        (editDeceasedData.hairLength.Neck && t("maps.Neck")) ||
        (editDeceasedData.hairLength.Collar && t("maps.Collar")) ||
        (editDeceasedData.hairLength.Shoulder && t("maps.Shoulder")) ||
        (editDeceasedData.hairLength.ArmPit && t("maps.Arm_Pit")) ||
        (editDeceasedData.hairLength.MidBack && t("maps.Mid_back")) ||
        (editDeceasedData.hairLength.Waist && t("maps.Waist")) ||
        ""
    );

    setSkinColor(
      (editDeceasedData.skinColor.Pale && t("maps.Pale")) ||
        (editDeceasedData.skinColor.LightBrown && t("maps.Light_Brown")) ||
        (editDeceasedData.skinColor.Tanned && t("maps.Tanned")) ||
        (editDeceasedData.skinColor.DarkBrown && t("maps.Dark_Brown")) ||
        (editDeceasedData.skinColor.VeryDarkBrown &&
          t("maps.Very_Dark_Brown")) ||
        ""
    );
    dispatch({
      type: LOCATION_NAME,
      payload: editDeceasedData.locationFound || "",
    });
    setDateBodyFound(
      moment(editDeceasedData.dateWhenBodyIsFound).format("YYYY-MM-DD") || ""
    );
    setDescription(editDeceasedData.description || "");
    setImagePreview(editDeceasedData.photo || null);
    dispatch({
      type: LOCATION_COORDINATES,
      payload: {
        lat: editDeceasedData.decLatitude,
        lng: editDeceasedData.decLongitude,
      },
    });
    setObjectId(editDeceasedData._id);
  }, []);

  const DeceasedPost = () => {
    setIsDisabled(true);
    let formdata = new FormData();
    formdata.append("firstName", firstName);
    formdata.append("middleName", middleName);
    formdata.append("lastName", lastName);
    formdata.append("nickName", nickName);
    formdata.append("dateOfBirth", dob);
    formdata.append("dateWhenBodyIsFound", moment(dateBodyFound).format());
    formdata.append("locationFound", locationName);
    formdata.append("description", description);
    formdata.append("decLatitude", locationCoordinates.lat);
    formdata.append("decLongitude", locationCoordinates.lng);
    formdata.append("photo", imgSrc);
    formdata.append("ethnicity", ethnicity);
    formdata.append("male", male);
    formdata.append("female", female);
    formdata.append("Infant", Infant);
    formdata.append("twelve", twelve);
    formdata.append("twelve_seventeen", twelve_seventeen);
    formdata.append("eighteen_twentyfour", eighteen_twentyfour);
    formdata.append("twentyfive_thirtyfour", twentyfive_thirtyfour);
    formdata.append("thirtyfive_fortyfour", thirtyfive_fortyfour);
    formdata.append("fortyfive_fiftyfour", fortyfive_fiftyfour);
    formdata.append("fiftyfive_sixtyfour", fiftyfive_sixtyfour);
    formdata.append("sixtyfive_seventyfour", sixtyfive_seventyfour);
    formdata.append("seventyfive", seventyfive);
    formdata.append("White", White);
    formdata.append("Grey", Grey);
    formdata.append("Blonde", Blonde);
    formdata.append("Brunette", Brunette);
    formdata.append("Red", Red);
    formdata.append("Black", Black);
    formdata.append("Others", Others);
    formdata.append("Bald", Bald);
    formdata.append("BuzzCut", BuzzCut);
    formdata.append("Ear", Ear);
    formdata.append("Neck", Neck);
    formdata.append("Collar", Collar);
    formdata.append("Shoulder", Shoulder);
    formdata.append("ArmPit", ArmPit);
    formdata.append("MidBack", MidBack);
    formdata.append("Waist", Waist);
    formdata.append("Pale", Pale);
    formdata.append("LightBrown", LightBrown);
    formdata.append("Tanned", Tanned);
    formdata.append("DarkBrown", DarkBrown);
    formdata.append("VeryDarkBrown", VeryDarkBrown);
    formdata.append("userId", localStorage.getItem("_id"));
    formdata.append("_id", objectId);
    PostAPI.EditDeceased(formdata, (response) => {
      if (!response.status) {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        setOpenDialog(false);
        alert("something went wrong");
        dispatch({
          type: EDIT_DECEASED_DIALOG,
          payload: false,
        });
        setIsDisabled(false);
      } else if (response.status) {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setImagePreview(null);
        setFilename("");
        setImgSrc(null);
        setOpenDialog(false);
        alert("Edited Deceased Report Successfully");
        dispatch({
          type: EDIT_DECEASED_DIALOG,
          payload: false,
        });
        window.location.reload();
        setIsDisabled(false);
      } else {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        setOpenDialog(false);
        alert("something went wrong");
        dispatch({
          type: EDIT_DECEASED_DIALOG,
          payload: false,
        });
        setIsDisabled(false);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const rootStyle = {
    width: "100%",
    backgroundColor: "white",
  };

  const smallTextStyle = {
    fontSize: 12,
    color: "gray",
  };
  const iconTextStyle = {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    marginTop: "5px",
    marginBottom: "5px",
  };

  const truncateDescription = (description) => {
    if (description.length > 40) {
      return description.slice(0, 40) + "...";
    } else {
      return description;
    }
  };
  const ContinueValid = (e) => {
    e.preventDefault();
  };
  return (
    <Container max_width="lg">
      <form onSubmit={ContinueValid}>
        <Grid item xs={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginBottom={1}
          >
            {t("deceased.name")}
          </Typography>

          <TextField
            name="first_name"
            fullWidth
            id="first_name"
            label={t("deceased.name")}
            size="small"
            sx={{ marginBottom: 2 }}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />

          <TextField
            name="middle_name"
            fullWidth
            id="middle_name"
            label={t("deceased.middle_name")}
            size="small"
            sx={{ marginBottom: 2 }}
            onChange={(e) => setMiddleName(e.target.value)}
            value={middleName}
          />

          <TextField
            name="last_name"
            fullWidth
            id="last_name"
            label={t("deceased.last_name")}
            size="small"
            sx={{ marginBottom: 2 }}
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />

          <TextField
            name="nickname"
            fullWidth
            id="nickname"
            label={t("deceased.nick_name")}
            size="small"
            sx={{ marginBottom: 2 }}
            onChange={(e) => setNickName(e.target.value)}
            value={nickName}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginBottom={1}
          >
            {t("deceased.gender")} *
          </Typography>

          <TextField
            required
            label={t("deceased.gender")}
            select
            id="gender"
            name="gender"
            sx={{ marginBottom: 2, minWidth: 100 }}
            value={gender}
            size="small"
            fullWidth
            onChange={(e) => setGender(e.target.value)}
          >
            {[t("maps.male"), t("maps.female")].map((item, index) => (
              <MenuItem
                key={item}
                onClick={() => {
                  setmale(index === 0);
                  setfemale(index === 1);
                }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginBottom={1}
          >
            {t("deceased.age_range")}
          </Typography>
          <TextField
            label={t("deceased.age_range")}
            select
            id="age_range"
            name="age_range"
            sx={{ marginBottom: 2, minWidth: 150 }}
            value={ageRange}
            size="small"
            fullWidth
            onChange={(e) => setAgeRange(e.target.value)}
          >
            {[
              t("maps.Infant"),
              t("maps.Under_12_years_old"),
              t("maps.12-17_years_old"),
              t("maps.18-24_years_old"),
              t("maps.25-34_years_old"),
              t("maps.35-44_years_old"),
              t("maps.45-54_years_old"),
              t("maps.55-64_years_old"),
              t("maps.65-74_years_old"),
              t("maps.75_years_or_older"),
            ].map((item, index) => (
              <MenuItem
                key={item}
                onClick={() => {
                  setInfant(index === 0);
                  settwelve(index === 1);
                  settwelve_seventeen(index === 2);
                  seteighteen_twentyfour(index === 3);
                  settwentyfive_thirtyfour(index === 4);
                  setthirtyfive_fortyfour(index === 5);
                  setfortyfive_fiftyfour(index === 6);
                  setfiftyfive_sixtyfour(index === 7);
                  setsixtyfive_seventyfour(index === 8);
                  setseventyfive(index === 9);
                }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginBottom={1}
          >
            {t("deceased.date_of_birth")}
          </Typography>
          <TextField
            fullWidth
            id="DOB"
            sx={{ marginBottom: 2 }}
            size="small"
            InputLabelProps={{ shrink: true }}
            name="date_of_birth"
            onChange={(e) =>
              e.target.value > moment().format("YYYY-MM-DD")
                ? setDob("")
                : setDob(e.target.value)
            }
            value={dob}
            type="date"
            inputProps={{ max: moment().format("YYYY-MM-DD") }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginBottom={1}
          >
            {t("deceased.ethnicity")}
          </Typography>
          <TextField
            fullWidth
            size="small"
            name="ethnicity"
            sx={{ marginBottom: 2 }}
            value={ethnicity}
            onChange={(e) => setEthnicity(e.target.value)}
            label={t("deceased.ethnicity")}
            id="ethnicity"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginBottom={1}
          >
            {t("deceased.features")}
          </Typography>

          <TextField
            label={t("deceased.hair_color")}
            select
            id="hair_color"
            name="hair_color"
            sx={{ marginBottom: 2, minWidth: 150 }}
            value={hairColor}
            size="small"
            fullWidth
            onChange={(e) => setHairColor(e.target.value)}
          >
            {[
              t("maps.White"),
              t("maps.Grey"),
              t("maps.Blonde"),
              t("maps.Brunette"),
              t("maps.Red"),
              t("maps.Black"),
              t("maps.Others"),
            ].map((item, index) => (
              <MenuItem
                key={item}
                onClick={() => {
                  setWhite(index === 0);
                  setGrey(index === 1);
                  setBlonde(index === 2);
                  setBrunette(index === 3);
                  setRed(index === 4);
                  setBlack(index === 5);
                  setOthers(index === 6);
                }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={t("deceased.hair_length")}
            select
            id="hair_length"
            name="hair_length"
            sx={{ marginBottom: 2, minWidth: 150 }}
            value={hairLength}
            size="small"
            fullWidth
            onChange={(e) => {
              setHairLength(e.target.value);
            }}
          >
            {[
              t("maps.Bald"),
              t("maps.Buzz_Cut"),
              t("maps.Ear"),
              t("maps.Neck"),
              t("maps.Collar"),
              t("maps.Shoulder"),
              t("maps.Arm_Pit"),
              t("maps.Mid_back"),
              t("maps.Waist"),
            ].map((item, index) => (
              <MenuItem
                key={item}
                onClick={() => {
                  setBald(index === 0);
                  setBuzzCut(index === 1);
                  setEar(index === 2);
                  setCollar(index === 3);
                  setShoulder(index === 4);
                  setArmPit(index === 5);
                  setWaist(index === 6);
                }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={t("deceased.skin_color")}
            select
            id="skin_color"
            name="skin_color"
            sx={{ marginBottom: 3, minWidth: 150 }}
            value={skinColor}
            size="small"
            fullWidth
            onChange={(e) => setSkinColor(e.target.value)}
          >
            {[
              t("maps.Pale"),
              t("maps.Light_Brown"),
              t("maps.Tanned"),
              t("maps.Dark_Brown"),
              t("maps.Very_Dark_Brown"),
            ].map((item, index) => (
              <MenuItem
                key={item}
                onClick={() => {
                  setPale(index === 0);
                  setLightBrown(index === 1);
                  setTanned(index === 2);
                  setDarkBrown(index === 3);
                  setVeryDarkBrown(index === 4);
                }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("maps.location")}
              </Typography>
              <TextField
                size="small"
                fullWidth
                required
                error={locationName && !useGPS}
                name={t("maps.location")}
                value={locationName}
                onChange={handleInput}
                disabled={useGPS}
                placeholder={t("maps.location")}
                label={t("maps.location")}
                id={t("maps.location")}
              />
              {status === "OK" && (
                <p style={{ padding: "20px" }}>
                  {useGPS ? "" : renderSuggestions()}
                </p>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox"
                      name="useGPS"
                      checked={useGPS}
                      onChange={handleGPSChange}
                      color="primary"
                    />
                  }
                  label={t("maps.current_location")}
                  fontSize="12"
                />
              </Grid>
            </Grid> */}

        {/* <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginTop={2}
                marginBottom={1}
              >
                {t("maps.location")}
              </Typography>
              <TextField
                size="small"
                fullWidth
                required
                error={locationName && !useGPS}
                name={t("maps.location")}
                value={locationName}
                onChange={handleInput}
                disabled={useGPS}
                placeholder={t("maps.location")}
                label={t("maps.location")}
                id={t("maps.location")}
              />
              {status === "OK" && (
                <p style={{ padding: "20px" }}>
                  {useGPS ? "" : renderSuggestions()}
                </p>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox"
                      name="useGPS"
                      checked={useGPS}
                      onChange={handleGPSChange}
                      color="primary"
                    />
                  }
                  label={t("maps.current_location")}
                  fontSize="12"
                />
              </Grid>
            </Grid> */}

        <LocationBar />

        <Grid item xs={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginBottom={1}
          >
            {t("deceased.date_body_found")} *
          </Typography>
          <TextField
            required
            fullWidth
            id="date_found"
            name="date_found"
            size="small"
            sx={{ marginBottom: 2 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              e.target.value > moment().format("YYYY-MM-DD")
                ? setDateBodyFound("")
                : setDateBodyFound(e.target.value);
            }}
            value={dateBodyFound}
            type="date"
            inputProps={{ max: moment().format("YYYY-MM-DD") }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginTop={2}
          >
            {t("deceased.description")} *
          </Typography>
          <TextField
            required
            fullWidth
            multiline
            rows={4}
            name="description"
            size="small"
            placeholder={t("deceased.description_placeholder")}
            id="description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </Grid>
        <>
          <div>
            <Typography
              variant="subtitle1"
              color="#D22108"
              component="div"
              sx={{ fontWeight: "bold", m: "1rem 0 0 5px" }}
            >
              {filename.length !== 0
                ? t("maps.selected_file") + filename
                : t("maps.select_file")}
            </Typography>
            {imagePreview ? (
              <div>
                <div style={{ position: "relative", margin: "10px 0" }}>
                  <img alt={filename} src={imagePreview} width={"100%"} />
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      zIndex: "3",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <canvas ref={canvas}></canvas>
                  </div>
                </div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                  >
                    {t("maps.reupload")}
                    <input
                      hidden
                      accept="image/jpeg, image/png, image/webp"
                      type="file"
                      onChange={handleImage}
                    />
                  </Button>
                </Stack>
              </div>
            ) : (
              <div style={{ textAlign: "center", padding: "10px 0" }}>
                <Button
                  id="upload-button"
                  variant="contained"
                  component="label"
                  startIcon={<PhotoCamera />}
                >
                  {t("maps.select")}
                  <input
                    name="upload"
                    id="upload"
                    hidden
                    accept="image/jpeg, image/png, image/webp"
                    type="file"
                    onChange={handleImage}
                  />
                </Button>
              </div>
            )}
            <Button
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              onClick={() => DeceasedPost()}
              disabled={
                imagePreview !== null &&
                locationName !== "" &&
                dateBodyFound !== "" &&
                gender !== "" &&
                description !== "" &&
                isDisabled == false
                  ? false
                  : true
              }
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              {t("maps.continue")}
            </Button>
            <Button
              onClick={() => {
                dispatch({
                  type: EDIT_DECEASED_DIALOG,
                  payload: false,
                });
                dispatch({
                  type: LOCATION_NAME,
                  payload: "",
                });
                dispatch({
                  type: LOCATION_COORDINATES,
                  payload: { lat: 0, lng: 0 },
                });
              }}
              fullWidth
              variant="outlined"
              sx={{
                color: "#D22108",
                borderColor: "#D22108",
                "&:hover": {
                  color: "#D22108",
                  borderColor: "#D22108",
                  backgroundColor: "white",
                },
                minWidth: "80px",
                borderRadius: "8px",
                marginBottom: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {t("maps.cancel")}
            </Button>
          </div>
        </>
      </form>
    </Container>
  );
};

export default EditDeceased;
