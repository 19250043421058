import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import mui
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";

// import local files
import EmptyFeed from "./EmptyFeed";
import { RequestCard } from "./RequestCard";
import LoadingDisplay from "components/LoadingDisplay";
import SearchBar from "./SearchBar";
import { NOTIFICATION } from "navigation/routeConfig";
import GETAPI from "utils/GETAPI";
import { useDispatch, useSelector } from "react-redux";
import { FEED_DATA, SEARCH_DATA } from "Redux/type";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Fade appear={false} in={!trigger}>
      {children}
    </Fade>
  );
}

export default function FeedIndex(props) {
  let navigate = useNavigate();
  let { userid } = useParams();
  const { searchData, feedData } = useSelector(
    (state) => state.requestTypeReducer
  );
  const [requests, setRequests] = useState([]); // to store requests
  const [isLoading, setIsLoading] = useState(false); // for loading display trigger
  const dispatch = useDispatch();
  console.log('feedData-length : ',feedData.length);
  // get all request on page load
  useEffect(() => {
    setIsLoading(true);
    GETAPI.GetRequests(userid, (response) => {
      console.log('response-status : ',response.status)
      if (!response.status) {
        setIsLoading(false);
        dispatch({
          type: SEARCH_DATA,
          payload: [],
        });
        dispatch({
          type: FEED_DATA,
          payload: [],
        });
      } else if (response.status) {
        setIsLoading(false);
        let filterData = response.data.filter(
          (data) => !data.offeredBy.includes(userid)
        );
        dispatch({
          type: SEARCH_DATA,
          payload: filterData,
        });
        dispatch({
          type: FEED_DATA,
          payload: filterData,
        });
      } else {
        dispatch({
          type: SEARCH_DATA,
          payload: [],
        });
        dispatch({
          type: FEED_DATA,
          payload: [],
        });
        setIsLoading(false);
      }
    });
    // setIsLoading(true);
  }, []);

  return (
    <Container maxWidth="lg">
      <div style={{ paddingBottom: "80px" }}>
        <div
          style={{ paddingTop: "3rem", paddingBottom: "1rem", display: "flex" }}
        >
          <SearchBar sx={{ flex: "auto" }} />
          <IconButton
            size="small"
            className="noti-icon"
            onClick={() => {
              navigate(`../${NOTIFICATION}`);
            }}
          >
            <NotificationsIcon
              className="notification-icon"
              style={{ fill: "#D22108", width: 30, fontSize: "2rem" }}
            />
          </IconButton>
        </div>

        {/* if loaded, check whether there is request, */}
        {/* else, display empty message */}
        {isLoading ? (
          <LoadingDisplay />
        ) : feedData.length !== 0 ? (
          <RequestCard requests={feedData} />
        ) : (
          <EmptyFeed />
        )}

        <HideOnScroll {...props}>
          <Fab aria-label="add" className="createBtn">
            <AddIcon
              className="add-icon"
              style={{ fill: "#FFFFFF" }}
              onClick={() => navigate(`new`)}
            />
          </Fab>
        </HideOnScroll>
      </div>
    </Container>
  );
}
