import {
  applyMiddleware,
  combineReducers,
  createStore,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import requestTypeReducer from "./reducers/requestTypeReducer.js";
import currentLocationCoordinates from "./reducers/currentLocationReducer.js";
import DeceasedReducer from "./reducers/DeceasedReducer.js";

const rootReducer = combineReducers({
  requestTypeReducer,
  currentLocationCoordinates,
  DeceasedReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
