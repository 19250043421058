import React, { useState, useCallback } from "react";

export const useDangerReportInfoStore = () => {
  const [location, setLocation] = useState("");
  const [locLat, setLocLat] = useState("");
  const [locLng, setLocLng] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [id, setId] = useState("");
  const [image, setImage] = useState([]);
  const [home, setHome] = useState("");
  const [advancedImage, setAdvancedImage] = useState([]);
  const [markerId, setMarkerId] = useState();

  const updateAutoFillInfo = useCallback(
    (parsedLocation, parsedDescription) => {
      setLocation(parsedLocation);
      setDescription(parsedDescription);
    },
    []
  );

  const updateEditInfo = useCallback((postInformation) => {
    console.log(postInformation);
    setLocation(postInformation.placeName);
    setDescription(postInformation.postContent);
    setCategory(postInformation.category);
    setImage(postInformation.photoUrls);
    setId(postInformation.id);
    setLocLat(postInformation.lat);
    setLocLng(postInformation.lon);
    setAdvancedImage(postInformation.images);
    setMarkerId(postInformation.markerId);
  }, []);

  const updatePostInfo = useCallback((placeLat, placeLng) => {
    setLocLat(placeLat);
    setLocLng(placeLng);
  }, []);

  const updateHome = useCallback((input) => {
    setHome(input);
  });

  return {
    location,
    markerId,
    advancedImage,
    description,
    category,
    image,
    locLat,
    locLng,
    home,
    id,
    updateHome,
    updateAutoFillInfo,
    updateEditInfo,
    updatePostInfo,
  };
};
