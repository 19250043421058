import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationOn, Public } from "@mui/icons-material";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedIcon from "@mui/icons-material/Verified";
import { RWebShare } from "react-web-share";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Container,
  Stack,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PostAPI from "utils/POSTAPI";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ChipButton from "../components/Map/components/ChipButton";
import ShareIcon from "@mui/icons-material/Share";
import EmptyProfileRequests from "pages/Profile/components/EmptyProfileRequests";
import PlaceDeatils from "./PlaceDetails.css";


const PlaceDetails = () => {
  //   const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dangerList, setDangerList] = useState([]);
  const { t } = useTranslation();
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [filename, setFilename] = useState("");
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [falseDialog, setFalseDialog] = useState(false);
  const [filter, setFilter] = useState([]);
  const [allLength, setAllLength] = useState(0);
  const [mineLength, setMineLength] = useState(0);
  const [unstableLength, setUnstableLength] = useState(0);
  const [blockLength, setBlockLength] = useState(0);
  const [spottedLength, setSpottedLength] = useState(0);
  const [floodsLength, setFloodsLength] = useState(0);
  const [fireLength, setFireLength] = useState(0);
  const [othersLength, setOthersLength] = useState(0);
  const [earthquakeLength, setEarthquakeLength] = useState(0);
  const [UnexplodedMinesandBombs, setUnexplodedMinesandBombs] = useState(false);
  const [UnstableStructuresandBuildings, setUnstableStructuresandBuildings] =
    useState(false);
  const [RoadBlocks, setRoadBlocks] = useState(false);
  const [AttackersSpotted, setAttackersSpotted] = useState(false);
  const [Floods, setFloods] = useState(false);
  const [Fire, setFire] = useState(false);
  const [Earthquake, setEarthquake] = useState(false);
  const [Others, setOthers] = useState(false);
  const [categoryData, setCategoryData] = useState({});

  React.useEffect(() => {
    getReports();
  }, []);
  console.log('categoryData : ', categoryData)
  const getReports = () => {
    let formdata = {
      locationLat: state.lat,
      locationLong: state.lng,
    };
    PostAPI.GetAreaDangerReports(formdata, (response) => {
      console.log('responseData : ', response.data)
      if (!response.status) {
        setDangerList([]);
        setFilter([]);
        filterDataLength([]);
      } else if (response.status) {
        setDangerList(response.data);
        setFilter(response.data);
        filterDataLength(response.data);
      } else {
        setDangerList([]);
        setFilter([]);
        filterDataLength([]);
      }
      const categories = {};
      response.data.forEach(item => {
        if (item.category) {
          const categoryName = item.category;
          categories[categoryName] = (categories[categoryName] || 0) + 1;
        }
      });
      setCategoryData(categories);
      setAllLength(response.data.length);
    });
  };

  const AddDangerLocation = () => {
    const data = new FormData();
    data.append("mineLatitude", state.lat);
    data.append("mineLongitude", state.lng);
    data.append("mineLocationName", state.title);
    data.append("description", description);
    data.append("UnexplodedMinesandBombs", UnexplodedMinesandBombs);
    data.append(
      "UnstableStructuresandBuildings",
      UnstableStructuresandBuildings
    );
    data.append("RoadBlocks", RoadBlocks);
    data.append("AttackersSpotted", AttackersSpotted);
    data.append("Floods", Floods);
    data.append("Fire", Fire);
    data.append("Earthquake", Earthquake);
    data.append("Others", Others);
    data.append("userId", localStorage.getItem("_id"));
    data.append("photo", imgSrc);
    PostAPI.UploadMineImage(data, (response) => {
      if (!response.status) {
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        setOpenDialog(false);
        setOpen(false);
        alert("something went wrong");
      } else if (response.status) {
        setImagePreview(null);
        setFilename("");
        setImgSrc(null);
        setOpenDialog(false);
        setOpen(false);
        alert("Image uploaded successful");
        window.location.reload();
      } else {
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        setOpenDialog(false);
        setOpen(false);
        alert("something went wrong");
      }
    });
  };

  const handleImage = async (e) => {
    console.log(e);
    e.preventDefault();
    console.log(e.target.files[0]);
    let image = URL.createObjectURL(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setImagePreview(image);
    setImgSrc(e.target.files[0]);
  };
  const filterData = (data) => {
    if (!data) {
      // If data is blank, show all data (dangerList in this case).
      setFilter(dangerList);
    } else {
      // Filter the data based on the category.
      const filteredData = dangerList.filter((item) => item.category === data);
      
      console.log('dangerListw :', dangerList)
      setFilter(filteredData);
    }
  };

  const filterDataLength = () => {
    const categories = Object.keys(categoryData);
    const categoryLengths = {};
    categories.forEach((category) => {
      const filteredData = dangerList.filter(
        (item) => item.category === category
      );
      categoryLengths[category] = filteredData.length;
    });
    setCategoryData(categoryLengths);
  };


  return (
    <div style={{ flexGrow: 1, marginBottom: "12%" }}>
      <AppBar style={{ backgroundColor: "white" }} position="static">
        <Toolbar>
          <IconButton
            onClick={() => navigate(-1)}
            edge="start"
            color="inherit"
            aria-label="back"
          >
            <ArrowBackIcon style={{ color: "black" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <img
        style={{ width: "100%", height: "40%" }}
        alt="big picture"
        src={state.photo}
      />
      <Typography
        style={{ fontWeight: "bold", marginLeft: "2%", marginTop: "3%" }}
        variant="h5"
      >
        {state.title}
      </Typography>
      <Typography
        style={{ marginLeft: "3%" }}
        variant="body2"
        color="textSecondary"
      >
        {state.placeType}
      </Typography>
      <Typography
        style={{ color: "blue", marginLeft: "3%" }}
        variant="body2"
        color="textSecondary"
      >
        {dangerList.length} Reviews
      </Typography>
      <RWebShare
        data={{
          text: `Danger. Please be careful Latitude: ${state.lat}, Longitude: ${state.lng},${state.photo}`,
          url: `${window.location.origin}/#/users/63d907d9cbc585c2d9456412/map`,
          title: "Share The Dangerous Location",
        }}
      >
        <Button
          startIcon={<ShareIcon />}
          variant="contained"
          color="primary"
          style={{
            height: "10%",
            width: "30%",
            marginLeft: "2%",
            marginTop: "2%",
          }}
        >
          Share
        </Button>
      </RWebShare>
      <div
        style={{
          backgroundColor: "gray",
          width: "100%",
          height: "0.5px",
          marginTop: "2%",
        }}
      />
      <Grid
        container
        style={{
          width: "100%",
          height: "5%",
          marginTop: "3%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={2}>
          <LocationOn style={{ color: "red", marginLeft: "10%" }} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2">{state.country}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Public style={{ marginLeft: "10%", color: "red" }} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2">
            Lat: {state.lat}, Long: {state.lng}
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundColor: "gray",
          width: "100%",
          height: "0.5px",
          marginTop: "2%",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "2%",
          marginRight: "2%",
          marginTop: "2%",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Potential Dangers
        </Typography>
        <Button
          onClick={() => setFalseDialog(true)}
          style={{
            display: "flex",
            alignItems: "center",
            color: "white",
            borderWidth: "1px",
            borderColor: "red",
            borderStyle: "solid",
            height: "30px",
            width: "30%",
          }}
        >
          <WarningIcon
            style={{
              marginRight: "10px",
              color: "red",
            }}
          />
          <Typography style={{ color: "red", fontSize: 14 }}>Report</Typography>
        </Button>
      </div>
      <Button
         onClick={() => filterData()}
         class = {'danger-cat-button'}
         >
          {'All'}  {allLength}
        </Button>
      {Object.keys(categoryData).map((category) => (        
        <Button
         key={category} 
         onClick={() => filterData(category)}
         class = {'danger-cat-button'}
         >
          {category}  {categoryData[category]}
        </Button>
      ))}
      {filter.length == 0 && <EmptyProfileRequests />}
      {filter.map((data, index) => (
        <>
          <div
            style={{
              backgroundColor: "gray",
              width: "100%",
              height: "0.5px",
              marginTop: "2%",
            }}
          />
          <List key={index} style={{ marginTop: "2%" }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  src={data.picture}
                  style={{ top: "6%", position: "absolute" }}
                />
              </ListItemAvatar>
              <div style={{ flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemText>{data.name}</ListItemText>
                  {data.adminApproved && (
                    <>
                      <VerifiedIcon
                        style={{
                          color: "red",
                        }}
                      />
                      <Typography
                        style={{
                          color: "red",
                        }}
                      >
                        {data.reason}
                      </Typography>
                    </>
                  )}
                </div>
                <Typography style={{ fontSize: 13 }}>
                  {moment(data.updatedAt).fromNow()}
                </Typography>
                <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
                  {data.category}
                  {/* {data.category.UnexplodedMinesandBombs &&
                    t("maps.unexploded")}
                  {data.category.UnstableStructuresandBuildings &&
                    t("maps.unstable")}
                  {data.category.RoadBlocks && t("maps.road_Blocks")}
                  {data.category.AttackersSpotted && t("maps.attackers")}
                  {data.category.Floods && t("maps.floods")}
                  {data.category.Fire && t("maps.fire")}
                  {data.category.Earthquake && t("maps.earthquake")}
                  {data.category.Others && t("maps.others")} */}
                </Typography>
                <div
                  style={{
                    width: "300px",
                    wordBreak: "break-all",
                  }}
                >
                  <ListItemText>{data.description}</ListItemText>
                </div>
                <img
                  width="300"
                  height="200"
                  alt="user post"
                  src={data.photo}
                />
              </div>
            </ListItem>
          </List>
        </>
      ))}
      <Dialog open={open}>
        <Container component="main" maxWidth="lg">
          {/* <Box
        sx={{
          marginTop: 0,
          marginLeft: 2,
          marginRight: 2,

          flexDirection: "column",
        }}
      > */}
          {/* <Box component="form" noValidate onSubmit={(e) => e.preventDefault()}> */}
          <Grid item xs={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginBottom={1}
            >
              {t("maps.category")}
            </Typography>
            <TextField
              label={t("maps.category")}
              select
              name={t("maps.category")}
              sx={{ minWidth: 150 }}
              value={category}
              size="small"
              fullWidth
              required
              onChange={(data) => {
                setCategory(data.target.value);
              }}
            >
              {[
                t("maps.unexploded"),
                t("maps.unstable"),
                t("maps.road_Blocks"),
                t("maps.attackers"),
                t("maps.floods"),
                t("maps.fire"),
                t("maps.earthquake"),
                t("maps.others"),
              ].map((item, index) => (
                <MenuItem
                  key={item}
                  onClick={() => {
                    setUnexplodedMinesandBombs(index === 0);
                    setUnstableStructuresandBuildings(index === 1);
                    setRoadBlocks(index === 2);
                    setAttackersSpotted(index === 3);
                    setFloods(index === 4);
                    setFire(index === 5);
                    setEarthquake(index === 6);
                    setOthers(index === 7);
                  }}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              {t("maps.description")}
            </Typography>

            <TextField
              required
              fullWidth
              multiline
              size="small"
              rows={4}
              name={t("maps.description")}
              label={t("maps.risk_information")}
              id={t("maps.description")}
              onChange={(data) => {
                setDescription(data.target.value);
              }}
              value={description}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              {t("maps.evidence")}
            </Typography>
            {imagePreview ? (
              <div>
                <div style={{ position: "relative", margin: "10px 0" }}>
                  <img alt={filename} src={imagePreview} width={"100%"} />

                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      zIndex: "3",
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                  >
                    {t("maps.reupload")}
                    <input
                      hidden
                      accept="image/jpeg, image/png, image/webp"
                      type="file"
                      onChange={handleImage}
                    />
                  </Button>
                </Stack>
              </div>
            ) : (
              <div style={{ textAlign: "center", padding: "10px 0" }}>
                <Button
                  id="upload-button"
                  variant="contained"
                  component="label"
                  startIcon={<PhotoCamera />}
                >
                  {t("maps.select")}
                  <input
                    name="upload"
                    id="upload"
                    hidden
                    accept="image/jpeg, image/png, image/webp"
                    type="file"
                    onChange={handleImage}
                  />
                </Button>
              </div>
            )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={
              category !== "" && description !== "" && imagePreview !== null
                ? false
                : true
            }
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#D22108",
              "&:hover": {
                backgroundColor: "#D22108",
                borderColor: "#D22108",
              },
            }}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            {t("maps.post")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              mb: 2,
              backgroundColor: "#D22108",
              "&:hover": {
                backgroundColor: "#D22108",
                borderColor: "#D22108",
              },
            }}
            onClick={() => setOpen(false)}
          >
            {t("details.cancelBtn")}
          </Button>
          {/* </Box> */}
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              maxWidth: "xs",
              "& .MuiDialog-paper": {
                borderRadius: 3,
                width: "70%",
                maxHeight: 435,
                zIndex: "5",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              textAlign={"center"}
              variant="h5"
              color="#D22108"
              component="div"
              sx={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {t("details.warning")}
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ marginBottom: "10px" }}>
                {t("details.beware")}
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{ "& .MuiDialogActions-root": { flex: "none" } }}
            >
              <Button
                variant="contained"
                sx={{ fontSize: 18 }}
                onClick={() => AddDangerLocation()}
              >
                {t("details.continueBtn")}
              </Button>
              <Button
                variant="outlined"
                sx={{ fontSize: 18 }}
                onClick={() => setOpenDialog(false)}
              >
                {t("details.cancelBtn")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* </Box> */}
          {/* </Box> */}
        </Container>
      </Dialog>
      <Dialog open={falseDialog}>
        <Box textAlign={"center"}>
          <div id="warning-title">{t("maps.false_alarm")}</div>
          <div>
            <div id="warning-description">{t("maps.false_alarm_text1")}</div>
            <div id="warning-description">{t("maps.false_alarm_text2")}</div>
            <div id="warning-description">
              {t("maps.false_alarm_text3") + t("maps.false_alarm_text4")}
            </div>
          </div>

          <Stack
            sx={{ padding: "30px" }}
            justifyContent={"center"}
            spacing={2}
            direction="column"
          >
            <Button
              fullWidth
              onClick={() => {
                setFalseDialog(false);
                setOpen(true);
              }}
              variant="contained"
              sx={{
                backgroundColor: "#D22108",
                "&:hover": {
                  backgroundColor: "#D22108",
                  borderColor: "#D22108",
                },
                minWidth: "80px",
                borderRadius: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {t("maps.continue")}
            </Button>

            <Button
              onClick={() => setFalseDialog(false)}
              fullWidth
              variant="outlined"
              sx={{
                color: "#D22108",
                borderColor: "#D22108",
                "&:hover": {
                  color: "#D22108",
                  borderColor: "#D22108",
                  backgroundColor: "white",
                },
                minWidth: "80px",
                borderRadius: "8px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {t("maps.cancel")}
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default PlaceDetails;
