import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN } from "./routeConfig";

const PrivateRoutes = () => {
  const [isLogin, setIsLogin] = useState(localStorage.getItem("_id"));

  useEffect(() => {
    setIsLogin(localStorage.getItem("_id"));
  }, []);

  return isLogin ? <Outlet /> : <Navigate to={LOGIN} />;
};

export default PrivateRoutes;
