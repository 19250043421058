import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// import mui
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import "./MediaCapture.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CURRENT_POSITION, DEFAULT_POSITION, DIALOG_DANGER } from "Redux/type";
import { useParams } from "react-router-dom";
import DangerPostWarningDialog from "./components/FalseAlarm/FalseAlarm";
import DangersReportScreen from "./components/Danger Report/DangerReport";
import MapContainer from "./components/Map/MapContainer";
import { useDangerPostWarningDialogStore } from "helper_methods/useDangerPostWarningStore";
import { useDangerReportDialogStore } from "helper_methods/useDangerReportDialogStore";
import { useBetween } from "use-between";
import { handleExtractLocations } from "helper_methods/LocationExtractor";

const MediaCapture = () => {
  const { t } = useTranslation(); // for translation
  const { dialogDanger } = useSelector((state) => state.requestTypeReducer);
  const { openDangerReportDialog } = useBetween(useDangerReportDialogStore);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const { dangerPostWarningDialog, closeDangerPostWarningDialog } = useBetween(
    useDangerPostWarningDialogStore
  );
  // const libraries = ["places"];
  const canvas = useRef();
  let ctx = null;
  const GetUserCoordinates = () => {
    const geolocationAPI = navigator.geolocation;

    if (!geolocationAPI) {
      console.log("no location service :(");
      setLat(null);
      setLong(null);
    } else {
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setLat(coords.latitude);
          setLong(coords.longitude);
          dispatch({
            type: CURRENT_POSITION,
            payload: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          });
          dispatch({
            type: DEFAULT_POSITION,
            payload: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          });
        },
        (error) => {
          // console.log("some geolocation error")
          // console.log(error)
          setLat(null);
          setLong(null);
        }
      );
    }
  };

  useEffect(() => {
    GetUserCoordinates();
  }, []);
  // GetUserCoordinates(setLat, setLong);

  // initialize the canvas context
  // useEffect(() => {
  //   if (doneDetect) {
  //     // dynamically assign the width and height to canva s
  //     const canvasEle = canvas.current;
  //     canvasEle.width = canvasEle.parentElement.clientWidth;
  //     canvasEle.height = canvasEle.parentElement.clientHeight;
  //     // get context of the canvas
  //     ctx = canvasEle.getContext("2d");
  //     let bboxes = predictions.bboxes;
  //     for (let i = 0; i < bboxes.length; i++) {
  //       let box = bboxes[i];
  //       let displayName = predictions.displayNames[i];
  //       let confidence = predictions.confidences[i];
  //       let x0, x1, y0, y1;
  //       x0 = box[0];
  //       x1 = box[1];
  //       y0 = box[2];
  //       y1 = box[3];
  //       let r1Info = {
  //         x: canvasEle.width * x0,
  //         y: canvasEle.height * y0,
  //         w: canvasEle.width * x1 - canvasEle.width * x0,
  //         h: canvasEle.height * y1 - canvasEle.height * y0,
  //         confidence: confidence,
  //         displayName: displayName,
  //       };
  //       const r1Style = { borderColor: "red", borderWidth: 2 };
  //       drawRect(r1Info, r1Style);
  //     }
  //   }
  // }, [doneDetect]);

  // draw rectangle
  const drawRect = (info, style = {}) => {
    const { x, y, w, h, confidence, displayName } = info;
    const { borderColor = "black", borderWidth = 1 } = style;

    ctx.beginPath();
    ctx.font = "18px Arial";
    ctx.fillStyle = "red";
    ctx.fillText(`${displayName}: ${confidence.toFixed(3) * 100}%`, x, y - 5);
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderWidth;
    ctx.rect(x, y, w, h);
    ctx.stroke();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // const handleDetect = () => {
  //   setIsLoading(true);
  //   // console.log("detecting")
  //   (async () => {
  //     try {
  //       console.log("sending api");
  //       // console.log(imgSrc)
  //       let res = await getUXOBoundingBoxes(imgSrc.split(",")[1]);
  //       // console.log(res)
  //       let predictions = JSON.parse(res.data[0]).predictions[0];
  //       // console.log(JSON.stringify(predictions))
  //       setPredictions(predictions);
  //       setIsLoading(false);
  //       setDoneDetect(true);
  //     } catch (error) {
  //       console.log(error);
  //       setIsLoading(false);
  //       setOpen(true);
  //     }
  //   })();
  // };

  function handleContinue() {
    closeDangerPostWarningDialog();
    dispatch({
      type: DIALOG_DANGER,
      payload: true,
    });
  }

  return (
    <div style={{ paddingBottom: "80px" }}>
      <Box
        sx={{
          background: "#D22108",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          pb: 2,
          zIndex: 4,
        }}
      >
        <Container maxWidth="lg" sx={{ zIndex: "4", width: "100%" }}>
          <div className="mt-3">
            <Typography
              variant="h5"
              color="#FFFFFF"
              component="div"
              sx={{ fontWeight: "bold", m: "1rem 0 0 5px" }}
            >
              {t("maps.page_title")}
            </Typography>
          </div>
        </Container>
      </Box>
      <MapContainer />
      <>
        <Dialog open={dialogDanger}>
          <DangersReportScreen />
        </Dialog>
        <Dialog open={dangerPostWarningDialog}>
          <DangerPostWarningDialog
            closeWarning={closeDangerPostWarningDialog}
            next={() => {
              handleContinue();
            }}
          />
        </Dialog>
      </>
    </div>
  );
};

export default MediaCapture;
