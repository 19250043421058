import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
// import mui
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// import local files
import { AppRoutes } from "navigation/AppRoutes";
import "./App.css";

function App() {

  useEffect(() => {
    clearCacheData();
  }, []);
  // set up app theme
  const theme = createTheme({
    palette: {
      primary: {
        main: "#D22108",
      },
      white: {
        main: "#FFF",
      },
    },
  });
  const clearCacheData = () => { 
    console.log('p');
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });  
    console.log('j');
    
};
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* overwrite css order */}
        <StyledEngineProvider injectFirst>
          {/* all routes found in AppRoutes */}
          <AppRoutes />
          <Outlet />
        </StyledEngineProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
