import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
// import mui
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import PostAPI from "utils/POSTAPI.js";
import GETAPI from "utils/GETAPI";
import "../Login/Login.css";

//icons
import { ReactComponent as WhatsAppIcon } from "assets/whatsapp.svg";
import { ReactComponent as TelegramIcon } from "assets/telegram.svg";
import { ReactComponent as ViberIcon } from "assets/viber.svg";
import { ReactComponent as WechatIcon } from "assets/wechat.svg";
import { ReactComponent as FacebookIcon } from "assets/facebook-messenger.svg";
import { ReactComponent as LineIcon } from "assets/line.svg";
import { ReactComponent as MessageIcon } from "assets/phone-message.svg";

// local files
import { createNotification } from "utils/api";
import { ACCEPT_REQUEST, FEED } from "navigation/routeConfig";
import FeedBanner from "../Feed/components/FeedBanner";
// import Warning from "./Warning";

export default function RequestDetails(props) {
  const { state } = useLocation();

  // console.log(state.data.validatedBeneficiary);
  let navigate = useNavigate(); // for url navigation
  let params = useParams(); // use to obtain request id from url
  const { t } = useTranslation("request"); // for translation
  const [open, setOpen] = useState(false);
  const [button, setButton] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const idDATA = localStorage.getItem("_id");
  const [reason, setReason] = useState();

  // use to toggle contact display
  const contactType = {
    Whatsapp: WhatsAppIcon,
    Telegram: TelegramIcon,
    Viber: ViberIcon,
    Wechat: WechatIcon,
    Facebook: FacebookIcon,
    Line: LineIcon,
    Phone: MessageIcon,
  };

  useEffect(() => {
    profile();
  }, []);
  const profile = () => {
    GETAPI.GetProfileById(state.data.userId, (response) => {
      console.log('response : ', response)
      console.log('state : ', state.data)
      if (!response.status) {
        setReason('');
      } else if (response.status ) {
        setReason(response.data.reason);
      } else {
        setReason('');
      }
    });
  };

  // send notification
  const sendNotification = (helperId, _id, userId) => {
    let formdata = {
      helperId: helperId,
      objUniqueId: _id,
      userId: userId,
    };
    PostAPI.OfferHelp(formdata, (response) => {
      setOpen(false);
      if (!response.status) {
        setOpen(false);
        alert("Something went wrong");
      } else if (response.status) {
        setOpen(false);
        navigate(ACCEPT_REQUEST);
      } else {
        alert("Something went wrong");
      }
    });
  };

  useEffect(() => {
    test();
  }, []);

  const test = () => {
    const found = state.data.offeredBy.filter((e) => e == idDATA);
    if (found.length > 0) {
      setButton(false);
    } else {
      setButton(true);
    }
    if (state.user == "User") {
      setButtonShow(true);
    } else {
      setButtonShow(false);
    }
  };

  // handles quantity display
  const handleQuantity = (quantity) => {
    console.log('lkkl',state);
    if (quantity) {
      if (state.data.helpType == "accommodation" || state.data.helpType == "transport" || state.data.helpType == "food" ) {
        
        return (
          <>
            <Typography gutterBottom variant="h6" className="details-title">
              {t("details.num_quantity")}
            </Typography>
            {state.data.numberOfPeopleInNeed.adult !== 0 ? (
              <Box>
                {state.data.numberOfPeopleInNeed.adult} {t("details.adults")}
              </Box>
            ) : (
              ""
            )}
            {state.data.numberOfPeopleInNeed.children !== 0 ? (
              <Box>
                {state.data.numberOfPeopleInNeed.children} {t("details.children")}
              </Box>
            ) : (
              ""
            )}
            {state.data.numberOfPeopleInNeed.infant !== 0 ? (
              <Box>
                {state.data.numberOfPeopleInNeed.infant} {t("details.infants")}
              </Box>
            ) : (
              ""
            )}
            
          </>
        );
      }
      return (
        <>
          <Typography gutterBottom variant="h6" className="details-title">
            {t("new.quantity")}
          </Typography>
          <Box>{state.data.quantity}</Box>
          { state.data.photo && (
              <img
              src={state.data.photo}
              style={{
                width: "100%",
                maxWidth: "400px",
                border: "1px solid #000",
              }}
              alt="icon"
            />
            )}
        </>
      );
      
    }
  };

  // handles contact display
  const handleContactDisplay = (mode) => {
    if (mode) {
      return (
        <>
          {state.data.contact.map((mode) => {
            const ContactTypeIcon = contactType[mode];
            return ContactTypeIcon ? (
              <ContactTypeIcon
                style={{ fill: "#E97E6F", width: 18, margin: "5 2 2 2" }}
                key={mode}
              />
            ) : (
              ""
            );
          })}
        </>
      );
    }
  };

  // handles button click
  const handleClickOpen = () => {
    setOpen(true);
  };

  // handles 'cancel' button click
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="request-details" style={{ paddingBottom: "80px" }}>
      <FeedBanner
        backPage={FEED}
        icon={state.data.helpType}
        title={state.data.title}
        username={state.data.userName}
        verifiedReason={reason}
      />
      <Container maxWidth="lg">
        <Box b={10} sx={{ padding: "0 5px" }}>
          {handleQuantity(state)}
          {state.data.helpType == "transport" ? (
            <>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                className="details-title"
              >
                {t("details.from")}
              </Typography>

              <Typography gutterBottom component="div">
                {state.data.transportLocation.from.name}
              </Typography>

              <Typography
                gutterBottom
                variant="h6"
                component="div"
                className="details-title"
              >
                {t("details.to")}
              </Typography>

              <Typography gutterBottom component="div">
                {state.data.transportLocation.to.name}
              </Typography>
            </>
          ) : (
            ""
          )}

          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="details-title"
          >
            {t("details.description")}
          </Typography>

          <Typography gutterBottom component="div">
            {state.data.description}
          </Typography>

          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="details-title"
          >
            {t("details.preferred_contacts")}
          </Typography>

          {handleContactDisplay(state.data.contact)}
        </Box>
        {buttonShow ? (
          <div></div>
        ) : button ? (
          <Box textAlign="center">
            <Button
              disabled={state.data.offeredBy.length >= 5 ? true : false}
              className="accept-request mt-3"
              variant="contained"
              sx={{ fontSize: 18 }}
              onClick={handleClickOpen}
            >
              {state.data.offeredBy.length >= 5
                ? "Help Limit Reached"
                : t("details.acceptBtn")}
            </Button>
          </Box>
        ) : (
          <Box textAlign="center">
            <Button
              className="accept-request mt-3"
              variant="contained"
              sx={{ fontSize: 18 }}
            >
              {t("details.offeredBtn")}
            </Button>
          </Box>
        )}
      </Container>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          maxWidth: "xs",
          "& .MuiDialog-paper": {
            borderRadius: 3,
            width: "70%",
            maxHeight: 435,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          variant="h5"
          color="#D22108"
          component="div"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          {t("details.warning")}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ marginBottom: "10px" }}>
            {t("details.beware")}
          </Typography>
          <Typography sx={{ marginBottom: "10px" }}>
            {t("details.be_responsible")}
          </Typography>
          <Typography sx={{ marginBottom: "10px" }}>
            <Trans i18nKey="details.tips" t={t}>
              Click
              <a
                className="linkText"
                href="https://tonytangebirah.wixsite.com/help/fraud-and-safety-policy"
              >
                here
              </a>{" "}
              for some tips to help you.
            </Trans>
          </Typography>
          <Typography sx={{ marginBottom: "10px" }}>
            {t("details.continue")}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ "& .MuiDialogActions-root": { flex: "none" } }}>
          <Button
            variant="contained"
            sx={{ fontSize: 10 }}
            onClick={() =>
              sendNotification(
                localStorage.getItem("_id"),
                state.data._id,
                state.data.userId
              )
            }
          >
            {t("details.continueBtn")}
          </Button>
          <Button
            variant="outlined"
            sx={{ fontSize: 18 }}
            onClick={handleCancel}
          >
            {t("details.cancelBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
