import { React, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

// import mui
import FormLabel from "@mui/material/FormLabel";
import {
  Box,
  Container,
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

//local files
import FeedBanner from "../../Feed/components/FeedBanner";
import Counter from "./Counter";
//utils
import { GetUserCoordinates } from "utils/locationService";
import { FEED } from "navigation/routeConfig";
import PostAPI from "utils/POSTAPI";
import Geocode from "react-geocode";
import { GOOGLE_MAPS_API_KEY } from "utils/GoogleMapKey";
import LocationBar from "pages/Map/components/LocationBar/LocationBar";
import { useDispatch, useSelector } from "react-redux";
import LocationToBar from "pages/Map/components/LocationBar/ToLocationBar";
import Stack from "@mui/material/Stack";
import GETAPI from "utils/GETAPI";
import {
  PROFILE_DATA,
} from "Redux/type";

import {
  LOCATION_COORDINATES,
  LOCATION_NAME,
  LOCATION_TO_COORDINATES,
  LOCATION_TO_NAME,
} from "Redux/type";
const NewRequestForm = (props) => {
  let { type } = useParams();
  Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
  type = type.toLowerCase();
  let navigate = useNavigate();
  const [filename, setFilename] = useState("");
  const { t } = useTranslation("request"); // for translation
  const [imgSrc, setImgSrc] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  let location = useLocation();
  const dispatch = useDispatch();
  const [title, setTitle] = useState(
    location.state && location.state.title ? location.state.title : ""
  );
  const [quantity, setQuantity] = useState(1);
  const [locationToLat, setLocationToLat] = useState("");
  const [locationToLong, setLocationToLong] = useState("");
  const [locationLat, setLocationLat] = useState("");
  // const [locationName, setLocationName] = useState("");
  const [locationLong, setLocationLong] = useState("");
  const [description, setDescription] = useState(
    location.state && location.state.description
      ? location.state.description
      : ""
  );
  const [verifiedReason, setVerifiedReason] = useState("");
  const [contactOptions, setContactOptions] = useState([]);
  const [expiry, setExpiry] = useState(3);
  const [numPeopleInNeed, setNumPeopleInNeed] = useState({
    adult: 0,
    children: 0,
    infant: 0,
  });
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const {
    locationName,
    locationCoordinates,
    locationToCoordinates,
    locationToName,
  } = useSelector((state) => state.currentLocationCoordinates);

  GetUserCoordinates(setLat, setLong);

  let setQuantityValue = (e) => {
    let value = e.target.value;
    // if (value < 0 || isNaN(value)) {
    //   return 0;
    // } else if (value >= 21 || isNaN(value)) {
    //   return 20;
    // }
    setQuantity(value);
  };

  useEffect(() => {
    profile();
  }, []);
  
  // Now, categoryList state should contain the filtered and processed data
  
  
    const profile = () => {
      GETAPI.GetProfile((response) => {
        if (!response.status) {
          dispatch({
            type: PROFILE_DATA,
            payload: {},
          });
          setVerifiedReason("");
        } else if (response.status) {
          dispatch({
            type: PROFILE_DATA,
            payload: response.data,
          });
          setVerifiedReason(response.data.reason);
        } else {
          dispatch({
            type: PROFILE_DATA,
            payload: {},
          });
          setVerifiedReason("");
        }
      });
    };

  const handleImage = async ({ target }) => {
    let image = URL.createObjectURL(target.files[0]);
    // const base64 = await convertBase64(target.files[0]);
    setFilename(target.files[0].name);
    setImagePreview(image);
    setImgSrc(target.files[0]);
  };

  let setLocationFromInput = (input, type) => {
    // if (input && input !== "Current Location") return { name: locationFrom };
    // return { lat: lat, lng: long };
    Geocode.fromAddress(input).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        if (type == "from") {
          setLocationLat(lat);
          setLocationLong(lng);
        } else {
          setLocationToLat(lat);
          setLocationToLong(lng);
        }
      },
      (error) => {}
    );
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    let payload;

    let expiryDate = new Date();
    let today = new Date();

    expiryDate.setDate(today.getDate() + expiry); // expiry is a number encoding the number of days before post expires

    let commonFields = {
      userId: localStorage.getItem("_id"),
      title: title,
      description: description,
      expiryDate: expiryDate,
      helpType: type,
      contact: contactOptions,
    };
    switch (type) {
      case "miscellaneous":
        payload = {
          ...commonFields,
          quantity: quantity,
          currentLocationLatitude: locationCoordinates.lat,
          currentLocationLongitude: locationCoordinates.lng,
          photo: imgSrc,
        };
        break;
      case "accommodation":
        payload = {
          ...commonFields,
          numberOfPeopleInNeed: numPeopleInNeed,
          currentLocationLatitude: locationCoordinates.lat,
          currentLocationLongitude: locationCoordinates.lng,
        };
        break;
      case "transport":
        payload = {
          ...commonFields,
          numberOfPeopleInNeed: numPeopleInNeed,
          transportLocationToName: locationToName,
          transportLocationFromName: locationName,
          transportLocationFromLatitude: locationCoordinates.lat,
          transportLocationFromLongitude: locationCoordinates.lng,
          transportLocationToLatitude: locationToCoordinates.lat,
          transportLocationToLongitude: locationToCoordinates.lng,
        };
        break;
      case "food":
        payload = {
          ...commonFields,
          numberOfPeopleInNeed: numPeopleInNeed,
          currentLocationLatitude: locationCoordinates.lat,
          currentLocationLongitude: locationCoordinates.lng,
        };
        break;
      default:
        payload = {
          ...commonFields,
          quantity: quantity,
          currentLocationLatitude: locationCoordinates.lat,
          currentLocationLongitude: locationCoordinates.lng,
          photo: imgSrc,
        };
        break;
    }
    (async () => {
      PostAPI.CreateRequest(payload, (response) => {
        if (!response.status) {
          alert("Something went wrong");
        } else if (response.status) {
          dispatch({
            type: LOCATION_NAME,
            payload: "",
          });
          dispatch({
            type: LOCATION_TO_NAME,
            payload: "",
          });
          dispatch({
            type: LOCATION_TO_COORDINATES,
            payload: { lat: 0, lng: 0 },
          });
          dispatch({
            type: LOCATION_COORDINATES,
            payload: { lat: 0, lng: 0 },
          });
          navigate("success");
        } else {
          alert("Something went wrong");
        }
      });
    })();
  };

  return (
    <Box style={{ paddingBottom: "80px" }}>
      <FeedBanner
        backPage={`${FEED}/new`}
        title={`${t("new.request")}: ${(
          `${type.charAt(0) + type.slice(1)}`
        )}`}
        username={localStorage.getItem("userName")}
        verifiedReason = {verifiedReason}
        icon={type}
      />
      <Container max_width="lg">
        <form onSubmit={handleSubmit}>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            spacing={2}
          >
            <Grid item style={{ width: "100%" }}>
              <FormLabel
                name="title"
                component="div"
                sx={{ fontWeight: "bold", color: "#D22108" }}
                required
              >
                {t("new.title")}
              </FormLabel>
              <TextField
                id="title-input"
                name="Title"
                placeholder={t("new.title")}
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                multiline={true}
                required
              />
            </Grid>
            {type === "medical" ? (
              <>
                <Grid item style={{ width: "100%" }}>
                  <FormLabel
                    name="quantity"
                    component="div"
                    sx={{ fontWeight: "bold", color: "#D22108" }}
                    required
                  >
                    {t("new.quantity")}
                  </FormLabel>
                  <TextField
                    id="quantity-input"
                    name="Quantity"
                    placeholder={t("new.quantity")}
                    // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    value={quantity}
                    onChange={setQuantityValue}
                    fullWidth
                    required
                  />
                </Grid>
                <LocationBar />
              </>
            ) : (
              ""
            )}
            {type === "accommodation" || type === "food" || type === "transport" ? (
              <Grid item>
                <FormLabel
                  name="numPeopleInNeed"
                  component="div"
                  sx={{ fontWeight: "bold", color: "#D22108" }}
                  required
                >
                  {t("details.num_quantity")}
                </FormLabel>
                <Counter
                  label={t("details.adults")}
                  count={numPeopleInNeed["adult"]}
                  setCount={(val) =>
                    setNumPeopleInNeed({ ...numPeopleInNeed, adult: val })
                  }
                />
                <Counter
                  label={t("details.children")}
                  count={numPeopleInNeed["children"]}
                  setCount={(val) =>
                    setNumPeopleInNeed({ ...numPeopleInNeed, children: val })
                  }
                />
                <Counter
                  label={t("details.infants")}
                  count={numPeopleInNeed["infant"]}
                  setCount={(val) =>
                    setNumPeopleInNeed({ ...numPeopleInNeed, infant: val })
                  }
                />
              </Grid>
            ) : (
              <>
                <Grid item style={{ width: "100%" }}>
                  <FormLabel
                    name="quantity"
                    component="div"
                    sx={{ fontWeight: "bold", color: "#D22108" }}
                    required
                  >
                    {t("new.quantity")}
                  </FormLabel>
                  <TextField
                    id="quantity-input"
                    name="Quantity"
                    placeholder={t("new.quantity")}
                    // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    value={quantity}
                    onChange={setQuantityValue}
                    fullWidth
                    required
                  />
                </Grid>
                <LocationBar />
              </>
            )}
            {type === "accommodation" ? <LocationBar /> : ""}
            {type === "food" ? <LocationBar /> : ""}
            {type === "transport" ? <LocationBar text="from" /> : ""}
            {type === "transport" ? <LocationToBar /> : ""}

            <Grid item style={{ width: "100%" }}>
              <FormLabel
                name="ContactOptions"
                component="div"
                sx={{ fontWeight: "bold", color: "#D22108" }}
                required
              >
                {t("details.contacts")}
              </FormLabel>
              <FormControl required style={{ width: "100%" }}>
                <Select
                  id="contactOptions"
                  name="ContactOptions"
                  value={contactOptions}
                  multiple
                  onChange={(e) => setContactOptions(e.target.value)}
                  enabled
                >
                  <MenuItem key="Facebook" value="Facebook">
                    Facebook Messenger
                  </MenuItem>
                  <MenuItem key="Line" value="Line">
                    LINE
                  </MenuItem>
                  <MenuItem key="Phone" value="Phone">
                    Phone text message
                  </MenuItem>
                  <MenuItem key="Telegram" value="Telegram">
                    Telegram
                  </MenuItem>
                  <MenuItem key="Viber" value="Viber">
                    Viber
                  </MenuItem>
                  <MenuItem key="Wechat" value="Wechat">
                    WeChat
                  </MenuItem>
                  <MenuItem key="Whatsapp" value="Whatsapp">
                    Whatsapp
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <FormLabel
                name="description"
                component="div"
                sx={{ fontWeight: "bold", color: "#D22108" }}
                required
              >
                {t("details.description")}
              </FormLabel>
              <TextField
                id="description-input"
                name="Description"
                placeholder={t("details.description")}
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                size="large"
                // variant="outlined"
                multiline={true}
                rows={4}
                required
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <FormLabel
                name="expiry"
                component="div"
                sx={{ fontWeight: "bold", color: "#D22108" }}
                required
              >
                {t("new.expired")}
              </FormLabel>
              <FormControl required style={{ width: "100%" }}>
                <Select
                  id="expiry"
                  name="Expiry"
                  value={expiry}
                  onChange={(e) => setExpiry(e.target.value)}
                  enabled={true}
                >
                  <MenuItem key="threeDay" value={3}>
                    {t("new.3d")}
                  </MenuItem>
                  <MenuItem key="fiveDay" value={5}>
                    {t("new.5d")}
                  </MenuItem>
                  <MenuItem key="oneWeek" value={7}>
                    {t("new.1wk")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {type !== "accommodation" && type !== "food" && type !== "transport" ? (
            <Grid>
            <Typography
                  variant="subtitle1"
                  color="#D22108"
                  component="div"
                  sx={{ fontWeight: "bold", m: "1rem 0 0 5px" }}
                >
                  {filename.length !== 0
                    ? t("new.selected_file") + filename
                    : "Take a photo or select one from gallery that is less then 1 MB *"}
                </Typography>
                {imagePreview ? (
                  <div>
                    <div style={{ position: "relative", margin: "10px 0" }}>
                      <img alt={filename} src={imagePreview} width={"100%"} />
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          zIndex: "3",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        
                      </div>
                    </div>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<PhotoCamera />}
                      >
                        {t("new.reupload")}
                        <input
                          hidden
                          accept="image/jpeg, image/png, image/webp"
                          type="file"
                          onChange={handleImage}
                        />
                      </Button>
                    </Stack>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", padding: "10px 0" }}>
                    <Button
                      id="upload-button"
                      variant="contained"
                      component="label"
                      startIcon={<PhotoCamera />}
                    >
                      {t("new.select")}
                      <input
                        name="upload"
                        id="upload"
                        hidden
                        accept="image/jpeg, image/png, image/webp"
                        type="file"
                        onChange={handleImage}
                      />
                    </Button>
                  </div>
                )}
              </Grid>
              ) : (
                ""
              )}
            <Grid item justifyContent="center">
              <Button variant="contained" color="primary" type="submit">
                {t("new.submitBtn")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default NewRequestForm;

// interface propState {
//   description: string;
//   title: string;
//   label: string;
// }
