import React from "react";
import { GoogleApiWrapper, Map } from "google-maps-react";
import { GOOGLE_MAPS_API_KEY } from "utils/GoogleMapKey";

export const API_KEY = GOOGLE_MAPS_API_KEY;

function MapCon(props) {
  return (
    <Map
      containerStyle={{ height: "0%", width: "0%" }}
      google={props.google}
    ></Map>
  );
}

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(MapCon);
