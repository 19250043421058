import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, MenuItem, Typography, Container, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { GOOGLE_MAPS_API_KEY } from "utils/GoogleMapKey";
import Geocode from "react-geocode";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useTranslation } from "react-i18next";
import PostAPI from "utils/POSTAPI";
import { useSelector, useDispatch } from "react-redux";
import LocationBar from "../LocationBar/LocationBar";
import { DIALOG_DANGER, LOCATION_COORDINATES, LOCATION_NAME } from "Redux/type";
import "../../../../App.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import { handleExtractLocations } from "helper_methods/LocationExtractor";
import GETAPI from "utils/GETAPI";

// input
export default function DangersReportScreen() {
  // const fileRef = useRef();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
  const { locationName, locationCoordinates } = useSelector(
    (state) => state.currentLocationCoordinates
  );
  const [imagePreview, setImagePreview] = useState(null);
  const [filename, setFilename] = useState("");
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  // const [UnexplodedMinesandBombs, setUnexplodedMinesandBombs] = useState(false);
  // const [UnstableStructuresandBuildings, setUnstableStructuresandBuildings] =
  //   useState(false);
  // const [RoadBlocks, setRoadBlocks] = useState(false);
  // const [AttackersSpotted, setAttackersSpotted] = useState(false);
  // const [Floods, setFloods] = useState(false);
  // const [Fire, setFire] = useState(false);
  // const [Earthquake, setEarthquake] = useState(false);
  // const [Others, setOthers] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState([]);
  // To Preview Images
  const handleImage = async (e) => {
    e.preventDefault();
    let image = URL.createObjectURL(e.target.files[0]);

    setFilename(e.target.files[0].name);
    setImagePreview(image);
    setImgSrc(e.target.files[0]);
  };

  useEffect(() => {
    setDescription((prevDescription) => prevDescription + finalTranscript);
    resetTranscript();
  }, [finalTranscript]);

  const handleClose = () => {
    setOpen(false);
  };
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    // Use useEffect to make the request only once when the component mounts
    GETAPI.GetCategoryList((response) => {
      if (!response.status) {
        setCategoryTitle([]);
      } else if (response.status) {
        setCategoryTitle(response.data.data);

        // Create categoryList here, inside the callback
        const filteredCategoryList = response.data.data
          .filter((element) => element.status === true && element.displayType === 'Danger') 
          .map((element) => element.name);
        
        setCategoryList(filteredCategoryList);
      } else {
        setCategoryTitle([]);
      }
    });
  }, []);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
 

  const uploadMineImage = () => {

    const data = new FormData();
    data.append("mineLatitude", locationCoordinates.lat);
    data.append("mineLongitude", locationCoordinates.lng);
    data.append("mineLocationName", locationName);
    data.append("category", category);

    // data.append("UnexplodedMinesandBombs", UnexplodedMinesandBombs);
    // data.append(
    //   "UnstableStructuresandBuildings",
    //   UnstableStructuresandBuildings
    // );
    // data.append("RoadBlocks", RoadBlocks);
    // data.append("AttackersSpotted", AttackersSpotted);
    // data.append("Floods", Floods);
    // data.append("Fire", Fire);
    // data.append("Earthquake", Earthquake);
    // data.append("Others", Others);
    data.append("description", description);
    data.append("userId", localStorage.getItem("_id"));
    data.append("photo", imgSrc);

    // const requestData = {
      // mineLatitude: locationCoordinates.lat,
      // mineLongitude: locationCoordinates.lng,
      // mineLocationName: locationName,
      // UnexplodedMinesandBombs: UnexplodedMinesandBombs,
      // UnstableStructuresandBuildings: UnstableStructuresandBuildings,
      // RoadBlocks: RoadBlocks,
      // AttackersSpotted: AttackersSpotted,
      // Floods: Floods,
      // Fire: Fire,
      // Earthquake: Earthquake,
      // Others: Others,
      // description: description,
      // userId: localStorage.getItem("_id"),
      // photo: imgSrc,
    // };
      console.log("uploadMineImage data",data);

    PostAPI.UploadMineImage(data, (response) => {
      console.log("UploadMineImage response", response);
      if (!response.status) {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        handleClose();
        alert("something went wrong");
      } else if (response.status) {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setImagePreview(null);
        setFilename("");
        setImgSrc(null);
        handleClose();
        alert("Reported Danger Successfully");
        window.location.reload();
      } else {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        handleClose();
        alert("something went wrong");
      }
    });
  };

  return (
    <Container component="main" max_width="lg">
      {/* <Box
        sx={{
          marginTop: 0,
          marginLeft: 2,
          marginRight: 2,

          flexDirection: "column",
        }}
      > */}
      {/* <Box component="form" noValidate onSubmit={(e) => e.preventDefault()}> */}
      <Grid item xs={12}>
        <Typography
          color="#D22108"
          fontWeight="500"
          component="h5"
          variant="subtitle1"
          align="left"
          gutterBottom
          marginBottom={1}
        >
          {t("maps.category")}
        </Typography>
        <TextField
          label={t("maps.category")}
          select
          name={t("maps.category")}
          sx={{ minWidth: 150 }}
          value={category}
          size="small"
          fullWidth
          required
          onChange={(data) => {
            setCategory(data.target.value);
          }}
        >
          {categoryList.map((item, index) => (
            <MenuItem
              key={item}
              onClick={() => {
                // setUnexplodedMinesandBombs(index === 0);
                // setUnstableStructuresandBuildings(index === 1);
                // setRoadBlocks(index === 2);
                // setAttackersSpotted(index === 3);
                // setFloods(index === 4);
                // setFire(index === 5);
                // setEarthquake(index === 6);
                // setOthers(index === 7);
              }}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <LocationBar />
      <Grid item xs={12} sm={12}>
        <Typography
          color="#D22108"
          fontWeight="500"
          component="h5"
          variant="subtitle1"
          align="left"
          gutterBottom
          marginTop={2}
          marginBottom={1}
        >
          {t("maps.description")}
        </Typography>

        <TextField
          required
          fullWidth
          multiline
          size="small"
          rows={4}
          name={t("maps.description")}
          placeholder={t("maps.risk_information")}
          id={t("maps.description")}
          onChange={(data) => {
            setDescription(data.target.value);
          }}
          value={description}
        />
      </Grid>
      <Grid
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "15px",
          flexDirection: "column",
          gap: "10px",
        }}
        item
        xs={12}
        sm={12}
      >
        <Button
          variant="contained"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: "30px",
            height: "61px",
          }}
          onClick={SpeechRecognition.startListening}
        >
          <MicIcon color="white" />
        </Button>
        <span>Microphone: {listening ? "on" : "off"}</span>
      </Grid>
      <Grid item xs={12}>
        <Typography
          color="#D22108"
          fontWeight="500"
          component="h5"
          variant="subtitle1"
          align="left"
          gutterBottom
          marginTop={2}
          marginBottom={1}
        >
          {t("maps.evidence")}
        </Typography>
        {imagePreview ? (
          <div>
            <div style={{ position: "relative", margin: "10px 0" }}>
              <img alt={filename} src={imagePreview} width={"100%"} />

              <div
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: "3",
                  width: "100%",
                  height: "100%",
                }}
              >
                {/* <canvas ref={canvas}></canvas> */}
              </div>
            </div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
              >
                {t("maps.reupload")}
                <input
                  hidden
                  accept="image/jpeg, image/png, image/webp"
                  type="file"
                  onChange={handleImage}
                />
              </Button>
            </Stack>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "10px 0" }}>
            <Button
              id="upload-button"
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              {t("maps.select")}
              <input
                name="upload"
                id="upload"
                hidden
                accept="image/jpeg, image/png, image/webp"
                type="file"
                onChange={handleImage}
              />
            </Button>
          </div>
        )}
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={
          category !== "" &&
          locationName !== "" &&
          description !== "" &&
          imagePreview !== null
            ? false
            : true
        }
        sx={{
          mt: 3,
          mb: 2,
          backgroundColor: "#D22108",
          "&:hover": {
            backgroundColor: "#D22108",
            borderColor: "#D22108",
          },
        }}
        onClick={() => uploadMineImage()}
      >
        {t("maps.post")}
      </Button>
      <Button
        onClick={() =>
          dispatch({
            type: DIALOG_DANGER,
            payload: false,
          })
        }
        fullWidth
        variant="outlined"
        sx={{
          mb: 2,
          color: "#D22108",
          borderColor: "#D22108",
          "&:hover": {
            color: "#D22108",
            borderColor: "#D22108",
            backgroundColor: "white",
          },
          minWidth: "80px",
          borderRadius: "8px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {t("maps.cancel")}
      </Button>
      {/* </Box> */}
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          maxWidth: "xs",
          "& .MuiDialog-paper": {
            borderRadius: 3,
            width: "70%",
            maxHeight: 435,
            zIndex: "5",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign={"center"}
          variant="h5"
          color="#D22108"
          component="div"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          {t("details.warning")}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ marginBottom: "10px" }}>
            {t("details.beware")}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ "& .MuiDialogActions-root": { flex: "none" } }}>
          <Button
            variant="contained"
            sx={{ fontSize: 18 }}
            onClick={() => uploadMineImage()}
          >
            {t("details.continueBtn")}
          </Button>
          <Button
            variant="outlined"
            sx={{ fontSize: 18 }}
            onClick={handleClose}
          >
            {t("details.cancelBtn")}
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* </Box> */}
      {/* </Box> */}
    </Container>
  );
}
