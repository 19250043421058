import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
// import { makeStyles } from "@material-ui/core/styles";
import "./FalseAlarm.css";

import { useBetween } from "use-between";
import { useDeletePostDialogStore } from "helper_methods/useDeletePostDialogStore";
import { useDangerPostWarningDialogStore } from "helper_methods/useDangerPostWarningStore";
import { useDangerReportDialogStore } from "helper_methods/useDangerReportDialogStore";
import { useTranslation } from "react-i18next";

// const useStyles = makeStyles(() => ({
//   dialog: {
//     width: "300px",
//   },
// }));

export default function DangerPostWarningDialog(props) {
  const { t } = useTranslation();
  const { next, closeWarning } = props;
  const text1 =
    "False alarms can be a major hazard to any alarm system since they lead to a loss of confidence in the system.";
  const text2 =
    "However, it is better to be safe than sorry and so innocent false alarms are understandable.";
  const text3 =
    "However, Malicious False Alarms, that is, any person who knowingly gives or causes to be given a false alarm, should be discouraged and held accountable.";
  const text4 = "HELP will work with the Authorities in such circumstances.";
  var text = text1 + "\n" + text2 + "\n" + text3 + "\n" + text4;
  // const classes = useStyles();
  const { dangerPostWarningDialog, closeDangerPostWarningDialog } = useBetween(
    useDangerPostWarningDialogStore
  );
  const { openDangerReportDialog } = useBetween(useDangerReportDialogStore);

  function handleContinue() {
    closeDangerPostWarningDialog();
    openDangerReportDialog();
  }

  return (
    <Dialog
      open={dangerPostWarningDialog}
      // classes={{ paper: classes.dialog }}
    >
      <Box textAlign={"center"}>
        <div id="warning-title">{t("maps.false_alarm")}</div>
        <div>
          <div id="warning-description">{t("maps.false_alarm_text1")}</div>
          <div id="warning-description">{t("maps.false_alarm_text2")}</div>
          <div id="warning-description">
            {t("maps.false_alarm_text3") + " " + t("maps.false_alarm_text4")}
          </div>
        </div>

        <Stack
          sx={{ padding: "30px" }}
          justifyContent={"center"}
          spacing={2}
          direction="column"
        >
          <Button
            fullWidth
            onClick={() => next()}
            variant="contained"
            sx={{
              backgroundColor: "#D22108",
              "&:hover": {
                backgroundColor: "#D22108",
                borderColor: "#D22108",
              },
              minWidth: "80px",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("maps.continue")}
          </Button>

          <Button
            onClick={closeDangerPostWarningDialog}
            fullWidth
            variant="outlined"
            sx={{
              color: "#D22108",
              borderColor: "#D22108",
              "&:hover": {
                color: "#D22108",
                borderColor: "#D22108",
                backgroundColor: "white",
              },
              minWidth: "80px",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("maps.cancel")}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
