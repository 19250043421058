import axios from "axios";
import { BaseURL } from "./BaseURL";

const PostAPI = {
  GoogleLogin: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/user/googlelogin`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  FBLogin: async (data, callback) => {
    console.log("data",data);
    axios({
      method: "POST",
      url: `${BaseURL}/user/facebooklogin`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  CreateRequest: async (data, callback) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${BaseURL}/request/createnewrequest`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  OfferHelp: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/helpoffered/helpoffer`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetFilteredList: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/request/getfilteredrequests`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetUserRequestList: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/request/getuserrequests`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  AcceptOffer: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/helpoffered/acceptoffer`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  RejectOffer: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/helpoffered/rejectoffer`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetUserOfferList: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/helpoffered/gethelpofferedlist`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  UploadMineImage: async (data, callback) => {
    console.log("UploadMineImage POSTapi", data);
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${BaseURL}/helpoffered/addmineImage`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  UploadDeceasedImage: async (data, callback) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${BaseURL}/helpoffered/adddeceasedimage`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetAreaDangerReports: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/danger/dangerLocationFilter`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  AddDeceased: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/deceased/addDeceased`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  EditDeceased: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/deceased/editDeceased`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  FilterDeceased: async (data, callback) => {
    
    axios({
      method: "POST",
      url: `${BaseURL}/deceased/deceasedFilter`,
      data: data,
    })
      .then((response) => {
        console.log(response)
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  EditProfile: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/user/editProfile`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
};

export default PostAPI;
