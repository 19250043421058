import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetUserCoordinates } from "utils/locationService";
import { useDispatch } from "react-redux";

// import parseUrlCoordinates from "./map_helper/parseUrlCoordinates";

import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { Fab, Box, Container, Typography } from "@mui/material";

import { useBetween } from "use-between";
import { useDangerReportInfoStore } from "helper_methods/useDangerReportInfoStore";
import { useDangerReportDialogStore } from "helper_methods/useDangerReportDialogStore";
import { useDangerPostWarningDialogStore } from "helper_methods/useDangerPostWarningStore";
import { useParams } from "react-router-dom";
import RoomIcon from "@mui/icons-material/Room";

// helper method to get the markers from the backend
// import { useMarkers } from "../api/dangerous_location/markerApi";
import { GOOGLE_MAPS_API_KEY } from "utils/GoogleMapKey";
import "./MapContainer.css";
import { CURRENT_POSITION, DEFAULT_POSITION } from "Redux/type";
import GETAPI from "utils/GETAPI";
import { MAP, PLACEDETAILS, USER } from "navigation/routeConfig";

export const API_KEY = GOOGLE_MAPS_API_KEY;

const searchContainerStyle = {
  padding: 20,
  alignItems: "center",
  position: "absolute",
  zIndex: 1,
};

const containerStyle = {
  height: "100%",
  zIndex: 1,
};

function MapContainer(props) {
  const { t } = useTranslation();
  // const { markers, loading } = useMarkers();
  const { position, showClickableMarker, defaultPosition } = useSelector(
    (state) => state.currentLocationCoordinates
  );
  let { type, userid } = useParams();

  const navigate = useNavigate();
  const { openDangerReportDialog } = useBetween(useDangerReportDialogStore);
  const { openDangerPostWarningDialog } = useBetween(
    useDangerPostWarningDialogStore
  );
  const { updateHome } = useBetween(useDangerReportInfoStore);
  const [address, setAddress] = useState("");
  let zoomLevel = 15;
  const dispatch = useDispatch();
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [dangerLocation, setDangerLocation] = useState([]);

  const GetUserCoordinates = () => {
    const geolocationAPI = navigator.geolocation;

    if (!geolocationAPI) {
      console.log("no location service :(");
      setLat(null);
      setLong(null);
    } else {
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setLat(coords.latitude);
          setLong(coords.longitude);
          dispatch({
            type: CURRENT_POSITION,
            payload: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          });
          dispatch({
            type: DEFAULT_POSITION,
            payload: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          });
        },
        (error) => {
          // console.log("some geolocation error")
          // console.log(error)
          setLat(null);
          setLong(null);
        }
      );
    }
  };

  useEffect(() => {
    GetUserCoordinates();
    GETAPI.GetMineLocations((response) => {
      if (!response.status) {
        setDangerLocation([]);
      } else if (response.status) {
        setDangerLocation(response.data);
      } else {
        setDangerLocation([]);
      }
    });
  }, []);

  // set the center of the map based on the current url (as long as the props change)
  // useEffect(() => {
  //   // console.log(props);
  //   let pos = props.position;
  //   console.log(pos);
  //   if (pos != "") {
  //     pos = parseUrlCoordinates(pos);
  //     setCenter(pos);
  //   }
  // }, [props.position]);

  // change the url based on users' actions so that the center of the map can be reset
  // useEffect(() => {
  //   if (currentCoords != null) {
  //     console.log(currentCoords);
  //     navigate("/map/@" + currentCoords.lat + "," + currentCoords.lng);
  //   }
  // }, [currentCoords]);

  const handleChange = (address) => {
    setAddress(address);
  };

  function reverseGeocodeCoordinates(lat, lng, photo) {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
    ) //INPUT GEOCODE API HERE
      .then((response) => response.json())
      .then((data) => {
        let code = data.results[0];
        const address = data.results[0].formatted_address;
        const addressArray = address.split(",");
        const country = addressArray[addressArray.length - 1];
        const addressLine = addressArray[0];
        navigate(`/${USER}/${localStorage.getItem("_id")}/${PLACEDETAILS}`, {
          state: {
            address,
            title: addressLine,
            placeType: code.types[0],
            country: country,
            lat,
            lng,
            photo,
          },
        });
      })
      .catch((error) => alert(error));
  }

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        dispatch({
          type: DEFAULT_POSITION,
          payload: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        });
        dispatch({
          type: CURRENT_POSITION,
          payload: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        });
        // console.log("Success", latLng.lat);
        // update center state
        // setCurrentCoords(latLng);
      })
      .catch((error) => console.error("Error", error));
  };
  // handle the action of clicking the marker

  return (
    <div style={containerStyle}>
      <div style={searchContainerStyle}>
        <PlacesAutocomplete
          value={address}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onChange={handleChange}
          onSelect={handleSelect}
          key="AIzaSyB3o4KOTL6IpypI0a0DYJrjwIAm-d9x19k"
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                style={{ borderRadius: 10, height: 30 }}
                {...getInputProps({
                  placeholder: "  Search Places ...",
                  className: "location-search-input",
                })}
              />

              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#000000",
                        color: "#ffffff",
                        cursor: "pointer",
                        padding: 10,
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        padding: 10,
                      };
                  return (
                    <div
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>

      <Map
        containerStyle={{ height: "81%" }}
        google={props.google}
        initialCenter={defaultPosition}
        center={defaultPosition}
        zoom={zoomLevel}
        mapTypeControlOptions={{
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          zIndex: "10",
        }}
      >
        <Marker icon={require("./images/focus_red.png")} position={position} />
        {dangerLocation.map((marker, index) => (
          <Marker
            key={index}
            onClick={(data) => {
              reverseGeocodeCoordinates(
                data.position.lat,
                data.position.lng,
                marker.photo
              );
            }}
            position={{ lat: marker.mineLatitude, lng: marker.mineLongitude }}
          />
        ))}
        <Fab
          className="report-dangers-fab"
          style={{
            position: "absolute",
            bottom: "4%",
            left: "0",
            zIndex: "1",
            backgroundColor: "#D22108",
            color: "white",
          }}
          variant="extended"
          size="small"
          onClick={() => {
            updateHome("FromMap");
            openDangerPostWarningDialog();
          }}
        >
          <ReportProblemRoundedIcon sx={{ mr: 1 }} />
          {t("maps.report_dangers")}
        </Fab>
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(MapContainer);
