import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Container,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import ShareIcon from "@mui/icons-material/Share";
import { RWebShare } from "react-web-share";
import { useTranslation } from "react-i18next";

const DeceasedDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const smallTextStyle = {
    fontSize: 12,
    color: "gray",
  };
  
  const iconTextStyle = {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    marginTop: "10px",
    marginBottom: "10px",
    color: "gray",
  };
  const separator = {
    marginTop: "30px",
    marginBottom: "30px",
  };

  const ageRangeMap = {
    Infant: t("maps.Infant"),
    12: t("maps.Under_12_years_old"),
    "12-17": t("maps.12-17_years_old"),
    "18-24": t("maps.18-24_years_old"),
    "25-34": t("maps.25-34_years_old"),
    "35-44": t("maps.35-44_years_old"),
    "45-54": t("maps.45-54_years_old"),
    "55-64": t("maps.55-64_years_old"),
    "65-74": t("maps.65-74_years_old"),
    75: t("maps.75_years_or_older"),
  };

  const skinColorMap = {
    Pale: t("maps.Pale"),
    LightBrown: t("maps.Light_Brown"),
    Tanned: t("maps.Tanned"),
    DarkBrown: t("maps.Dark_Brown"),
    VeryDarkBrown: t("maps.Very_Dark_Brown"),
  };

  const hairColorMap = {
    White: t("maps.White"),
    Grey: t("maps.Grey"),
    Blonde: t("maps.Blonde"),
    Brunette: t("maps.Brunette"),
    Red: t("maps.Red"),
    Black: t("maps.Black"),
    Others: t("maps.Others"),
  };

  const hairLengthMap = {
    Bald: t("maps.Bald"),
    BuzzCut: t("maps.Buzz_Cut"),
    Ear: t("maps.Ear"),
    Neck: t("maps.Neck"),
    Collar: t("maps.Collar"),
    Shoulder: t("maps.Shoulder"),
    ArmPit: t("maps.Arm_Pit"),
    MidBack: t("maps.Mid_back"),
    Waist: t("maps.Waist"),
  };
  const genderMap = {
    male: t("maps.male"),
    female: t("maps.female"),
  };

  const currentURL = window.location.href;

  // Remove '/deceased_details' from the URL
  const updatedURL = currentURL.replace('/deceased_details', '');
  return (
    <div style={{ flexGrow: 1, marginBottom: "12%" }}>
      <AppBar
        style={{
          backgroundColor: "primary",
          display: "flex",
          justifyContent: "center",
        }}
        position="static"
      >
        <Toolbar>
          <IconButton
            onClick={() => navigate(-1)}
            edge="start"
            color="inherit"
            aria-label="back"
          >
            <ArrowBackIcon style={{ color: "white" }} />
          </IconButton>
          <Typography style={{ color: "white", marginLeft: "3%" }}>
            {t("deceased.deceased_information")}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ padding: "30px" }}>
        <Typography
          style={{ color: "red", fontWeight: "bold", letterSpacing: "0.5px" }}
        >
          {state.data.description}
        </Typography>
        <span style={smallTextStyle}>
          {moment(state.data.createdAt).fromNow()}
        </span>
        <RWebShare
          data={{
            text: `If you suspect that the deceased is your immediate family member and would like to verify the identity,
             please contact Gebirah's admin at gebirahad@gmail.com or www.gebirah.org .`,
            url: `${updatedURL}/deceased`,
            title: "Share This",
          }}
        >
          <Button
            startIcon={<ShareIcon />}
            variant="contained"
            color="primary"
            style={{
              height: "10%",
              width: "40%",
              marginLeft: "2%",
              marginTop: "2%",
            }}
          >
            {t("deceased.share")}
          </Button>
        </RWebShare>
        <div style={separator} />
        <Typography
          style={{ color: "red", fontWeight: "bold", letterSpacing: "0.5px" }}
        >
          {t("deceased.basic_information")}
        </Typography>
        <React.Fragment>
          <div style={iconTextStyle}>
            <AccountBoxOutlinedIcon />
            {state.data.firstName == "" &&
            state.data.middleName == "" &&
            state.data.lastName == ""
              ? "Unknown"
              : state.data.firstName +
                " " +
                state.data.middleName +
                " " +
                state.data.lastName}{" "}
            [{t("deceased.name")}]
          </div>
          <div style={iconTextStyle}>
            <AccessTimeIcon />
            {moment(state.data.dateWhenBodyIsFound).format("YYYY-MM-DD")} [
            {t("deceased.date_found")}]
          </div>
          <div style={iconTextStyle}>
            <LocationOnOutlinedIcon />
            {state.data.locationFound.split(", ").slice(-4, -3)[0] +
              " " +
              state.data.locationFound.split(", ").slice(-1)[0]}{" "}
            [{t("deceased.location")}]
          </div>
        </React.Fragment>
        <div style={separator} />
        <Typography
          style={{ color: "red", fontWeight: "bold", letterSpacing: "0.5px" }}
        >
          {t("deceased.features")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              color: "gray",
            }}
          >
            {t("deceased.age_range")}:
          </span>
          <div style={{ color: "gray" }}>
            {(state.data.ageRange["Infant"] && t("maps.Infant")) ||
              (state.data.ageRange["12"] && t("maps.Under_12_years_old")) ||
              (state.data.ageRange["12-17"] && t("maps.12-17_years_old")) ||
              (state.data.ageRange["18-24"] && t("maps.18-24_years_old")) ||
              (state.data.ageRange["25-34"] && t("maps.25-34_years_old")) ||
              (state.data.ageRange["35-44"] && t("maps.35-44_years_old")) ||
              (state.data.ageRange["45-54"] && t("maps.45-54_years_old")) ||
              (state.data.ageRange["55-64"] && t("maps.55-64_years_old")) ||
              (state.data.ageRange["65-74"] && t("maps.65-74_years_old")) ||
              (state.data.ageRange["75"] && t("maps.75_years_or_older")) ||
              "Unknown"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              color: "gray",
            }}
          >
            {t("deceased.gender")}:
          </span>
          <div style={{ color: "gray" }}>
            {(state.data.gender.male && t("maps.male")) ||
              (state.data.gender.female && t("maps.female")) ||
              "Unknown"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              color: "gray",
            }}
          >
            {t("deceased.ethnicity")}:
          </span>
          <div style={{ color: "gray" }}>
            {state.data.ethnicity || "Unknown"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              color: "gray",
            }}
          >
            {t("deceased.hair_color")}:
          </span>
          <div style={{ color: "gray" }}>
            {(state.data.hairColor.White && t("maps.White")) ||
              (state.data.hairColor.Grey && t("maps.Grey")) ||
              (state.data.hairColor.Blonde && t("maps.Blonde")) ||
              (state.data.hairColor.Brunette && t("maps.Brunette")) ||
              (state.data.hairColor.Red && t("maps.Red")) ||
              (state.data.hairColor.Black && t("maps.Black")) ||
              (state.data.hairColor.Others && t("maps.Others")) ||
              "Unknown"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              color: "gray",
            }}
          >
            {t("deceased.hair_length")}:
          </span>
          <div style={{ color: "gray" }}>
            {/* {state.data.hairLength || "Unknown"} */}
            {(state.data.hairLength.Bald && t("maps.Bald")) ||
              (state.data.hairLength.BuzzCut && t("maps.Buzz_Cut")) ||
              (state.data.hairLength.Ear && t("maps.Ear")) ||
              (state.data.hairLength.Neck && t("maps.Neck")) ||
              (state.data.hairLength.Collar && t("maps.Collar")) ||
              (state.data.hairLength.Shoulder && t("maps.Shoulder")) ||
              (state.data.hairLength.ArmPit && t("maps.Arm_Pit")) ||
              (state.data.hairLength.MidBack && t("maps.Mid_back")) ||
              (state.data.hairLength.Waist && t("maps.Waist")) ||
              "Unknown"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              color: "gray",
            }}
          >
            {t("deceased.skin_color")}:
          </span>
          <div style={{ color: "gray" }}>
            {/* {state.data.skinColor || "Unknown"} */}
            {(state.data.skinColor.Pale && t("maps.Pale")) ||
              (state.data.skinColor.LightBrown && t("maps.Light_Brown")) ||
              (state.data.skinColor.Tanned && t("maps.Tanned")) ||
              (state.data.skinColor.DarkBrown && t("maps.Dark_Brown")) ||
              (state.data.skinColor.VeryDarkBrown &&
                t("maps.Very_Dark_Brown")) ||
              "Unknown"}
          </div>
        </div>
        <div style={separator} />
        <Typography
          style={{ color: "red", fontWeight: "bold", letterSpacing: "0.5px" }}
        >
          {t("deceased.description")}
        </Typography>
        <div style={{ color: "gray", marginTop: "2%" }}>
          {state.data.description || "Unknown"}
        </div>
        <div style={separator} />
        <Typography
          style={{ color: "red", fontWeight: "bold", letterSpacing: "0.5px" }}
        >
          {t("deceased.contact_info")}
        </Typography>
      </div>
    </div>
  );
};

export default DeceasedDetails;
