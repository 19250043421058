import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// import mui
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  Container,
  Chip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  FormControl,
  Select,
  AppBar,
  Toolbar,
} from "@mui/material";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import "./DeceasedMap.css";
import { useSelector } from "react-redux";
import { GetUserCoordinates } from "utils/locationService";
import { useDispatch } from "react-redux";
import PostAPI from "utils/POSTAPI";
import {
  LOCATION_NAME,
  LOCATION_COORDINATES,
  FROM_DATE,
  TO_DATE,
  GENDER_FILTER,
  AGERANGE_FILTER,
  SKINCOLOR_FILTER,
  HAIRCOLOR_FILTER,
  HAIRLENGTH_FILTER,
} from "Redux/type";
import { Fab, Grid, MenuItem, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ChipButton from "pages/Map/components/Map/components/ChipButton";
import { DECEASEDDETAILS, USER } from "navigation/routeConfig";
import moment from "moment/moment";
import GETAPI from "utils/GETAPI";
import EmptyProfileRequests from "pages/Profile/components/EmptyProfileRequests";
import LocationBar from "pages/Map/components/LocationBar/LocationBar";
import MapCon from "./components/Map/Map.js";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useFilterDialogStore } from "../../helper_methods/useFilterDialogStore";
import { useBetween } from "use-between";

const DeceasedMap = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // for translation
  const [imgSrc, setImgSrc] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { locationName, locationCoordinates } = useSelector(
    (state) => state.currentLocationCoordinates
  );
  const {
    genderFilter,
    ageRangeFilter,
    hairColorFilter,
    hairLengthFilter,
    skinColorFilter,
    toDate,
    fromDate,
  } = useSelector((state) => state.requestTypeReducer);
  const dispatch = useDispatch();
  // const { openFilterDialog } = useBetween(useFilterDialogStore);
  const [filename, setFilename] = useState("");
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [gender, setGender] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [dob, setDob] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [hairLength, setHairLength] = useState("");
  const [skinColor, setSkinColor] = useState("");
  const [dateBodyFound, setDateBodyFound] = useState("");
  const [description, setDescription] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [deceasedData, setDeceasedData] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [todayDate, setTodayDate] = useState(new Date());
  const [male, setmale] = useState(false);
  const [female, setfemale] = useState(false);
  const [Infant, setInfant] = useState(false);
  const [twelve, settwelve] = useState(false);
  const [twelve_seventeen, settwelve_seventeen] = useState(false);
  const [eighteen_twentyfour, seteighteen_twentyfour] = useState(false);
  const [twentyfive_thirtyfour, settwentyfive_thirtyfour] = useState(false);
  const [thirtyfive_fortyfour, setthirtyfive_fortyfour] = useState(false);
  const [fortyfive_fiftyfour, setfortyfive_fiftyfour] = useState(false);
  const [fiftyfive_sixtyfour, setfiftyfive_sixtyfour] = useState(false);
  const [sixtyfive_seventyfour, setsixtyfive_seventyfour] = useState(false);
  const [seventyfive, setseventyfive] = useState(false);
  const [White, setWhite] = useState(false);
  const [Grey, setGrey] = useState(false);
  const [Blonde, setBlonde] = useState(false);
  const [Brunette, setBrunette] = useState(false);
  const [Red, setRed] = useState(false);
  const [Black, setBlack] = useState(false);
  const [Others, setOthers] = useState(false);
  const [Bald, setBald] = useState(false);
  const [BuzzCut, setBuzzCut] = useState(false);
  const [Ear, setEar] = useState(false);
  const [Neck, setNeck] = useState(false);
  const [Collar, setCollar] = useState(false);
  const [Shoulder, setShoulder] = useState(false);
  const [ArmPit, setArmPit] = useState(false);
  const [MidBack, setMidBack] = useState(false);
  const [Waist, setWaist] = useState(false);
  const [Pale, setPale] = useState(false);
  const [LightBrown, setLightBrown] = useState(false);
  const [Tanned, setTanned] = useState(false);
  const [DarkBrown, setDarkBrown] = useState(false);
  const [VeryDarkBrown, setVeryDarkBrown] = useState(false);

  const canvas = useRef();
  let ctx = null;

  // GetUserCoordinates(setLat, setLong);

  // initialize the canvas context
  // useEffect(() => {
  //   if (doneDetect) {
  //     // dynamically assign the width and height to canvas
  //     const canvasEle = canvas.current;
  //     canvasEle.width = canvasEle.parentElement.clientWidth;
  //     canvasEle.height = canvasEle.parentElement.clientHeight;
  //     // get context of the canvas
  //     ctx = canvasEle.getContext("2d");
  //     let bboxes = predictions.bboxes;
  //     for (let i = 0; i < bboxes.length; i++) {
  //       let box = bboxes[i];
  //       let displayName = predictions.displayNames[i];
  //       let confidence = predictions.confidences[i];
  //       let x0, x1, y0, y1;
  //       x0 = box[0];
  //       x1 = box[1];
  //       y0 = box[2];
  //       y1 = box[3];
  //       let r1Info = {
  //         x: canvasEle.width * x0,
  //         y: canvasEle.height * y0,
  //         w: canvasEle.width * x1 - canvasEle.width * x0,
  //         h: canvasEle.height * y1 - canvasEle.height * y0,
  //         confidence: confidence,
  //         displayName: displayName,
  //       };
  //       const r1Style = { borderColor: "red", borderWidth: 2 };
  //       drawRect(r1Info, r1Style);
  //     }
  //   }
  // }, [doneDetect]);

  // draw rectangle
  const drawRect = (info, style = {}) => {
    const { x, y, w, h, confidence, displayName } = info;
    const { borderColor = "black", borderWidth = 1 } = style;

    ctx.beginPath();
    ctx.font = "18px Arial";
    ctx.fillStyle = "red";
    ctx.fillText(`${displayName}: ${confidence.toFixed(3) * 100}%`, x, y - 5);
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderWidth;
    ctx.rect(x, y, w, h);
    ctx.stroke();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImage = async ({ target }) => {
    let image = URL.createObjectURL(target.files[0]);
    // const base64 = await convertBase64(target.files[0]);
    setFilename(target.files[0].name);
    setImagePreview(image);
    setImgSrc(target.files[0]);
  };

  const ContinueValid = (e) => {
    e.preventDefault();
  };

  // const handleDetect = () => {
  //   setIsLoading(true);
  //   // console.log("detecting")
  //   (async () => {
  //     try {
  //       console.log("sending api");
  //       // console.log(imgSrc)
  //       let res = await getUXOBoundingBoxes(imgSrc.split(",")[1]);
  //       // console.log(res)
  //       let predictions = JSON.parse(res.data[0]).predictions[0];
  //       // console.log(JSON.stringify(predictions))
  //       setPredictions(predictions);
  //       setIsLoading(false);
  //       setDoneDetect(true);
  //     } catch (error) {
  //       console.log(error);
  //       setIsLoading(false);
  //       setOpen(true);
  //     }
  //   })();
  // };

  const rootStyle = {
    width: "100%",
    backgroundColor: "white",
  };

  const smallTextStyle = {
    fontSize: 12,
    color: "gray",
  };
  const iconTextStyle = {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    marginTop: "5px",
    marginBottom: "5px",
  };

  const truncateDescription = (description) => {
    if (description.length > 40) {
      return description.slice(0, 40) + "...";
    } else {
      return description;
    }
  };

  const DeceasedPost = () => {
    setIsDisabled(true);
    let formdata = new FormData();
    formdata.append("firstName", firstName);
    formdata.append("middleName", middleName);
    formdata.append("lastName", lastName);
    formdata.append("nickName", nickName);
    formdata.append("dateOfBirth", dob);
    formdata.append("dateWhenBodyIsFound", moment(dateBodyFound).format());
    formdata.append("locationFound", locationName);
    formdata.append("description", description);
    formdata.append("decLatitude", locationCoordinates.lat);
    formdata.append("decLongitude", locationCoordinates.lng);
    formdata.append("photo", imgSrc);
    formdata.append("ethnicity", ethnicity);
    formdata.append("male", male);
    formdata.append("female", female);
    formdata.append("Infant", Infant);
    formdata.append("twelve", twelve);
    formdata.append("twelve_seventeen", twelve_seventeen);
    formdata.append("eighteen_twentyfour", eighteen_twentyfour);
    formdata.append("twentyfive_thirtyfour", twentyfive_thirtyfour);
    formdata.append("thirtyfive_fortyfour", thirtyfive_fortyfour);
    formdata.append("fortyfive_fiftyfour", fortyfive_fiftyfour);
    formdata.append("fiftyfive_sixtyfour", fiftyfive_sixtyfour);
    formdata.append("sixtyfive_seventyfour", sixtyfive_seventyfour);
    formdata.append("seventyfive", seventyfive);
    formdata.append("White", White);
    formdata.append("Grey", Grey);
    formdata.append("Blonde", Blonde);
    formdata.append("Brunette", Brunette);
    formdata.append("Red", Red);
    formdata.append("Black", Black);
    formdata.append("Others", Others);
    formdata.append("Bald", Bald);
    formdata.append("BuzzCut", BuzzCut);
    formdata.append("Ear", Ear);
    formdata.append("Neck", Neck);
    formdata.append("Collar", Collar);
    formdata.append("Shoulder", Shoulder);
    formdata.append("ArmPit", ArmPit);
    formdata.append("MidBack", MidBack);
    formdata.append("Waist", Waist);
    formdata.append("Pale", Pale);
    formdata.append("LightBrown", LightBrown);
    formdata.append("Tanned", Tanned);
    formdata.append("DarkBrown", DarkBrown);
    formdata.append("VeryDarkBrown", VeryDarkBrown);
    formdata.append("userId", localStorage.getItem("_id"));
    PostAPI.AddDeceased(formdata, (response) => {
      if (!response.status) {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        setOpenDialog(false);
        alert("something went wrong");
        setIsDisabled(false);
      } else if (response.status) {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setImagePreview(null);
        setFilename("");
        setImgSrc(null);
        setOpenDialog(false);
        alert("Reported Deceased Successfully");
        window.location.reload();
        setIsDisabled(false);
      } else {
        dispatch({
          type: LOCATION_NAME,
          payload: "",
        });
        dispatch({
          type: LOCATION_COORDINATES,
          payload: { lat: 0, lng: 0 },
        });
        setFilename("");
        setImagePreview(null);
        setImgSrc(null);
        setOpenDialog(false);
        alert("something went wrong");
        setIsDisabled(false);
      }
    });
  };

  useEffect(() => {
    getDeceased();
  }, []);

  const getDeceased = () => {
    GETAPI.GetDeceasedList((response) => {
      if (!response.status) {
        setDeceasedData([]);
      } else if (response.status) {
        setDeceasedData(response.data);
      } else {
        setDeceasedData([]);
      }
    });
  };

  const FilterData = () => {
    let formdata = {
      gender: genderFilter,
      ageRange: ageRangeFilter,
      hairColor: hairColorFilter,
      skinColor: skinColorFilter,
      hairLength: hairLengthFilter,
      fromDate: fromDate,
      toDate: toDate,
      ethnicity: ethnicity,
    };

    PostAPI.FilterDeceased(formdata, (response) => {
      if (!response.status) {
        setDeceasedData([]);
        setOpenFilterDialog(false);
        alert("something went wrong");
      } else if (response.status) {
        setDeceasedData(response.data);
        setOpenFilterDialog(false);
      } else {
        setDeceasedData([]);
        setOpenFilterDialog(false);
        alert("something went wrong");
      }
    });
  };

  const handleReset = () => {
    dispatch({
      type: GENDER_FILTER,
      payload: [],
    });
    dispatch({
      type: AGERANGE_FILTER,
      payload: [],
    });
    dispatch({
      type: HAIRCOLOR_FILTER,
      payload: [],
    });
    dispatch({
      type: HAIRLENGTH_FILTER,
      payload: [],
    });
    dispatch({
      type: SKINCOLOR_FILTER,
      payload: [],
    });
    dispatch({
      type: TO_DATE,
      payload: "",
    });
    dispatch({
      type: FROM_DATE,
      payload: "",
    });
  };

  const ageRangeMap = {
    Infant: t("maps.Infant"),
    12: t("maps.Under_12_years_old"),
    "12-17": t("maps.12-17_years_old"),
    "18-24": t("maps.18-24_years_old"),
    "25-34": t("maps.25-34_years_old"),
    "35-44": t("maps.35-44_years_old"),
    "45-54": t("maps.45-54_years_old"),
    "55-64": t("maps.55-64_years_old"),
    "65-74": t("maps.65-74_years_old"),
    75: t("maps.75_years_or_older"),
  };

  const skinColorMap = {
    Pale: t("maps.Pale"),
    LightBrown: t("maps.Light_Brown"),
    Tanned: t("maps.Tanned"),
    DarkBrown: t("maps.Dark_Brown"),
    VeryDarkBrown: t("maps.Very_Dark_Brown"),
  };

  const hairColorMap = {
    White: t("maps.White"),
    Grey: t("maps.Grey"),
    Blonde: t("maps.Blonde"),
    Brunette: t("maps.Brunette"),
    Red: t("maps.Red"),
    Black: t("maps.Black"),
    Others: t("maps.Others"),
  };

  const hairLengthMap = {
    Bald: t("maps.Bald"),
    BuzzCut: t("maps.Buzz_Cut"),
    Ear: t("maps.Ear"),
    Neck: t("maps.Neck"),
    Collar: t("maps.Collar"),
    Shoulder: t("maps.Shoulder"),
    ArmPit: t("maps.Arm_Pit"),
    MidBack: t("maps.Mid_back"),
    Waist: t("maps.Waist"),
  };
  const genderMap = {
    male: t("maps.male"),
    female: t("maps.female"),
  };

  const ageRangeChip = (deceased) =>
    Object.keys(ageRangeMap).map((key) => {
      if (deceased.ageRange[key]) {
        return <ChipButton name={t(ageRangeMap[key])} />;
      }
      return null;
    });
  const genderChip = (deceased) =>
    Object.keys(genderMap).map((key) => {
      if (deceased.gender[key]) {
        return <ChipButton name={t(genderMap[key])} />;
      }
      return null;
    });
  const skinColorChip = (deceased) =>
    Object.keys(skinColorMap).map((key) => {
      if (deceased.skinColor[key]) {
        return <ChipButton name={t(skinColorMap[key])} />;
      }
      return null;
    });
  const hairColorChip = (deceased) =>
    Object.keys(hairColorMap).map((key) => {
      if (deceased.hairColor[key]) {
        return <ChipButton name={t(hairColorMap[key])} />;
      }
      return null;
    });
  const hairLengthChip = (deceased) =>
    Object.keys(hairLengthMap).map((key) => {
      if (deceased.hairLength[key]) {
        return <ChipButton name={t(hairLengthMap[key])} />;
      }
      return null;
    });

  return (
    <div style={{ paddingBottom: "80px" }}>
      <Box
        sx={{
          background: "#D22108",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          pb: 2,
          zIndex: 4,
        }}
      >
        <Container maxWidth="lg" sx={{ zIndex: "4", width: "100%" }}>
          <div className="mt-3">
            <Typography
              variant="h5"
              color="#FFFFFF"
              component="div"
              sx={{ fontWeight: "bold", m: "1rem 0 0 5px" }}
            >
              {t("maps.deceased")}
            </Typography>
          </div>
        </Container>
      </Box>
      <AppBar
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
        }}
        position="static"
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography style={{ color: "black" }}>
            {t("deceased.deceased_information")}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: "#D22108",
              color: "#D22108",
              "&:hover": {
                backgroundColor: "#FFF3F3",
                borderColor: "#D22108",
              },
              marginRight: "20px",
            }}
            startIcon={<FilterAltIcon />}
            aria-label="Deceased posts filter"
            onClick={() => setOpenFilterDialog(true)}
          >
            {t("deceased.filter")}
          </Button>
        </Toolbar>
      </AppBar>
      <Box className="banner-info">
        {t("deceased.deceased_header")}
        <p style={{ marginTop: "2%" }}>{t("deceased.deceased_header_2")}</p>
      </Box>
      <List style={rootStyle}>
        {deceasedData.length == 0 && <EmptyProfileRequests />}
        {deceasedData.map((deceased, index) => (
          <>
            <div style={{ marginTop: "2%" }}>
              {ageRangeChip(deceased)}
              {skinColorChip(deceased)}
              {hairLengthChip(deceased)}
              {hairColorChip(deceased)}
              {genderChip(deceased)}
              {deceased.ethnicity !== null && deceased.ethnicity !== "" && (
                <ChipButton name={deceased.ethnicity} />
              )}
            </div>
            <ListItem
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/${USER}/${localStorage.getItem("_id")}/${DECEASEDDETAILS}`,
                  { state: { data: deceased } }
                )
              }
              key={index}
            >
              <ListItemText
                primary={truncateDescription(deceased.description)}
                secondary={
                  <React.Fragment>
                    <span style={smallTextStyle}>
                      {moment(deceased.createdAt).fromNow()}
                    </span>
                    <br />
                    <div style={iconTextStyle}>
                      <AccountBoxOutlinedIcon />
                      {deceased.firstName == "" &&
                      deceased.middleName == "" &&
                      deceased.lastName == ""
                        ? "Unknown"
                        : deceased.firstName +
                          " " +
                          deceased.middleName +
                          " " +
                          deceased.lastName}{" "}
                      [{t("deceased.name")}]
                    </div>
                    <div style={iconTextStyle}>
                      <AccessTimeIcon />
                      {moment(deceased.dateWhenBodyIsFound).format(
                        "YYYY-MM-DD"
                      )}{" "}
                      [{t("deceased.date_found")}]
                    </div>
                    <div style={iconTextStyle}>
                      <LocationOnOutlinedIcon />
                      {deceased.locationFound.split(", ").slice(-4, -3)[0] +
                        " " +
                        deceased.locationFound.split(", ").slice(-1)[0]}{" "}
                      [{t("deceased.location")}]
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
            <div
              style={{
                backgroundColor: "gray",
                width: "100%",
                height: "0.5px",
              }}
            />
          </>
        ))}
      </List>

      <Dialog open={openDialog}>
        <Container max_width="lg">
          
            <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.name")}
              </Typography>

              <TextField
                name="first_name"
                fullWidth
                id="title-input"
                label={t("deceased.first_name")}
                size="small"
                sx={{ marginBottom: 2 }}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />

              <TextField
                name="middle_name"
                fullWidth
                id="middle_name"
                label={t("deceased.middle_name")}
                size="small"
                sx={{ marginBottom: 2 }}
                onChange={(e) => setMiddleName(e.target.value)}
                value={middleName}
              />

              <TextField
                name="last_name"
                fullWidth
                id="last_name"
                label={t("deceased.last_name")}
                size="small"
                sx={{ marginBottom: 2 }}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />

              <TextField
                name="nickname"
                fullWidth
                id="nickname"
                label={t("deceased.nick_name")}
                size="small"
                sx={{ marginBottom: 2 }}
                onChange={(e) => setNickName(e.target.value)}
                value={nickName}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.gender")} *
              </Typography>

              <TextField
                required
                label={t("deceased.gender")}
                select
                id="gender"
                name="gender"
                sx={{ marginBottom: 2, minWidth: 100 }}
                value={gender}
                size="small"
                fullWidth
                onChange={(e) => setGender(e.target.value)}
              >
                {[t("maps.male"), t("maps.female")].map((item, index) => (
                  <MenuItem
                    key={item}
                    onClick={() => {
                      setmale(index === 0);
                      setfemale(index === 1);
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.age_range")}
              </Typography>
              <TextField
                label={t("deceased.age_range")}
                select
                id="age_range"
                name="age_range"
                sx={{ marginBottom: 2, minWidth: 150 }}
                value={ageRange}
                size="small"
                fullWidth
                onChange={(e) => setAgeRange(e.target.value)}
              >
                {[
                  t("maps.Infant"),
                  t("maps.Under_12_years_old"),
                  t("maps.12-17_years_old"),
                  t("maps.18-24_years_old"),
                  t("maps.25-34_years_old"),
                  t("maps.35-44_years_old"),
                  t("maps.45-54_years_old"),
                  t("maps.55-64_years_old"),
                  t("maps.65-74_years_old"),
                  t("maps.75_years_or_older"),
                ].map((item, index) => (
                  <MenuItem
                    key={item}
                    onClick={() => {
                      setInfant(index === 0);
                      settwelve(index === 1);
                      settwelve_seventeen(index === 2);
                      seteighteen_twentyfour(index === 3);
                      settwentyfive_thirtyfour(index === 4);
                      setthirtyfive_fortyfour(index === 5);
                      setfortyfive_fiftyfour(index === 6);
                      setfiftyfive_sixtyfour(index === 7);
                      setsixtyfive_seventyfour(index === 8);
                      setseventyfive(index === 9);
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"Infant"}>Infant</MenuItem>
                <MenuItem value={"Under 12 years old"}>
                  Under 12 years old
                </MenuItem>
                <MenuItem value={"12-17 years old"}>12-17 years old</MenuItem>
                <MenuItem value={"18-24 years old"}>18-24 years old</MenuItem>
                <MenuItem value={"25-34 years old"}>25-34 years old</MenuItem>
                <MenuItem value={"35-44 years old"}>35-44 years old</MenuItem>
                <MenuItem value={"45-54 years old"}>45-54 years old</MenuItem>
                <MenuItem value={"55-64 years old"}>55-64 years old</MenuItem>
                <MenuItem value={"65-74 years old"}>65-74 years old</MenuItem>
                <MenuItem value={"75 years or older"}>
                  75 years or older
                </MenuItem> */}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.date_of_birth")}
              </Typography>
              <TextField
                fullWidth
                id="DOB"
                sx={{ marginBottom: 2 }}
                size="small"
                InputLabelProps={{ shrink: true }}
                name="date_of_birth"
                onChange={(e) => {
                  e.target.value > moment().format("YYYY-MM-DD")
                    ? setDob("")
                    : setDob(e.target.value);
                }}
                value={dob}
                type="date"
                inputProps={{ max: moment().format("YYYY-MM-DD") }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.ethnicity")}
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="ethnicity"
                sx={{ marginBottom: 2 }}
                value={ethnicity}
                onChange={(e) => setEthnicity(e.target.value)}
                label={t("deceased.ethnicity")}
                id="ethnicity"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.features")}
              </Typography>

              <TextField
                label={t("deceased.hair_color")}
                select
                id="hair_color"
                name="hair_color"
                sx={{ marginBottom: 2, minWidth: 150 }}
                value={hairColor}
                size="small"
                fullWidth
                onChange={(e) => setHairColor(e.target.value)}
              >
                {[
                  t("maps.White"),
                  t("maps.Grey"),
                  t("maps.Blonde"),
                  t("maps.Brunette"),
                  t("maps.Red"),
                  t("maps.Black"),
                  t("maps.Others"),
                ].map((item, index) => (
                  <MenuItem
                    key={item}
                    onClick={() => {
                      setWhite(index === 0);
                      setGrey(index === 1);
                      setBlonde(index === 2);
                      setBrunette(index === 3);
                      setRed(index === 4);
                      setBlack(index === 5);
                      setOthers(index === 6);
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"White"}>White</MenuItem>
                <MenuItem value={"Grey"}>Grey</MenuItem>
                <MenuItem value={"Blonde"}>Blonde</MenuItem>
                <MenuItem value={"Brunette"}>Brunette</MenuItem>
                <MenuItem value={"Red"}>Red</MenuItem>
                <MenuItem value={"Black"}>Black</MenuItem>
                <MenuItem value={"Others"}>Others</MenuItem> */}
              </TextField>

              <TextField
                label={t("deceased.hair_length")}
                select
                id="hair_length"
                name="hair_length"
                sx={{ marginBottom: 2, minWidth: 150 }}
                value={hairLength}
                size="small"
                fullWidth
                onChange={(e) => {
                  setHairLength(e.target.value);
                }}
              >
                {[
                  t("maps.Bald"),
                  t("maps.Buzz_Cut"),
                  t("maps.Ear"),
                  t("maps.Neck"),
                  t("maps.Collar"),
                  t("maps.Shoulder"),
                  t("maps.Arm_Pit"),
                  t("maps.Mid_back"),
                  t("maps.Waist"),
                ].map((item, index) => (
                  <MenuItem
                    key={item}
                    onClick={() => {
                      setBald(index === 0);
                      setBuzzCut(index === 1);
                      setEar(index === 2);
                      setCollar(index === 3);
                      setShoulder(index === 4);
                      setArmPit(index === 5);
                      setWaist(index === 6);
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"Bald"}>Bald</MenuItem>
                <MenuItem value={"Buzz Cut"}>Buzz Cut</MenuItem>
                <MenuItem value={"Ear"}>Ear</MenuItem>
                <MenuItem value={"Neck"}>Neck</MenuItem>
                <MenuItem value={"Collar"}>Collar</MenuItem>
                <MenuItem value={"Shoulder"}>Shoulder</MenuItem>
                <MenuItem value={"Arm Pit"}>Arm Pit</MenuItem>
                <MenuItem value={"Mid-back"}>Mid-back</MenuItem>
                <MenuItem value={"Waist"}>Waist</MenuItem> */}
              </TextField>

              <TextField
                label={t("deceased.skin_color")}
                select
                id="skin_color"
                name="skin_color"
                sx={{ marginBottom: 3, minWidth: 150 }}
                value={skinColor}
                size="small"
                fullWidth
                onChange={(e) => setSkinColor(e.target.value)}
              >
                {[
                  t("maps.Pale"),
                  t("maps.Light_Brown"),
                  t("maps.Tanned"),
                  t("maps.Dark_Brown"),
                  t("maps.Very_Dark_Brown"),
                ].map((item, index) => (
                  <MenuItem
                    key={item}
                    onClick={() => {
                      setPale(index === 0);
                      setLightBrown(index === 1);
                      setTanned(index === 2);
                      setDarkBrown(index === 3);
                      setVeryDarkBrown(index === 4);
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"Pale"}>Pale</MenuItem>
                <MenuItem value={"Light Brown"}>Light Brown</MenuItem>
                <MenuItem value={"Tanned"}>Tanned</MenuItem>
                <MenuItem value={"Dark Brown"}>Dark Brown</MenuItem>
                <MenuItem value={"Very Dark Brown"}>Very Dark Brown</MenuItem> */}
              </TextField>
            </Grid>

            {/* <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("maps.location")}
              </Typography>
              <TextField
                size="small"
                fullWidth
                required
                error={locationName && !useGPS}
                name={t("maps.location")}
                value={locationName}
                onChange={handleInput}
                disabled={useGPS}
                placeholder={t("maps.location")}
                label={t("maps.location")}
                id={t("maps.location")}
              />
              {status === "OK" && (
                <p style={{ padding: "20px" }}>
                  {useGPS ? "" : renderSuggestions()}
                </p>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox"
                      name="useGPS"
                      checked={useGPS}
                      onChange={handleGPSChange}
                      color="primary"
                    />
                  }
                  label={t("maps.current_location")}
                  fontSize="12"
                />
              </Grid>
            </Grid> */}

            {/* <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginTop={2}
                marginBottom={1}
              >
                {t("maps.location")}
              </Typography>
              <TextField
                size="small"
                fullWidth
                required
                error={locationName && !useGPS}
                name={t("maps.location")}
                value={locationName}
                onChange={handleInput}
                disabled={useGPS}
                placeholder={t("maps.location")}
                label={t("maps.location")}
                id={t("maps.location")}
              />
              {status === "OK" && (
                <p style={{ padding: "20px" }}>
                  {useGPS ? "" : renderSuggestions()}
                </p>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox"
                      name="useGPS"
                      checked={useGPS}
                      onChange={handleGPSChange}
                      color="primary"
                    />
                  }
                  label={t("maps.current_location")}
                  fontSize="12"
                />
              </Grid>
            </Grid> */}

            <LocationBar />

            <Grid item xs={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.date_body_found")} *
              </Typography>
              <TextField
                required
                fullWidth
                id="date_found"
                name="date_found"
                size="small"
                sx={{ marginBottom: 2 }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  e.target.value > moment().format("YYYY-MM-DD")
                    ? setDateBodyFound("")
                    : setDateBodyFound(e.target.value);
                }}
                value={dateBodyFound}
                type="date"
                inputProps={{ max: moment().format("YYYY-MM-DD") }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginTop={2}
              >
                {t("deceased.description")} *
              </Typography>
              <TextField
                required
                fullWidth
                multiline
                rows={4}
                name="description"
                size="small"
                placeholder={t("deceased.description_placeholder")}
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Grid>
            <>
              <div>
                <Typography
                  variant="subtitle1"
                  color="#D22108"
                  component="div"
                  sx={{ fontWeight: "bold", m: "1rem 0 0 5px" }}
                >
                  {filename.length !== 0
                    ? t("maps.selected_file") + filename
                    : "Take a photo or select one from gallery that is less then 1 MB *"}
                </Typography>
                {imagePreview ? (
                  <div>
                    <div style={{ position: "relative", margin: "10px 0" }}>
                      <img alt={filename} src={imagePreview} width={"100%"} />
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          zIndex: "3",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <canvas ref={canvas}></canvas>
                      </div>
                    </div>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<PhotoCamera />}
                      >
                        {t("maps.reupload")}
                        <input
                          hidden
                          accept="image/jpeg, image/png, image/webp"
                          type="file"
                          onChange={handleImage}
                        />
                      </Button>
                    </Stack>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", padding: "10px 0" }}>
                    <Button
                      id="upload-button"
                      variant="contained"
                      component="label"
                      startIcon={<PhotoCamera />}
                    >
                      {t("maps.select")}
                      <input
                        name="upload"
                        id="upload"
                        hidden
                        accept="image/jpeg, image/png, image/webp"
                        type="file"
                        onChange={handleImage}
                      />
                    </Button>
                  </div>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  onClick={() => DeceasedPost()}
                  disabled={
                    imagePreview !== null &&
                    locationName !== "" &&
                    dateBodyFound !== "" &&
                    gender !== "" &&
                    description !== "" &&
                    isDisabled == false
                      ? false
                      : true
                  }
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {t("maps.continue")}
                </Button>
                <Button
                  onClick={() => setOpenDialog(false)}
                  fullWidth
                  variant="outlined"
                  sx={{
                    mb: 2,
                    color: "#D22108",
                    borderColor: "#D22108",
                    "&:hover": {
                      color: "#D22108",
                      borderColor: "#D22108",
                      backgroundColor: "white",
                    },
                    minWidth: "80px",
                    borderRadius: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {t("maps.cancel")}
                </Button>
              </div>
            </>
         
        </Container>
      </Dialog>
      <Fab aria-label="add" className="createBtn">
        <AddIcon
          className="add-icon"
          style={{ fill: "#FFFFFF" }}
          onClick={() => setOpenDialog(true)}
        />
      </Fab>
      <MapCon />
      <Dialog open={openFilterDialog}>
        <Container style={{ minWidth: "320px" }} max_width="lg">
          <form onSubmit={ContinueValid}>
            <Grid item xs={12}>
              <div
                style={{
                  marginTop: "8%",
                  marginBottom: "3%",
                }}
              >
                <Typography
                  color="#D22108"
                  fontWeight="500"
                  component="h5"
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t("deceased.from")}
                </Typography>
                <TextField
                  fullWidth
                  id="DOB"
                  size="small"
                  name="date_of_birth"
                  onChange={(e) =>
                    e.target.value > moment().format("YYYY-MM-DD")
                      ? dispatch({
                          type: FROM_DATE,
                          payload: "",
                        })
                      : dispatch({
                          type: FROM_DATE,
                          payload: e.target.value,
                        })
                  }
                  value={fromDate}
                  type="date"
                  inputProps={{ max: moment().format("YYYY-MM-DD") }}
                />
                

                <Typography
                  color="#D22108"
                  fontWeight="500"
                  component="h5"
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                >
                  {t("deceased.to")}
                </Typography>
                
                <TextField
                  fullWidth
                  id="DOB"
                  size="small"
                  name="date_of_birth"
                  onChange={(e) =>
                    e.target.value > moment().format("YYYY-MM-DD")
                      ? dispatch({
                          type: TO_DATE,
                          payload: "",
                        })
                      : dispatch({
                          type: TO_DATE,
                          payload: e.target.value,
                        })
                  }
                  value={toDate}
                  type="date"
                  inputProps={{ max: moment().format("YYYY-MM-DD") }}
                />
              </div>
              <FormControl required style={{ width: "100%" }}>
                <Typography
                  color="#D22108"
                  fontWeight="500"
                  component="h5"
                  variant="subtitle1"
                  align="left"
                  gutterBottom
                  marginBottom={1}
                >
                  {t("deceased.gender")}
                </Typography>
                <Select
                  id="genderFilter"
                  name="genderFilter"
                  value={genderFilter}
                  multiple
                  onChange={(e) =>
                    dispatch({
                      type: GENDER_FILTER,
                      payload: e.target.value,
                    })
                  }
                  enabled
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.ethnicity")}
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="ethnicity"
                sx={{ marginBottom: 2 }}
                value={ethnicity}
                onChange={(e) => setEthnicity(e.target.value)}
                label={t("deceased.ethnicity")}
                id="ethnicity"
              />
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.age_range")}
              </Typography>
              <FormControl required style={{ width: "100%" }}>
                <Select
                  id="ageRangeFilter"
                  name="ageRangeFilter"
                  value={ageRangeFilter}
                  multiple
                  onChange={(e) =>
                    dispatch({
                      type: AGERANGE_FILTER,
                      payload: e.target.value,
                    })
                  }
                  enabled
                >
                  <MenuItem value={"Infant"}>Infant</MenuItem>
                  <MenuItem value={"Under 12 years old"}>
                    Under 12 years old
                  </MenuItem>
                  <MenuItem value={"12-17 years old"}>12-17 years old</MenuItem>
                  <MenuItem value={"18-24 years old"}>18-24 years old</MenuItem>
                  <MenuItem value={"25-34 years old"}>25-34 years old</MenuItem>
                  <MenuItem value={"35-44 years old"}>35-44 years old</MenuItem>
                  <MenuItem value={"45-54 years old"}>45-54 years old</MenuItem>
                  <MenuItem value={"55-64 years old"}>55-64 years old</MenuItem>
                  <MenuItem value={"65-74 years old"}>65-74 years old</MenuItem>
                  <MenuItem value={"75 years or older"}>
                    75 years or older
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.hair_color")}
              </Typography>
              <FormControl required style={{ width: "100%" }}>
                <Select
                  id="hairColorFilter"
                  name="hairColorFilter"
                  value={hairColorFilter}
                  multiple
                  onChange={(e) =>
                    dispatch({
                      type: HAIRCOLOR_FILTER,
                      payload: e.target.value,
                    })
                  }
                  enabled
                >
                  <MenuItem value={"White"}>White</MenuItem>
                  <MenuItem value={"Grey"}>Grey</MenuItem>
                  <MenuItem value={"Blonde"}>Blonde</MenuItem>
                  <MenuItem value={"Brunette"}>Brunette</MenuItem>
                  <MenuItem value={"Red"}>Red</MenuItem>
                  <MenuItem value={"Black"}>Black</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
              </FormControl>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.hair_length")}
              </Typography>
              <FormControl required style={{ width: "100%" }}>
                <Select
                  id="hairLengthFilter"
                  name="hairLengthFilter"
                  value={hairLengthFilter}
                  multiple
                  onChange={(e) =>
                    dispatch({
                      type: HAIRLENGTH_FILTER,
                      payload: e.target.value,
                    })
                  }
                  enabled
                >
                  <MenuItem value={"Bald"}>Bald</MenuItem>
                  <MenuItem value={"Buzz Cut"}>Buzz Cut</MenuItem>
                  <MenuItem value={"Ear"}>Ear</MenuItem>
                  <MenuItem value={"Neck"}>Neck</MenuItem>
                  <MenuItem value={"Collar"}>Collar</MenuItem>
                  <MenuItem value={"Shoulder"}>Shoulder</MenuItem>
                  <MenuItem value={"Arm Pit"}>Arm Pit</MenuItem>
                  <MenuItem value={"Mid-back"}>Mid-back</MenuItem>
                  <MenuItem value={"Waist"}>Waist</MenuItem>
                </Select>
              </FormControl>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="left"
                gutterBottom
                marginBottom={1}
              >
                {t("deceased.skin_color")}
              </Typography>
              <FormControl required style={{ width: "100%" }}>
                <Select
                  id="skinColorFilter"
                  name="skinColorFilter"
                  value={skinColorFilter}
                  multiple
                  onChange={(e) =>
                    dispatch({
                      type: SKINCOLOR_FILTER,
                      payload: e.target.value,
                    })
                  }
                  enabled
                >
                  <MenuItem value={"Pale"}>Pale</MenuItem>
                  <MenuItem value={"Light Brown"}>Light Brown</MenuItem>
                  <MenuItem value={"Tanned"}>Tanned</MenuItem>
                  <MenuItem value={"Dark Brown"}>Dark Brown</MenuItem>
                  <MenuItem value={"Very Dark Brown"}>Very Dark Brown</MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "5%",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ fontSize: 18 }}
                  onClick={() => FilterData()}
                >
                  {t("deceased.apply")}
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ fontSize: 18, backgroundColor: "gray" }}
                  onClick={handleReset}
                >
                  {t("deceased.reset")}
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "5%",
                  marginBottom: "5%",
                }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ fontSize: 18 }}
                  onClick={() => setOpenFilterDialog(false)}
                >
                  {t("details.cancelBtn")}
                </Button>
              </div>
            </Grid>
          </form>
        </Container>
      </Dialog>
      {/* <Dialog open={openFilterDialog}>
        <Container max_width="lg">
          <form>
            <Grid spacing={12}>
              <FormControl required style={{ width: "100%" }}>
                <Select
                  id="genderFilter"
                  name="genderFilter"
                  value={genderFilter}
                  multiple
                  onChange={(e) => setGenderFilter(e.target.value)}
                  enabled
                >
                  <MenuItem key="Male" value="Male">
                    Male
                  </MenuItem>
                  <MenuItem key="Female" value="Female">
                    Female
                  </MenuItem>
             
                </Select>
              </FormControl>
            </Grid>
          </form>
        </Container>
      </Dialog> */}
    </div>
  );
};

export default DeceasedMap;
