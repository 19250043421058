import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import PostAPI from "utils/POSTAPI";
import { useDispatch, useSelector } from "react-redux";
import { FEED_DATA, SEARCH_DATA } from "Redux/type";
import GETAPI from "utils/GETAPI";

export default function SearchBar(props) {
  const { t } = useTranslation(); // for translation
  // PROXIMITY STUFF: SAME AS IN REQUESTCARD! (to refactor) ========================================
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [km, setKm] = useState([]);
  const [search, setSearch] = useState("");
  const [categoryTitle, setCategoryTitle] = useState([]);
  const { searchData, feedData } = useSelector(
    (state) => state.requestTypeReducer
  );
  const [category, setCategory] = useState([]);

  const dispatch = useDispatch();

  const geolocationAPI = navigator.geolocation;

  // useEffect(() => {
  //   GetAllCategories();
  // }, []);

  // Initialize the state variables
// const [categoryTitle, setCategoryTitle] = useState([]);
const [categoryList, setCategoryList] = useState([]);
const [valid_categories, setValidCategories] = useState([]);

// Make the API call and store the response in the state
useEffect(() => {
  GETAPI.GetCategoryList((response) => {
    if (!response.status) {
      setCategoryTitle([]);
      setCategoryList([]);
      setValidCategories([]);
    } else if (response.status) {
      setCategoryTitle(response.data.data);
    } else {
      setCategoryTitle([]);
    }
  });
}, []);

// Filter and process the data after getting the response
useEffect(() => {
  const filteredCategories = categoryTitle
    .filter((element) => element.status === true && element.displayType == 'Home' )
    .map((element) => ({
      title: element.name,
      trans: element.name,
    }));

  const validCategories = categoryTitle
    .filter((element) => element.status === true && element.displayType == 'Home')
    .map((element) => element.name.toLowerCase());

  setCategoryList(filteredCategories);
  setValidCategories(validCategories);
}, [categoryTitle]);

// Now you have categoryList and valid_categories available for use in your component


  const GetUserCoordinates = () => {
    if (!geolocationAPI) {
      console.log("no location service :(");
      setLat(null);
      setLong(null);
    } else {
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setLat(coords.latitude);
          setLong(coords.longitude);
        },
        (error) => {
          // console.log("some geolocation error")
          // console.log(error)
          setLat(null);
          setLong(null);
        }
      );
    }
  };

  GetUserCoordinates();

  // PROXIMITY STUFF END ======================================================================

  // const GetAllCategories = () => {
  //   GETAPI.GetCategoryList(userid, (response) => {
  //     if (!response.status) {
  //       setCategory([]);
  //     } else if (response.status) {
  //       setCategory(response.data);
  //     } else {
  //       setCategory([]);
  //     }
  //   });
  // };
  // let categoryList = [];

  // GETAPI.GetCategoryList((response) => {
  //   setCategoryTitle([]);
  //   if (!response.status) {
  //     setCategoryTitle([]);
  //   } else if (response.status) {
  //     setCategoryTitle(response.data.data);
  //   } else {
  //     setCategoryTitle([]);
  //   }
  //   // console.log(response.data.data[0].name);
  // });

  //  categoryTitle.forEach(element => {
  //   if (element.status === true) {
  //     categoryList.push({
  //       title: element.name,
  //       trans: element.name, // Use the icon property from your data
  //     });
  //   }
  // });

  const categoriesOld = [
    //put types here
    // { title: "Food", trans: t("search.food") },
    // { title: "Transport", trans: t("search.transport") },
    // { title: "Medical", trans: t("search.medical") },
    // { title: "Accommodation", trans: t("search.accommodation") },
    { title: "50km" },
    { title: "10km" },
    { title: "< 1km" },
  ];
  
  const categories = [...categoryList, ...categoriesOld];

  let { userid } = useParams();
  const options = categories.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[<, 0-9]/.test(firstLetter)
        ? t("search.proximity")
        : t("search.suggest_cat"),
      ...option,
    };
  });

  // // valid_categories = ["medical", "transport", "accommodation", "food"];
  // const valid_categories = [];

  // categoryTitle.forEach(element => {
  //   if (element.status === true) {
  //     valid_categories.push(element.name);
  //   }
  // });

  const valid_proximities = {
    "50km": 50000,
    "10km": 10000,
    "< 1km": 1000,
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = searchData.filter(function (item) {
        const itemData = item.helpType
          ? item.helpType.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      // setFilteredDataSource(newData);
      dispatch({
        type: FEED_DATA,
        payload: newData,
      });
      setSearch(text);
    } else {
      // setFilteredDataSource(searchData);
      dispatch({
        type: FEED_DATA,
        payload: searchData,
      });
      setSearch(text);
    }
  };

  // useEffect(() => {
  //   if (data.length !== 0 || km.length !== 0) {
  //     let formdata = {
  //       currentLat: lat,
  //       currentLong: long,
  //       searchType: data,
  //       userId: userid,
  //       distancKMs: km.length > 0 ? km : [],
  //     };
  //     PostAPI.GetFilteredList(formdata, (response) => {
  //       if (!response.status) {
  //         props.setRequests(null);
  //       } else if (response.status) {
  //         console.log(response.data);
  //         props.setRequests(response.data);
  //       }
  //     });
  //   } else {
  //     let formdata = {
  //       userId: userid,
  //       currentLat: lat,
  //       currentLong: long,
  //     };
  //     PostAPI.GetFilteredList(formdata, (response) => {
  //       if (!response.status) {
  //         props.setRequests(null);
  //       } else if (response.status) {
  //         props.setRequests(response.data);
  //       } else {
  //         props.setRequests();
  //       }
  //     });
  //   }
  // }, [data, km]);

  const filter = (e, values) => {
    searchFilterFunction(e.target.value);
    console.log("filter",e.target.value);
    // if (!allData.includes(values)) {
    //   setAllData([...allData, values]);
    // }
    const searches = values.map((e) => e.title && e.title.toLowerCase());

    // Check search terms for category options
    const categories = searches.filter((e) => valid_categories.includes(e));
    // setData(categories);

    // Check search terms for proximity options, map to their integer equivalents, take MIN as upper limit.
    const proximities = searches
      .filter((e) => Object.keys(valid_proximities).includes(e))
      .map((e) => valid_proximities[e])
      .filter((e, index, self) => self.indexOf(e) === index);
    const proximity =
      proximities.length === 0 ? null : Math.min(...proximities);
    setKm(proximities);
    if (categories.length !== 0 || proximities.length !== 0) {
      let formdata = {
        currentLat: lat,
        currentLong: long,
        searchType: categories,
        userId: userid,
        distancKMs: proximities.length > 0 ? proximities : [],
      };
      PostAPI.GetFilteredList(formdata, (response) => {
        if (!response.status) {
          dispatch({
            type: SEARCH_DATA,
            payload: null,
          });
          dispatch({
            type: FEED_DATA,
            payload: null,
          });
        } else if (response.status) {
          dispatch({
            type: SEARCH_DATA,
            payload: response.data,
          });
          dispatch({
            type: FEED_DATA,
            payload: response.data,
          });
        }
      });
    } else {
      let formdata = {
        userId: userid,
        currentLat: lat,
        currentLong: long,
      };
      PostAPI.GetFilteredList(formdata, (response) => {
        if (!response.status) {
          dispatch({
            type: SEARCH_DATA,
            payload: null,
          });
          dispatch({
            type: FEED_DATA,
            payload: null,
          });
        } else if (response.status) {
          dispatch({
            type: SEARCH_DATA,
            payload: response.data,
          });
          dispatch({
            type: FEED_DATA,
            payload: response.data,
          });
        } else {
          dispatch({
            type: SEARCH_DATA,
            payload: null,
          });
          dispatch({
            type: FEED_DATA,
            payload: null,
          });
        }
      });
    }
  };

  return (
    <Box sx={{ flex: "auto" }}>
      <Autocomplete
        className="search-bar filter-button"
        multiple
        onChange={filter}
        id="tags-outlined"
        options={options.sort(
          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
        )}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.trans || option.title}
        defaultValue={[]}
        //freesolo allows user to search outside of tags
        freeSolo
        filterSelectedOptions
        renderInput={(params) => {
          return (
            <TextField
              className="search-option"
              {...params}
              label={t("search.search_txt")}
              placeholder={t("search.tip")}
            />
          );
        }}
        //To adjust dropdown list height
        ListboxProps={{
          style: {
            maxHeight: "600px",
          },
        }}
      />
    </Box>
  );
}
