
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Container from "@mui/material/Container";
import SettingsIcon from "@mui/icons-material/Settings";
import ProfileRequests from "./components/ProfileRequests";
import EmptyProfileRequests from "./components/EmptyProfileRequests";
import "./profile.css";
import { SETTINGS } from "navigation/routeConfig";
import PostAPI from "utils/POSTAPI.js";
import GETAPI from "utils/GETAPI";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  requestDescriptions,
  requestTypeStatus,
  requestTypes,
} from "Redux/actions/requestType.js";
import {
  PROFILE_DATA,
  REQUEST_DESCRIPTION,
  REQUEST_TYPE,
  REQUEST_TYPE_STATUS,
  TYPE,
} from "Redux/type";
import DangerReports from "./components/DangerReports";
import DeceasedReports from "./components/DeceasedReports";

export default function Profile() {
  const { t } = useTranslation(); // for translation
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { requestType, toggleType, requestDescription, type } = useSelector(
    (state) => state.requestTypeReducer
  );
  const [requests, setRequests] = useState([]);
  const [dangerRequests, setDangerRequests] = useState([]);
  const [deceasedRequests, setDeceasedRequests] = useState([]);
  const [userName, setUserName] = useState("");
  const [profPic, setProfPic] = useState("");
  const [name, setName] = useState("");
  const [profileData, setProfileData] = useState();

  useEffect(() => {
    profile();
  }, []);
  useEffect(() => {
    type == "sent" ||
    type == "outreach" ||
    type == "report" ||
    type == "deceased"
      ? requestedList()
      : setRequests([]);
  }, [requestType, type]);

  const profile = () => {
    GETAPI.GetProfile((response) => {
      if (!response.status) {
        dispatch({
          type: PROFILE_DATA,
          payload: {},
        });
        setProfileData();
        setUserName("");
        setProfPic("");
        setName("");
      } else if (response.status) {
        dispatch({
          type: PROFILE_DATA,
          payload: response.data,
        });
        setProfileData(response.data);
        setUserName(response.data.userName);
        setProfPic(response.data.picture);
        setName(response.data.name);
      } else {
        dispatch({
          type: PROFILE_DATA,
          payload: {},
        });
        setProfileData();
        setUserName("");
        setProfPic("");
        setName("");
      }
    });
  };

  const requestedList = () => {
    let formdata = {
      userId: localStorage.getItem("_id"),
      pending: requestType == "pending" ? true : false,
      accepted: requestType == "accepted" ? true : false,
      completed: requestType == "completed" ? true : false,
      expired: requestType == "expired" ? true : false,
    };
    type == "sent"
      ? PostAPI.GetUserRequestList(formdata, (response) => {
          setDangerRequests([]);
          setDeceasedRequests([]);
          if (!response.status) {
            setRequests([]);
          } else if (response.status) {
            setRequests(response.data);
          } else {
            setRequests([]);
          }
        })
      : type == "outreach"
      ? PostAPI.GetUserOfferList(formdata, (response) => {
          setDangerRequests([]);
          setDeceasedRequests([]);
          if (!response.status) {
            setRequests([]);
          } else if (response.status) {
            setRequests(response.data);
          } else {
            setRequests([]);
          }
        })
      : type == "report"
      ? dangerReport()
      : deceasedReport();
  };

  const dangerReport = () => {
    GETAPI.GetReportList(localStorage.getItem("_id"), (response) => {
      setDeceasedRequests([]);
      if (!response.status) {
        setDangerRequests([]);
      } else if (response.status) {
        setDangerRequests(response.data);
      } else {
        setDangerRequests([]);
      }
    });
  };
  const deceasedReport = () => {
    GETAPI.GetDeceasedListById(localStorage.getItem("_id"), (response) => {
      setDangerRequests([]);
      if (!response.status) {
        setDeceasedRequests([]);
      } else if (response.status) {
        setDeceasedRequests(response.data);
      } else {
        setDeceasedRequests([]);
      }
    });
  };

  let sentFilters = [
    {
      type: "sent",
      status: "pending",
      description: t("profile.request_pending"),
    },
    {
      type: "sent",
      status: "accepted",
      description: t("profile.waiting_accept"),
    },
    {
      type: "sent",
      status: "expired",
      description: t("profile.request_expired"),
    },
    {
      type: "sent",
      status: "completed",
      description: t("profile.request_complete"),
    },
  ];

  let outreachFilters = [
    {
      type: "outreach",
      status: "pending",
      description: t("profile.waiting_connect"),
    },
    {
      type: "outreach",
      status: "completed",
      description: t("profile.request_accept"),
    },
    {
      type: "outreach",
      status: "expired",
      description: t("profile.fail_connect"),
    },
  ];

  const handleChange = (event, requestType) => {
    if (requestType === "request") {
      dispatch({
        type: TYPE,
        payload: "sent",
      });
      dispatch({
        type: REQUEST_DESCRIPTION,
        payload: t("profile.request_pending"),
      });
      dispatch({
        type: REQUEST_TYPE_STATUS,
        payload: "pending",
      });
      dispatch({
        type: REQUEST_TYPE,
        payload: requestType,
      });
    } else if (requestType === "offer") {
      dispatch({
        type: TYPE,
        payload: "outreach",
      });
      dispatch({
        type: REQUEST_DESCRIPTION,
        payload: "Waiting for requester to connect",
      });
      dispatch({
        type: REQUEST_TYPE_STATUS,
        payload: "pending",
      });
      dispatch({
        type: REQUEST_TYPE,
        payload: requestType,
      });
    } else if (requestType === "danger") {
      dispatch({
        type: TYPE,
        payload: "report",
      });
      dispatch({
        type: REQUEST_TYPE,
        payload: requestType,
      });
      dispatch({
        type: REQUEST_DESCRIPTION,
        payload: "",
      });
      dispatch({
        type: REQUEST_TYPE_STATUS,
        payload: "",
      });
      dispatch({
        type: REQUEST_TYPE,
        payload: requestType,
      });
    } else if (requestType == "deceased") {
      dispatch({
        type: TYPE,
        payload: "deceased",
      });
      dispatch({
        type: REQUEST_TYPE,
        payload: requestType,
      });
    }
  };

  const changeStatus = (e) => {
    dispatch({
      type: REQUEST_TYPE_STATUS,
      payload: e.target.value,
    });
    const value = e.target.value;
    const filtered =
      type === "sent"
        ? sentFilters.filter((s) => s.status === value)
        : outreachFilters.filter((o) => o.status === value);
    dispatch({
      type: REQUEST_DESCRIPTION,
      payload: filtered[0].description,
    });
  };

  const truncateDescription = (description) => {
    if (description.length > 10) {
      return description.slice(0, 10) + "...";
    } else {
      return description;
    }
  };

  return (
    <Container maxWidth="lg">
      <div style={{ paddingBottom: "80px" }}>
        <div style={{ textAlign: "end", padding: "20px 0 0" }}>
          <SettingsIcon
            className="settings-icon"
            sx={{ color: "#D22108", cursor: "pointer", fontSize: "1.7rem" }}
            onClick={() =>
              navigate(`../${SETTINGS}`, { state: { profileData } })
            }
          />
        </div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={profPic} referrerPolicy="no-referrer" className="image" />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography className="name">{name}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            color="#FFF3F3"
            variant="subtitle2"
            className="profile-username"
          >
            @{userName}
          </Typography>
        </Box>
        {/* <div style={{ width: "100%", margin: "auto" }}> */}
        <Box
          sx={{
            marginTop: "2rem",
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            style={{
              width: "100%",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
            value={toggleType}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton
              value="request"
              sx={{
                border: "1px solid #d2210880",
                width: "30%",
                overflow: "auto",
                height: "80px",
              }}
            >
              {t("profile.help_requested")}
            </ToggleButton>
            <ToggleButton
              value="offer"
              sx={{
                border: "1px solid #d2210880",
                width: "30%",
                overflow: "auto",
                height: "80px",
              }}
            >
              {t("profile.help_offered")}
            </ToggleButton>
            <ToggleButton
              value="danger"
              sx={{
                border: "1px solid #d2210880",
                width: "30%",
                overflow: "auto",
                height: "80px",
              }}
            >
              {t("profile.my_danger_reports")}
            </ToggleButton>
            <ToggleButton
              value="deceased"
              sx={{
                border: "1px solid #d2210880",
                width: "30%",
                overflow: "auto",
                height: "80px",
              }}
            >
              {t("profile.my_deceased_reports")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            {toggleType == "request" || toggleType == "offer" ? (
              <>
                <InputLabel id="demo-select-small">
                  {t("profile.status")}
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  displayEmpty
                  value={requestType}
                  label={t("profile.status")}
                  onChange={changeStatus}
                >
                  <MenuItem value={"pending"}>{t("profile.pending")}</MenuItem>
                  {type === "sent" ? (
                    <MenuItem value={"accepted"}>
                      {t("profile.accepted")}
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  <MenuItem value={"completed"}>
                    {t("profile.completed")}
                  </MenuItem>
                  <MenuItem value={"expired"}>{t("profile.expired")}</MenuItem>
                </Select>
              </>
            ) : (
              <></>
            )}
          </FormControl>
        </Box>
        {/* </div> */}
        {toggleType === "danger" && dangerRequests.length == 0 ? (
          <EmptyProfileRequests />
        ) : (
          <Box>
            <DangerReports
              requests={dangerRequests}
              tab={type}
              status={requestType}
              requestEvents={requestedList}
            />
          </Box>
        )}
        {toggleType === "deceased" && deceasedRequests.length == 0 ? (
          <EmptyProfileRequests />
        ) : (
          <Box>
            <DeceasedReports
              requests={deceasedRequests}
              tab={type}
              status={requestType}
              requestEvents={requestedList}
            />
          </Box>
        )}
        {toggleType === "request" || toggleType === "offer" ? (
          requests.length === 0 ? (
            <EmptyProfileRequests />
          ) : (
            <Box>
              <Typography
                sx={{
                  color: "#D22108",
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: "10px 0",
                }}
              >
                {requestDescription}
              </Typography>
              <hr style={{ border: "1px solid #D22108" }} />

              <ProfileRequests
                requests={requests}
                tab={type}
                status={requestType}
                requestEvents={requestedList}
              />
            </Box>
          )
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
}
