import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Geocode from "react-geocode";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { GOOGLE_MAPS_API_KEY } from "utils/GoogleMapKey";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { LOCATION_TO_COORDINATES, LOCATION_TO_NAME } from "Redux/type";

const LocationToBar = (props) => {
  const {} = props;
  const { t } = useTranslation();
  Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
  const [useGPS, setUseGPS] = useState(false);
  const dispatch = useDispatch();
  const { defaultPosition, locationToName } = useSelector(
    (state) => state.currentLocationCoordinates
  );

  const handleInput = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setValue(e.target.value);
    dispatch({
      type: LOCATION_TO_NAME,
      payload: e.target.value,
    });
    if (name === "useGPS") handleUseGPS(e);
  };

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  function handleUseGPS(e) {
    if (e.target.checked) {
      let check = true;
      reverseGeocodeCoordinates(
        defaultPosition.lat,
        defaultPosition.lng,
        locationToName,
        check
      );
    } else {
      dispatch({
        type: LOCATION_TO_COORDINATES,
        payload: { lat: 0, lng: 0 },
      });
    }
  }
  function handleGPSChange(e) {
    handleUseGPS(e);
    setUseGPS(!useGPS);
  }

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        reverseGeocodeCoordinates(lat, lng, description);
      });
    };

  function reverseGeocodeCoordinates(
    lat,
    lng,
    // markerId,
    des,
    check
    // categ,
    // imgIds,
    // postId
  ) {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
    ) //INPUT GEOCODE API HERE
      .then((response) => response.json())
      .then((data) => {
        if (check) {
          dispatch({
            type: LOCATION_TO_NAME,
            payload: data.results[0].formatted_address,
          });
        } else {
          dispatch({
            type: LOCATION_TO_NAME,
            payload: des,
          });
        }
        dispatch({
          type: LOCATION_TO_COORDINATES,
          payload: { lat: lat, lng: lng },
        });
      })
      .catch((error) => alert(error));
  }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          className="suggestion"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </div>
      );
    });
  return (
    <Grid item style={{ width: "100%" }} xs={12}>
      <Typography
        color="#D22108"
        fontWeight="500"
        component="h5"
        variant="subtitle1"
        align="left"
        gutterBottom
        marginTop={2}
      >
        To
      </Typography>
      <TextField
        size="small"
        fullWidth
        required
        error={locationToName && !useGPS}
        name={t("maps.location")}
        value={locationToName}
        onChange={handleInput}
        disabled={useGPS}
        placeholder={t("maps.location")}
        label={t("maps.location")}
        id={t("maps.location")}
      />
      {status === "OK" && (
        <p style={{ padding: "20px" }}>{useGPS ? "" : renderSuggestions()}</p>
      )}
    </Grid>
  );
};

export default LocationToBar;
