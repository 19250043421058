import React, { Suspense } from "react";
import { HashRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import store from "../src/Redux/store.js";
import { Provider } from "react-redux";
import App from "./App";
import "./i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { clientID } from "utils/GoogleClientID";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={clientID}>
    <Provider store={store}>
      <Suspense fallback="...is loading">
        <HashRouter>
          <App />
        </HashRouter>
      </Suspense>
    </Provider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
