import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  CardActionArea,
  Container,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import TelegramIcon from '@mui/icons-material/Telegram';
import { ReactComponent as WhatsAppIcon } from "assets/whatsapp.svg";
import { ReactComponent as TelegramIcon } from "assets/telegram.svg";
import { ReactComponent as ViberIcon } from "assets/viber.svg";
import { ReactComponent as WechatIcon } from "assets/wechat.svg";
import { ReactComponent as FacebookIcon } from "assets/facebook-messenger.svg";
import { ReactComponent as LineIcon } from "assets/line.svg";
import { ReactComponent as MessageIcon } from "assets/phone-message.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MapCon from "../../DeceasedMap/components/Map/Map.js";
import { DECEASEDDETAILS, FEED, PROFILE, USER } from "navigation/routeConfig";
import { deleteRequest, repostRequest } from "utils/api";
import accommodationIcon from "assets/accommodation.png";
import bandageIcon from "assets/bandage.png";
import foodIcon from "assets/food.png";
import transportIcon from "assets/transport.png";
import EditIcon from "@mui/icons-material/Edit";

// Proximity imports
import { GetUserCoordinates, getProximity } from "utils/locationService";
import { useState, useRef } from "react";
import DeleteAPI from "utils/DELETEAPI";
import ChipButton from "pages/Map/components/Map/components/ChipButton";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import LocationBar from "pages/Map/components/LocationBar/LocationBar";
import {
  EDIT_DECEASED_DATA,
  EDIT_DECEASED_DIALOG,
  LOCATION_COORDINATES,
  LOCATION_NAME,
} from "Redux/type";
import PostAPI from "utils/POSTAPI";
import EditDeceased from "./EditDeceased/EditDeceased.js";

export default function DeceasedReports(props) {
  let navigate = useNavigate();
  let { userid } = useParams();
  const dispatch = useDispatch();
  const [filename, setFilename] = useState("");
  const canvas = useRef();
  const [imgSrc, setImgSrc] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const { t } = useTranslation(); // for translation
  const [open, setOpen] = useState(false);
  const [requestId, setRequestId] = useState();
  const { editDeceasedDialog } = useSelector(
    (state) => state.requestTypeReducer
  );

  const tab = props.tab;
  const status = props.status;

  const contactType = {
    Whatsapp: WhatsAppIcon,
    Telegram: TelegramIcon,
    Viber: ViberIcon,
    Wechat: WechatIcon,
    Facebook: FacebookIcon,
    Line: LineIcon,
    Phone: MessageIcon,
  };

  const iconType = {
    medical: bandageIcon,
    accommodation: accommodationIcon,
    transport: transportIcon,
    food: foodIcon,
  };

  const handleImage = async ({ target }) => {
    let image = URL.createObjectURL(target.files[0]);
    // console.log(image);
    // setDoneDetect(false);
    // setImgSrc(null);
    // console.log(target.files)
    // const base64 = await convertBase64(target.files[0]);
    setFilename(target.files[0].name);
    setImagePreview(image);
    setImgSrc(target.files[0]);
  };

  // PROXIMITY START ======================================================================

  // function getProximityString(lat, long, request) {
  //   if (!lat || !long) {
  //     return "";
  //   }
  //   if (
  //     request.helpType == "transport" &&
  //     [
  //       request.transportLocation.from.longitude,
  //       request.transportLocation.from.latitude,
  //       request.transportLocation.to.longitude,
  //       request.transportLocation.to.latitude,
  //     ].includes(null)
  //   ) {
  //     // console.log(request.location)
  //     return "";
  //   }
  //   if (
  //     request.helpType !== "transport" &&
  //     [
  //       request.currentLocation.longitude,
  //       request.currentLocation.latitude,
  //     ].includes(null)
  //   ) {
  //     // console.log(request.location)
  //     return "";
  //   }

  //   const latlng = getProximity(lat, long, request);

  //   if (request.helpType == "transport") {
  //     return (
  //       latlng[0] +
  //       t("card.requesting_travel") +
  //       " " +
  //       latlng[1] +
  //       t("card.away")
  //     );
  //   }
  //   return latlng[0] + t("card.away");
  // }

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  GetUserCoordinates(setLat, setLong);

  // PROXIMITY STUFF END ======================================================================
  // More stuff at Proximity field of render

  // function handleDelete() {
  //   (async () => {
  //     try {
  //       const res = await deleteRequest(userid, requestId);
  //       // console.log(res.data);
  //       props.requestEvents();
  //       setOpen(false);
  //     } catch (error) {
  //       console.log(error);
  //       alert("failure");
  //     }
  //   })();
  // }

  const handleDelete = () => {
    DeleteAPI.DeleteDeceasedReport(requestId, (response) => {
      props.requestEvents();
      setOpen(false);
    });
  };

  const handleClickOpen = (request) => {
    setRequestId(request._id);
    setOpen(true);
  };

  // const DeceasedPost = () => {
  //   let formdata = new FormData();
  //   formdata.append("firstName", firstName);
  //   formdata.append("middleName", middleName);
  //   formdata.append("lastName", lastName);
  //   formdata.append("nickName", nickName);
  //   formdata.append("dateOfBirth", dob);
  //   formdata.append("gender", gender);
  //   formdata.append("ageRange", ageRange);
  //   formdata.append("dateWhenBodyIsFound", moment(dateBodyFound).format());
  //   formdata.append("hairColor", hairColor);
  //   formdata.append("hairLength", hairLength);
  //   formdata.append("skinColor", skinColor);
  //   formdata.append("locationFound", locationName);
  //   formdata.append("description", description);
  //   formdata.append("decLatitude", locationCoordinates.lat);
  //   formdata.append("decLongitude", locationCoordinates.lng);
  //   formdata.append("photo", imgSrc);
  //   formdata.append("ethnicity", ethnicity);
  //   formdata.append("userId", localStorage.getItem("_id"));
  //   PostAPI.AddDeceased(formdata, (response) => {
  //     if (!response.status) {
  //       dispatch({
  //         type: LOCATION_NAME,
  //         payload: "",
  //       });
  //       dispatch({
  //         type: LOCATION_COORDINATES,
  //         payload: { lat: 0, lng: 0 },
  //       });
  //       setFilename("");
  //       setImagePreview(null);
  //       setImgSrc(null);
  //       setOpenDialog(false);
  //       alert("something went wrong");
  //     } else if (response.status) {
  //       dispatch({
  //         type: LOCATION_NAME,
  //         payload: "",
  //       });
  //       dispatch({
  //         type: LOCATION_COORDINATES,
  //         payload: { lat: 0, lng: 0 },
  //       });
  //       setImagePreview(null);
  //       setFilename("");
  //       setImgSrc(null);
  //       setOpenDialog(false);
  //       alert("Reported Deceased Successfully");
  //       window.location.reload();
  //     } else {
  //       dispatch({
  //         type: LOCATION_NAME,
  //         payload: "",
  //       });
  //       dispatch({
  //         type: LOCATION_COORDINATES,
  //         payload: { lat: 0, lng: 0 },
  //       });
  //       setFilename("");
  //       setImagePreview(null);
  //       setImgSrc(null);
  //       setOpenDialog(false);
  //       alert("something went wrong");
  //     }
  //   });
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const rootStyle = {
    width: "100%",
    backgroundColor: "white",
  };

  const smallTextStyle = {
    fontSize: 12,
    color: "gray",
  };
  const iconTextStyle = {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    marginTop: "5px",
    marginBottom: "5px",
  };

  const truncateDescription = (description) => {
    if (description.length > 40) {
      return description.slice(0, 40) + "...";
    } else {
      return description;
    }
  };
  const ContinueValid = (e) => {
    e.preventDefault();
  };

  const ageRangeMap = {
    Infant: t("maps.Infant"),
    12: t("maps.Under_12_years_old"),
    "12-17": t("maps.12-17_years_old"),
    "18-24": t("maps.18-24_years_old"),
    "25-34": t("maps.25-34_years_old"),
    "35-44": t("maps.35-44_years_old"),
    "45-54": t("maps.45-54_years_old"),
    "55-64": t("maps.55-64_years_old"),
    "65-74": t("maps.65-74_years_old"),
    75: t("maps.75_years_or_older"),
  };

  const skinColorMap = {
    Pale: t("maps.Pale"),
    LightBrown: t("maps.Light_Brown"),
    Tanned: t("maps.Tanned"),
    DarkBrown: t("maps.Dark_Brown"),
    VeryDarkBrown: t("maps.Very_Dark_Brown"),
  };

  const hairColorMap = {
    White: t("maps.White"),
    Grey: t("maps.Grey"),
    Blonde: t("maps.Blonde"),
    Brunette: t("maps.Brunette"),
    Red: t("maps.Red"),
    Black: t("maps.Black"),
    Others: t("maps.Others"),
  };

  const hairLengthMap = {
    Bald: t("maps.Bald"),
    BuzzCut: t("maps.Buzz_Cut"),
    Ear: t("maps.Ear"),
    Neck: t("maps.Neck"),
    Collar: t("maps.Collar"),
    Shoulder: t("maps.Shoulder"),
    ArmPit: t("maps.Arm_Pit"),
    MidBack: t("maps.Mid_back"),
    Waist: t("maps.Waist"),
  };
  const genderMap = {
    male: t("maps.male"),
    female: t("maps.female"),
  };

  const ageRangeChip = (deceased) =>
    Object.keys(ageRangeMap).map((key) => {
      if (deceased.ageRange[key]) {
        return <ChipButton name={t(ageRangeMap[key])} />;
      }
      return null;
    });
  const genderChip = (deceased) =>
    Object.keys(genderMap).map((key) => {
      if (deceased.gender[key]) {
        return <ChipButton name={t(genderMap[key])} />;
      }
      return null;
    });
  const skinColorChip = (deceased) =>
    Object.keys(skinColorMap).map((key) => {
      if (deceased.skinColor[key]) {
        return <ChipButton name={t(skinColorMap[key])} />;
      }
      return null;
    });
  const hairColorChip = (deceased) =>
    Object.keys(hairColorMap).map((key) => {
      if (deceased.hairColor[key]) {
        return <ChipButton name={t(hairColorMap[key])} />;
      }
      return null;
    });
  const hairLengthChip = (deceased) =>
    Object.keys(hairLengthMap).map((key) => {
      if (deceased.hairLength[key]) {
        return <ChipButton name={t(hairLengthMap[key])} />;
      }
      return null;
    });

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <List style={rootStyle}>
          {props.requests.map((deceased, index) => (
            <>
              <div style={{ marginTop: "2%" }}>
                {ageRangeChip(deceased)}
                {skinColorChip(deceased)}
                {hairLengthChip(deceased)}
                {hairColorChip(deceased)}
                {genderChip(deceased)}
                {deceased.ethnicity !== null && deceased.ethnicity !== "" && (
                  <ChipButton name={deceased.ethnicity} />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   backgroundColor: "yellow",
                  gap: "10px",
                }}
              >
                <EditIcon
                  style={{
                    fill: "#D22108",
                    width: 22,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch({
                      type: EDIT_DECEASED_DIALOG,
                      payload: true,
                    });
                    dispatch({
                      type: EDIT_DECEASED_DATA,
                      payload: deceased,
                    });
                  }}
                />
                <DeleteIcon
                  style={{
                    fill: "#D22108",
                    width: 22,
                    cursor: "pointer",
                  }}
                  onClick={() => handleClickOpen(deceased)}
                />
              </div>
              <ListItem
                onClick={() =>
                  navigate(
                    `/${USER}/${localStorage.getItem(
                      "_id"
                    )}/${DECEASEDDETAILS}`,
                    { state: { data: deceased } }
                  )
                }
                key={index}
              >
                <ListItemText
                  style={{ cursor: "pointer" }}
                  primary={truncateDescription(deceased.description)}
                  secondary={
                    <React.Fragment>
                      <span style={smallTextStyle}>
                        {moment(deceased.createdAt).fromNow()}
                      </span>
                      <br />
                      <div style={iconTextStyle}>
                        <AccountBoxOutlinedIcon />
                        {deceased.firstName == "" &&
                        deceased.middleName == "" &&
                        deceased.lastName == ""
                          ? "Unknown"
                          : deceased.firstName +
                            " " +
                            deceased.middleName +
                            " " +
                            deceased.lastName}{" "}
                        [{t("deceased.name")}]
                      </div>
                      <div style={iconTextStyle}>
                        <AccessTimeIcon />
                        {moment(deceased.dateWhenBodyIsFound).format(
                          "YYYY-MM-DD"
                        )}{" "}
                        [{t("deceased.date_found")}]
                      </div>
                      <div style={iconTextStyle}>
                        <LocationOnOutlinedIcon />
                        {deceased.locationFound.split(", ").slice(-4, -3)[0] +
                          " " +
                          deceased.locationFound.split(", ").slice(-1)[0]}{" "}
                        [{t("deceased.location")}]
                      </div>
                    </React.Fragment>
                  }
                />
                <MapCon />
              </ListItem>
              <div
                style={{
                  backgroundColor: "gray",
                  width: "100%",
                  height: "0.5px",
                }}
              />
            </>
          ))}
        </List>
      </Grid>
      <Dialog open={editDeceasedDialog}>
        <EditDeceased />
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          maxWidth: "xs",
          "& .MuiDialog-paper": { borderRadius: 3, maxHeight: 435 },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("delete.msg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ "& .MuiDialogActions-root": { flex: "none" } }}>
          <Button variant="contained" onClick={handleDelete}>
            {t("delete.deleteBtn")}
          </Button>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            {t("details.cancelBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
